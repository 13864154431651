import {useState, useEffect} from 'react'
import { useAppContext } from './../Context';

//import tags
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faPhone, faEnvelope, faLocationDot } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';

// import recources
import Facebook from '../../assets/image/facebook.png'
import Youtube from '../../assets/image/youtube.png'
import Location from '../../assets/image/location.png'
import Envelope from '../../assets/image/envelope.png'
import Phone from '../../assets/image/phone.png'

//import translation
import { useTranslation } from "react-i18next";
import "../../i18n";

function getDate() {
    const today = new Date();
    const year = today.getFullYear();
    return `${year}`;
}

  
function Footer({locale}) {

    const [currentDate, setCurrentDate] = useState(getDate());

    const { globalContact } = useAppContext();

    //get locale
    const { t } = useTranslation();


    return (
        <footer className="footer">
            <Container>
                <Row>
                    <Col xxl={3}>
                        <div className="footer_menu">
                            <h4>{t('statements')}</h4>
                            <ul>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> ბინები თბილისში</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> ახალაშენებული ბინები</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> ბინები ბათუმში</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> იყიდება 1 ოთახიანი ბინა</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> იყიდება კერძო სახლი</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> მშენებარე ბინები თბილისში</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> მშენებარე ბინები ბათუმში</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> იყიდება კერძო სახლები</Link></li>
                            </ul>
                        </div>
                    </Col>
                    <Col xxl={3}>
                        <div className="footer_menu">
                            <h4>&nbsp;</h4>
                            <ul>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> ბინები თბილისში</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> ახალაშენებული ბინები</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> ბინები ბათუმში</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> იყიდება 1 ოთახიანი ბინა</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> იყიდება კერძო სახლი</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> მშენებარე ბინები თბილისში</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> მშენებარე ბინები ბათუმში</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> იყიდება კერძო სახლები</Link></li>
                            </ul>
                        </div>
                    </Col>
                    <Col xxl={3}>
                        <div className="footer_menu">
                            <h4>&nbsp;</h4>
                            <ul>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> ბინები თბილისში</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> ახალაშენებული ბინები</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> ბინები ბათუმში</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> იყიდება 1 ოთახიანი ბინა</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> იყიდება კერძო სახლი</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> მშენებარე ბინები თბილისში</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> მშენებარე ბინები ბათუმში</Link></li>
                                <li><Link to={`/${locale}/property`}><FontAwesomeIcon icon={faCaretRight} /> იყიდება კერძო სახლები</Link></li>
                            </ul>
                        </div>
                    </Col>
                    <Col xxl={3}>
                        <div className="footer_menu">
                            <h4>{t('contact_info')}</h4>
                            <ul className="footerContactIcons">
                                <li><Link to=''><Image src={Location} alt="Location" />{ typeof globalContact == 'object' ? globalContact?.translations[0].address : '' }</Link></li>
                                <li><Link to={ typeof globalContact == 'object' ? 'mailto:'+globalContact?.email : '' }><Image src={Envelope} alt="Envelope" />{ typeof globalContact == 'object' ? globalContact?.email : '' }</Link></li>
                                <li><Link to={ typeof globalContact == 'object' ? 'tel:'+globalContact?.phone : '' }><Image src={Phone} alt="Envelope" />{ typeof globalContact == 'object' ? globalContact?.phone : '' }</Link></li>
                                <li className="socialFtrBox">
                                    <div className="footerSocial">
                                        <div className="singleSocial">
                                            <Link to={globalContact?.facebook} target='_blank'>
                                                <FontAwesomeIcon icon={faFacebook} />
                                            </Link>
                                        </div>
                                        <div className="singleSocial">
                                            <Link to={globalContact?.instagram} target='_blank'>
                                                <FontAwesomeIcon icon={faInstagram} />
                                            </Link>
                                        </div>
                                        <div className="singleSocial">
                                            <Link to={globalContact?.tiktok} target='_blank'>
                                                <FontAwesomeIcon icon={faTiktok} />
                                            </Link>
                                        </div>
                                        <div className="singleSocial">
                                            <Link to={globalContact?.youtube} target='_blank'>
                                                <FontAwesomeIcon icon={faYoutube} />
                                            </Link>
                                        </div>
                                    </div>    
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>

            </Container>

            <div className="footerBottom">
                <Container>
                    <Row>
                        <Col xxl={6}>
                            <div className="footerBottomLeft">
                                <p>&copy; RELUX.GE {currentDate}. {t('all_rights')}.</p>
                            </div>
                        </Col>
                        <Col xxl={6}>
                            <div className="footerBottomRight">
                                <Link to={`/${locale}/terms`}>{t('terms_conditions')}</Link>
                                <Link to={`/${locale}/privacy`}>{t('confidential')}</Link>
                            </div>
                        </Col>
                    </Row>
                </Container>
                
            </div>

        </footer>

        
    )
    
}

export default Footer;