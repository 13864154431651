import { useEffect, useState } from 'react';

import { destrictOptions } from '../utils/PropertyOptions';

//import tags
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";
import { Navigation, A11y } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';

// import api
import { get_count_destrict } from '../../api/Api';

function District({locale}) {

    const [data, setData] = useState(false);
        const [destrictData, setDestrictData] = useState([]);
    
        useEffect(() => {
            const fetchData = async () => {
                try {
                    get_count_destrict(setData, destrictData);
                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            };
        
            // Collect the cities only once
            const cities = destrictOptions
                .map(item => item.value);
        
            // Set destrict data only if it's empty
            if (cities.length > 0 && destrictData.length === 0) {
                setDestrictData(cities);
            }
        
            // Only call fetchData if 'data' is empty and 'destrictData' is populated
            if (!data && destrictData.length > 0) {
                fetchData();
            }
        }, [data, destrictData, destrictOptions]);

    return (
        <Container>
            <Row>
                <Col xxl={12}>
                    <section className="districtSection">
                        
                        <div className="sectionHeader">
                            <div className="sectionHeaderLeft">
                                <h2>თბილისის უბნები</h2>
                                <p>მოიძიეთ სასურველი განცხადება უბნების მიხედვით.</p>
                            </div>
                        </div>

                        <div className="citySlider">
                            
                            <Swiper
                                // install Swiper modules
                                modules={[Navigation, A11y]}
                                spaceBetween={20}
                                slidesPerView={5}
                                navigation
                            >
                            
                            {
                                destrictOptions.map((item) => {
                                    return (
                                        <SwiperSlide key={item.value}>
                                            <Link to={`/${locale}/property?city=tbilisi&destrict=${item.value}`}>
                                                <div className="singleCityBox">
                                                    <div className="singleCityBoxImage">
                                                        <Image src={item.image} alt={item.label} />
                                                    </div>
                                                    <div className="cityOverlay">
                                                        <div className="overCity">
                                                            <h3>{item.label}</h3>
                                                            <p>{data?.[item.value] ?? 0} განცხადება</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                    )
                                }) 
                            }
                                

                            </Swiper>
                        </div>

                    </section>
                </Col>
            </Row>
        </Container>
    )

}

export default District;