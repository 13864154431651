import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  .use(HttpApi) // Load translations from files
  .use(LanguageDetector) // Detect language from URL
  .use(initReactI18next)
  .init({
    supportedLngs: ["ka", "en", "ru"], // Supported languages
    fallbackLng: "ka", // Default language
    detection: {
      order: ["path", "navigator"], // Get language from URL path first
    },
    interpolation: { escapeValue: false }, // React already escapes
  });

export default i18n;
