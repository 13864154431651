//import tags
import React, { useState, useEffect } from 'react';
import { useAppContext } from '../Context';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import { Navigation, A11y } from 'swiper/modules';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faHeart, faLocationCrosshairs, faBed, faDoorOpen, faLayerGroup, faHashtag, faMobileScreen, faWifi } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartRegular, faClone, faCalendarDays, faFlag } from '@fortawesome/free-regular-svg-icons';

import 'swiper/css';
import 'swiper/css/navigation';

// import recources
import Skeleton from 'react-loading-skeleton';
import Noproperty from "../../assets/image/property.jpg";
import ReluxSectionLogo from '../../assets/image/favicon.png'

//import translation
import { useTranslation } from "react-i18next";
import "../../i18n";

//api
import { get_relux_properties } from "../../api/Api";


function Relux({ locale }) {

    const baseUrl = "https://reluxapi.myweb.com.ge";

    //get locale
    const { t } = useTranslation();

    const { getenumbykey, setFavoritesCount } = useAppContext();

    const [message, setMessage] = useState('');
    const [favorites, setFavorites] = useState([]);
    const [currentCurrency, setCurrentCurrency] = useState('GEL');
    const [property, setProperty] = useState(null);

    //get favorites if saved

    useEffect(() => {
        const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
        setFavorites(storedFavorites);
    }, []);

    //set favorites
    const toggleFavorite = (id) => {
        let updatedFavorites;

        if (favorites.includes(id)) {
            updatedFavorites = favorites.filter((favId) => favId !== id);
            toast.error('წაიშალა ფავორიტებიდან', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        } else {
            updatedFavorites = [...favorites, id];
            toast.success('დაემატა ფავორიტებში', {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
            });
        }

        setFavorites(updatedFavorites);

        localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
    };

    //toggle currency
    const toggleCurrency = (currency) => {
        setCurrentCurrency(currency);
    };

    //set favorites count context
    useEffect(() => {
        setFavoritesCount(null);
    }, [favorites]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const header = {
                    'Authorization': 'Bearer ' + localStorage.getItem('token')
                }

                get_relux_properties(setProperty, header);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        if (property == null) {
            fetchData();
        }
    }, [property]);

    return (
        <Container>
            <Row>
                <Col xxl={12}>
                    <section className="reluxSection">

                        <div className="sectionHeader">
                            <div className="sectionHeaderLeft">
                                <div className="sectionTitleLogo">
                                    <div className="sectionTitleLogoLeft">
                                        <Image src={ReluxSectionLogo} alt="Relux" />
                                    </div>
                                    <div className="sectionTitleLogoRight">
                                        <h2>Relux განცხადებები</h2>
                                        <p>მოიძიეთ RELUX განცხადებები.</p>
                                    </div>
                                </div>

                            </div>
                            <div className="sectionHeaderRight">
                                <Link to={`/${locale}/property?statement_type=relux`}>ყველას ნახვა <FontAwesomeIcon icon={faChevronRight} /></Link>
                            </div>
                        </div>

                        {message && <div className="addedToFafourites"><h4>{message}</h4></div>}

                        <div className="citySlider">
                            <Swiper
                                // install Swiper modules
                                modules={[Navigation, A11y]}
                                spaceBetween={20}
                                slidesPerView={4}
                                navigation
                            >

                                {
                                    property == null ?
                                        <>
                                            {
                                                [1, 2, 3, 4, 5].map(( mapItems ) => {
                                                    return (
                                                        <SwiperSlide>
                                                            <div className="singleProductGridBox">
                                                                <div className="singleProductGridBoxImageBox">
                                                                    <Skeleton height={240} />
                                                                </div>
                                                                <div className="singleProductGridBoxContant">
                                                                    <h1 className="mt-3">
                                                                        <Skeleton height={25} />
                                                                    </h1>
                                                                    <Skeleton className="mt-3" height={20} />
                                                                    <Skeleton className="mt-3" height={20} />
                                                                </div>
                                                            </div>
                                                        </SwiperSlide>
                                                    )
                                                })
                                            }


                                        </>
                                        :
                                        (typeof property == 'object' && property != null ? (
                                            property.map((item) => {
                                                return (
                                                    <SwiperSlide>
                                                        <div className="singleProductGridBox">
                                                            {message && (
                                                                <div className="addedToFavourites">
                                                                    <h4>{message}</h4>
                                                                </div>
                                                            )}
                                                            <div className="singleProductGridBoxImageBox">
                                                                <Link to={`/${locale}/property/details/` + item.id}>
                                                                    <Swiper
                                                                        modules={[Navigation, A11y]}
                                                                        spaceBetween={5}
                                                                        slidesPerView={1}
                                                                        navigation
                                                                        scrollbar={{ draggable: true }}
                                                                    >
                                                                        {item.image1 && item.image1 != null ? (
                                                                            <SwiperSlide>
                                                                                <div className="singleProductGridBoxImage">
                                                                                    <Image
                                                                                        src={`${baseUrl}/storage/property/${item.image_folder}/thumbnail/${item.image1}`}
                                                                                        alt={`${typeof item === "object" &&
                                                                                            item?.transaction_type != null
                                                                                            ? getenumbykey(
                                                                                                parse(item.transaction_type)
                                                                                            )
                                                                                            : ""
                                                                                            } ${typeof item === "object" &&
                                                                                                item?.property_type != null
                                                                                                ? getenumbykey(
                                                                                                    parse(item.property_type)
                                                                                                )
                                                                                                : ""
                                                                                            }`}
                                                                                    />
                                                                                </div>
                                                                            </SwiperSlide>
                                                                        ) : (
                                                                            <SwiperSlide>
                                                                                <div className="singleProductGridBoxImage">
                                                                                    <Image src={Noproperty} alt="Relux" />
                                                                                </div>
                                                                            </SwiperSlide>
                                                                        )}
                                                                    </Swiper>
                                                                </Link>
                                                                {item.statement_type && (
                                                                    <div
                                                                        className={`singleProductGridTag ${item.statement_type === "exclusive"
                                                                            ? "exclusiveAd"
                                                                            : "reluxAd"
                                                                            }`}
                                                                    >
                                                                        <Link to="/">
                                                                            <h4>
                                                                                {item?.statement_type != null
                                                                                    ? getenumbykey(
                                                                                        parse(item.statement_type)
                                                                                    )
                                                                                    : ""}
                                                                            </h4>
                                                                        </Link>
                                                                    </div>
                                                                )}
                                                                <div
                                                                    className="singleProductGridFavourite"
                                                                    onClick={() => toggleFavorite(item.id)}
                                                                >
                                                                    {favorites.includes(item.id) ? (
                                                                        <FontAwesomeIcon icon={faHeart} />
                                                                    ) : (
                                                                        <FontAwesomeIcon icon={faHeartRegular} />
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="singleProductGridBoxContant">
                                                                <div className="singleProductGridPrice">
                                                                    {["GEL", "USD", "EUR"].includes(
                                                                        currentCurrency
                                                                    ) && (
                                                                            <h4>
                                                                                {item?.[
                                                                                    `price_${currentCurrency.toLowerCase()}`
                                                                                ] != null
                                                                                    ? item[
                                                                                    `price_${currentCurrency.toLowerCase()}`
                                                                                    ]
                                                                                    : ""}
                                                                                <span>{currentCurrency}</span>
                                                                            </h4>
                                                                        )}
                                                                    <div className="priceBoxGrid">
                                                                        {["GEL", "USD", "EUR"].map((currency) => (
                                                                            <div
                                                                                key={currency}
                                                                                className={`singlePrice ${currentCurrency === currency
                                                                                    ? "active"
                                                                                    : ""
                                                                                    }`}
                                                                                onClick={() => toggleCurrency(currency)}
                                                                            >
                                                                                <p>{currency}</p>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                                <div className="propertyGridTags">
                                                                    <div className="singleTag">
                                                                        {item?.property_type != null
                                                                            ? getenumbykey(parse(item.property_type))
                                                                            : ""}
                                                                    </div>
                                                                    <div className="singleTag">
                                                                        {item?.city != null
                                                                            ? getenumbykey(parse(item.city))
                                                                            : ""}
                                                                    </div>
                                                                </div>
                                                                <Link to={`/${locale}/property/details/` + item.id}>
                                                                    <h1>
                                                                        {item?.transaction_type != null
                                                                            ? getenumbykey(
                                                                                parse(item.transaction_type)
                                                                            )
                                                                            : ""}{" "}
                                                                        {item?.property_type != null
                                                                            ? getenumbykey(parse(item.property_type))
                                                                            : ""}
                                                                    </h1>
                                                                </Link>

                                                                <div className="singlePropertyGridLocation">
                                                                    <FontAwesomeIcon
                                                                        icon={faLocationCrosshairs}
                                                                    />
                                                                    <p>
                                                                        {item?.address != null
                                                                            ? item.address.length > 50
                                                                                ? item.address
                                                                                    .substring(0, 50)
                                                                                    .replace(/<[^>]*>/g, "") + "..."
                                                                                : item.address.replace(/<[^>]*>/g, "")
                                                                            : ""}
                                                                    </p>
                                                                </div>
                                                                <div className="singlePropertyListMiniBox">
                                                                    {(() => {
                                                                        switch (item?.property_type) {
                                                                            case "land":
                                                                                return (
                                                                                    <div
                                                                                        className="singlePropertyGridMini"
                                                                                        title="ფართი"
                                                                                    >
                                                                                        <FontAwesomeIcon icon={faClone} />:
                                                                                        <span>
                                                                                            {item?.area != null
                                                                                                ? item.area
                                                                                                : ""}{" "}
                                                                                            მ<sup>2</sup>
                                                                                        </span>
                                                                                    </div>
                                                                                );
                                                                            case "apartment":
                                                                                return (
                                                                                    <>
                                                                                        <div
                                                                                            className="singlePropertyGridMini"
                                                                                            title="საძინებელი"
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faBed} />:
                                                                                            <span>
                                                                                                {item?.bedrooms != null
                                                                                                    ? item.bedrooms
                                                                                                    : ""}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="singlePropertyGridMini"
                                                                                            title="ოთახი"
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={faDoorOpen}
                                                                                            />
                                                                                            :
                                                                                            <span>
                                                                                                {item?.rooms != null
                                                                                                    ? item.rooms
                                                                                                    : ""}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="singlePropertyGridMini"
                                                                                            title="სართული"
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={faLayerGroup}
                                                                                            />
                                                                                            :
                                                                                            <span>
                                                                                                {item?.floor != null
                                                                                                    ? item.floor
                                                                                                    : ""}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="singlePropertyGridMini"
                                                                                            title="ფართი"
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faClone} />
                                                                                            :
                                                                                            <span>
                                                                                                {item?.area != null
                                                                                                    ? item.area
                                                                                                    : ""}{" "}
                                                                                                მ<sup>2</sup>
                                                                                            </span>
                                                                                        </div>
                                                                                    </>
                                                                                );
                                                                            case "business":
                                                                                return (
                                                                                    <>
                                                                                        <div
                                                                                            className="singlePropertyGridMini"
                                                                                            title="ფართი"
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faClone} />
                                                                                            :
                                                                                            <span>
                                                                                                {item?.area != null
                                                                                                    ? item.area
                                                                                                    : ""}{" "}
                                                                                                მ<sup>2</sup>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="singlePropertyGridMini"
                                                                                            title="ოთახი"
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={faDoorOpen}
                                                                                            />
                                                                                            :
                                                                                            <span>
                                                                                                {item?.rooms != null
                                                                                                    ? item.rooms
                                                                                                    : ""}
                                                                                            </span>
                                                                                        </div>
                                                                                    </>
                                                                                );
                                                                            default:
                                                                                return null;
                                                                        }
                                                                    })()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            })
                                        ) : '')

                                }


                            </Swiper>
                        </div>

                    </section>
                </Col>
            </Row>
        </Container>
    )

}

export default Relux;