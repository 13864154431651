import { useState } from "react";
import { Link } from "react-router-dom";

//import tags
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

function Slider({locale}) {

    const [search, setSearch] = useState('');

    return (
        <section className="sliderSection">
            <div className="SliderContent">
                <div className="SliderText">
                    <h1>მოძებნე შენი უძრავი ქონება.</h1>
                    <pre>ჩვენ გთავაზობთ მხოლოდ გადამოწმებულ უძრავ ქონებას!</pre>
                </div>
                <div className="sliderSearch">
                    <div className="sliderSearchInput">
                        <input className="mainSearch" name="key" value={search} onChange={(e) => setSearch(e.target.value)} type="text" placeholder="მოძებნეთ ID, სიტყვა, ს/კ" />
                        <Link to={`/${locale}/property?key=${search}`} className="searchButton"><FontAwesomeIcon icon={faMagnifyingGlass} /></Link>
                    </div>
                </div>
            </div>
        </section>
    )
    
}

export default Slider;