// import routes
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { ToastContainer } from 'react-toastify';

import HomePage from "./pages/Home";
import AboutPage from "./pages/About";
import CitiesPage from "./pages/Cities";
import VideosPage from "./pages/Videos";
import ContactPage from "./pages/Contact";
import PhotovideoPage from "./pages/Photovideo";
import RatePage from "./pages/Rate";
import ManagementPage from "./pages/Management";
import ExclusivePage from "./pages/Exclusive";
import CheckPage from "./pages/Check";
import InsurancePage from "./pages/Insurance";
import LegalservicePage from "./pages/Legalservice";
import AdsPage from "./pages/Ads";
import CleintsPage from "./pages/Clients";
import RepairservicePage from "./pages/Repairservice";
import MontagePage from "./pages/Montage";
import LandservicePage from "./pages/Landservice";
import BuildingmanagementPage from "./pages/Buildingmanagement";
import ArchitectPage from "./pages/Architect";
import UrbanPage from "./pages/Urban";
import CostservicePage from "./pages/Costservice";
import InteriordesignPage from "./pages/Interiordesign";
import CommunicationsPage from "./pages/Communications";
import ProfilePage from "./pages/profile/Main";
import ProfileinfoPage from "./pages/profile/Ads";
import ProfilefavoritesPage from "./pages/Favorites";
import TermsPage from "./pages/Terms";
import PrivacyPage from "./pages/Privacy";

import NewsPage from "./pages/news/Main";
import NewsDetailPage from "./pages/news/Detail";
import PropertyPage from "./pages/property/Main";
import PropertyDetailPage from "./pages/property/Detail";
import PropertyAddPage from "./pages/property/Add";
import Error404 from "./Error404";

// import tags
import { GoogleOAuthProvider } from '@react-oauth/google';

// import components
import Header from './components/static/Header'
import Footer from './components/static/Footer'
import { ContextProvider } from './components/Context'

// import resources
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './assets/css/App.css';

function WebRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Navigate to="/ka" replace />} /> {/* Default to Georgian */}
            <Route path="/:locale/*" element={<Layout />} /> {/* Wrap all routes inside Layout */}
        </Routes>
    );
}

// Layout Component to extract locale from URL
const Layout = () => {
    const { locale } = useParams();
    const { i18n } = useTranslation();

    // Get allowed languages
    const allowedLanguages = i18n.options.supportedLngs;

    // Once the locale is valid, change the language
    useEffect(() => {
        if (i18n.language !== locale) {
            i18n.changeLanguage(locale);
            localStorage.setItem('locale', locale);
        }
    }, [locale, i18n]);

    useEffect(()=>{
        if(localStorage.hasOwnProperty('locale')) {
            i18n.changeLanguage(localStorage.getItem('locale'));
        }
    }, [])

    // If the locale is invalid, redirect immediately
    if (!allowedLanguages.includes(locale)) {
        return <Navigate to="/ka" replace />;
    }
    
    
    
    return (
        <>
            <Header locale={locale} />
            <Routes>
                <Route path="/" element={<HomePage locale={locale} />} />
                <Route path="/about" element={<AboutPage locale={locale} />} />
                <Route path="/cities" element={<CitiesPage locale={locale} />} />
                <Route path="/videos" element={<VideosPage locale={locale} />} />
                <Route path="/contact" element={<ContactPage locale={locale} />} />
                <Route path="/photovideo" element={<PhotovideoPage locale={locale} />} />
                <Route path="/rate" element={<RatePage locale={locale} />} />
                <Route path="/management" element={<ManagementPage locale={locale} />} />
                <Route path="/exclusive" element={<ExclusivePage locale={locale} />} />
                <Route path="/check" element={<CheckPage locale={locale} />} />
                <Route path="/insurance" element={<InsurancePage locale={locale} />} />
                <Route path="/legalservice" element={<LegalservicePage locale={locale} />} />
                <Route path="/ads" element={<AdsPage locale={locale} />} />
                <Route path="/clients" element={<CleintsPage locale={locale} />} />
                <Route path="/repairservice" element={<RepairservicePage locale={locale} />} />
                <Route path="/montage" element={<MontagePage locale={locale} />} />
                <Route path="/landservice" element={<LandservicePage locale={locale} />} />
                <Route path="/buildingmanagement" element={<BuildingmanagementPage locale={locale} />} />
                <Route path="/architect" element={<ArchitectPage locale={locale} />} />
                <Route path="/urban" element={<UrbanPage locale={locale} />} />
                <Route path="/costservice" element={<CostservicePage locale={locale} />} />
                <Route path="/interiordesign" element={<InteriordesignPage locale={locale} />} />
                <Route path="/communications" element={<CommunicationsPage locale={locale} />} />

                {/* Profile Routes */}
                <Route path="/profile/main" element={<ProfilePage locale={locale} />} />
                <Route path="/profile/ads" element={<ProfileinfoPage locale={locale} />} />
                <Route path="/favorites" element={<ProfilefavoritesPage locale={locale} />} />

                {/* Terms & Privacy */}
                <Route path="/terms" element={<TermsPage locale={locale} />} />
                <Route path="/privacy" element={<PrivacyPage locale={locale} />} />

                {/* News Routes */}
                <Route path="/newses" element={<NewsPage locale={locale} />} />
                <Route path="/news/:id/:slug" element={<NewsDetailPage locale={locale} />} />

                {/* Property Routes */}
                <Route path="/property" element={<PropertyPage locale={locale} />} />
                <Route path="/property/:id/:slug" element={<PropertyDetailPage locale={locale} />} />
                <Route path="/property/add" element={<PropertyAddPage locale={locale} />} />

                {/* 404 Not Found */}
                <Route path="*" element={<Error404 locale={locale} />} />
            </Routes>
            <Footer locale={locale} />
            <ToastContainer />
        </>
    );
};

export default function App() {
    return (
        <GoogleOAuthProvider clientId="173465526565-fqd6nopc90q8dg4065nbcjl2dtdtdtth.apps.googleusercontent.com">
            <ContextProvider>
                <Router>
                    <WebRoutes />
                </Router>
            </ContextProvider>
        </GoogleOAuthProvider>
    );
}
