import { useState, useEffect } from "react";

//import components
import * as Options from "../utils/PropertyOptions";
import { formatDate } from '../../components/utils/Helper'

//import tags
import { Col, Row, Dropdown } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Select from "react-select";

//import resources
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

export default function Filter({ setFilterModal, searchQueryData, setSearchQueryData, handleChangeSelect, filter, locale }) {

        const [currency, setCurrency] = useState("GEL");
    
        function handleChangeInput(e) {
            const { name, value, type, checked } = e.target;
          
            // Determine if the field is an array (checkbox group)
            const isArrayField = name.endsWith("[]");
            const key = isArrayField ? name.slice(0, -2) : name;
          
            setSearchQueryData((prevsearchQueryData) => {
              // Copy previous data to avoid mutation
              let newData = { ...prevsearchQueryData };
          
              if (isArrayField) {
                // If it's an array field (checkbox group)
                const currentArray = prevsearchQueryData[key] || [];
          
                if (checked) {
                  // If checked, add value to the array (ensure no duplicates)
                  newData[key] = [...new Set([...currentArray, value])];
                } else {
                  // If unchecked, remove value from the array
                  newData[key] = currentArray.filter((item) => item !== value);
                }
              } else {
                if (type === "checkbox") {
                  newData[key] = checked ? value : undefined;
                } else {
                    if (value.length > 0) {
                        newData[key] = value;
                    } else {
                        newData[key] = '';
                    }
                }
              }
              
          
              // Clean the data by removing keys with undefined, empty, or null values
              // For arrays, keep even if they are empty.
              const cleanedData = Object.fromEntries(
                Object.entries(newData).filter(([k, val]) => {
                  if (Array.isArray(val)) {
                    return true; // always keep arrays, even empty ones
                  }
                  return val !== "" && val !== undefined && val !== null;
                })
              );
          
              return cleanedData;
            });
          }

          useEffect(() => {
            setSearchQueryData((prevQuery) => ({
                ...prevQuery,
                currency: currency
            }))
          }, [currency]) 
    
        function handleChangeDate(name, date) {
            if (name && date) {
                setSearchQueryData((prevsearchQueryData) => ({
                    ...prevsearchQueryData,
                    [name]: date
                }));
            }
        }

    return (
        <>
            <div className="addonFilterBox">
                
                <div className="addonFilterHead">
                    <h4>დეტალური ფილტრი</h4>
                    <span onClick={() => setFilterModal(false)}>
                        <FontAwesomeIcon icon={faXmark} />
                    </span>
                </div>

                <div className="addonFilterContent">
                    <Row>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsTitle">ძირითადი ინფორმაცია</h4>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">გარიგების ტიპი</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="transaction-type"
                                            value={Options.transactionTypeOptions.filter(opt => (searchQueryData?.transaction_type || '').includes(opt.value))}
                                            name="transaction_type"
                                            onChange={handleChangeSelect}
                                            options={Options.transactionTypeOptions}
                                            placeholder="აირჩიეთ გარიგების ტიპი"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">უძრავი ქონების ტიპი</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="property-type"
                                            value={Options.propertyTypeOptions.filter(opt => 
                                                (searchQueryData?.property_type || '').includes(opt.value))}
                                            name="property_type"
                                            onChange={handleChangeSelect}
                                            options={Options.propertyTypeOptions}
                                            placeholder="აირჩიეთ უძრავი ქონების ტიპი"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">უძრავი ქონების სტატუსი</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="property_status"
                                            value={Options.propertyStatusOptions.filter(opt => 
                                                (searchQueryData?.property_status || '').includes(opt.value))}
                                            name="property_status"
                                            onChange={handleChangeSelect}
                                            options={Options.propertyStatusOptions}
                                            placeholder="აირჩიეთ უძრავი ქონების სტატუსი"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">პროექტი</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="project"
                                            value={Options.propertyProjectOptions.filter(opt => 
                                                (searchQueryData?.project || '').includes(opt.value))}
                                            name="project"
                                            onChange={handleChangeSelect}
                                            options={Options.propertyProjectOptions}
                                            placeholder="აირჩიეთ პროექტი"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">პროექტის კლასი</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="class"
                                            value={Options.propertyProjectClassOptions.filter(opt => 
                                                (searchQueryData?.class || '').includes(opt.value))}
                                            name="class"
                                            onChange={handleChangeSelect}
                                            options={Options.propertyProjectClassOptions}
                                            placeholder="აირჩიეთ პროექტის კლასი"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">მდგომარეობა</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="condition"
                                            value={Options.propertyConditionOptions.filter(opt => 
                                                (searchQueryData?.condition || '').includes(opt.value))}
                                            name="condition"
                                            onChange={handleChangeSelect}
                                            options={Options.propertyConditionOptions}
                                            placeholder="აირჩიეთ მდგომარეობა"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">განცხადების ტიპი</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="statement_type"
                                            value={Options.statementTypeOptions.filter(opt => 
                                                (searchQueryData?.statement_type || '').includes(opt.value))}
                                            name="statement_type"
                                            onChange={handleChangeSelect}
                                            options={Options.statementTypeOptions}
                                            placeholder="აირჩიეთ განცხადების ტიპი"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>
                        
                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput iconInput">
                                <label>სრული ფასი</label>
                                <input type='number' name='price_from' value={searchQueryData?.price_from} onChange={(e)=>handleChangeInput(e)} placeholder='დან' />
                                <span>{currency}</span>
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput iconInput">
                                <label>&nbsp;</label>
                                <input type='number' name='price_to' value={searchQueryData?.price_to} onChange={(e)=>handleChangeInput(e)} placeholder='მდე' />
                                <span>{currency}</span>
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput iconInput">
                                <label>ვალუტა</label>
                                <div className="pricedetfltr">
                                    {["GEL", "USD", "EUR"].map((cur) => (
                                        <div
                                            key={cur}
                                            className={`snglpricedetfltr ${currency === cur ? "active" : ""}`} 
                                            onClick={() => setCurrency(cur) }
                                        >
                                            <p>{cur}</p>
                                            <input type="hidden" value={currency} name="currency" onChange={(e)=>handleChangeInput(e)} />
                                        </div>
                                    ))}
                                </div>
                            </div> 
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsTitle">მდებარეობა</h4>
                        </Col>
                        
                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">ქალაქი</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="city"
                                            value={Options.cityOptions.filter(opt => 
                                                (searchQueryData?.city || '').includes(opt.value))}
                                            name="city"
                                            onChange={handleChangeSelect}
                                            options={Options.cityOptions}
                                            placeholder="ქალაქი"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>

                        { searchQueryData?.city == 'tbilisi' ?
                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">უბანი</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="destrict"
                                            value={Options.destrictOptions.filter(opt => 
                                                (searchQueryData?.destrict || '').includes(opt.value))}
                                            name="destrict"
                                            onChange={handleChangeSelect}
                                            options={Options.destrictOptions}
                                            placeholder="უბანი"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>
                        : ''
                        }

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">რეგიონი</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="region"
                                            value={Options.districtOptions.filter(opt => 
                                                (searchQueryData?.region || '').includes(opt.value))}
                                            name="region"
                                            onChange={handleChangeSelect}
                                            options={Options.districtOptions}
                                            placeholder="რეგიონი"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">მუნიციპალიტეტი</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="municipality"
                                            value={Options.municipalOptions.filter(opt => 
                                                (searchQueryData?.municipality || '').includes(opt.value))}
                                            name="municipality"
                                            onChange={handleChangeSelect}
                                            options={Options.municipalOptions}
                                            placeholder="მუნიციპალიტეტი"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsTitle">პარამეტრები</h4>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>სრული ფართი (მ<sup>2</sup>)</label>
                                <input type='number' value={searchQueryData?.area_from} name='area_from' onChange={(e)=>handleChangeInput(e)} placeholder='დან' />
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>&nbsp;</label>
                                <input type='number' value={searchQueryData?.area_to} name='area_to' onChange={(e)=>handleChangeInput(e)} placeholder='მდე' />
                            </div>  
                        </Col>
                        <Col xxl={4} className="mb-3">&nbsp;</Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>ოთახების რაოდენობა</label>
                                <input type='number' value={searchQueryData?.rooms_from} name='rooms_from' onChange={(e)=>handleChangeInput(e)} placeholder='დან' />
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>&nbsp;</label>
                                <input type='number' value={searchQueryData?.rooms_to} name='rooms_to' onChange={(e)=>handleChangeInput(e)} placeholder='მდე' />
                            </div>  
                        </Col>
                        <Col xxl={4} className="mb-3">&nbsp;</Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>საძინებლების რაოდენობა</label>
                                <input type='number' value={searchQueryData?.bedrooms_from} name='bedrooms_from' onChange={(e)=>handleChangeInput(e)} placeholder='დან' />
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>&nbsp;</label>
                                <input type='number' value={searchQueryData?.bedrooms_to} name='bedrooms_to' onChange={(e)=>handleChangeInput(e)} placeholder='მდე' />
                            </div>  
                        </Col>
                        <Col xxl={4} className="mb-3">&nbsp;</Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>მასტერ საძ. რაოდენობა</label>
                                <input type='number' value={searchQueryData?.masterbedrooms_from} name='masterbedrooms_from' onChange={(e)=>handleChangeInput(e)} placeholder='დან' />
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>&nbsp;</label>
                                <input type='number' value={searchQueryData?.masterbedrooms_to} name='masterbedrooms_to' onChange={(e)=>handleChangeInput(e)} placeholder='მდე' />
                            </div>  
                        </Col>
                        <Col xxl={4} className="mb-3">&nbsp;</Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>სართული</label>
                                <input type='number' value={searchQueryData?.floor_from} name='floor_from' onChange={(e)=>handleChangeInput(e)} placeholder='დან' />
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>&nbsp;</label>
                                <input type='number' value={searchQueryData?.floor_to} name='floor_to' onChange={(e)=>handleChangeInput(e)} placeholder='მდე' />
                            </div>  
                        </Col>
                        <Col xxl={4} className="mb-3">&nbsp;</Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>სართული სულ</label>
                                <input type='number' value={searchQueryData?.allfloor_from} name='allfloor_from' onChange={(e)=>handleChangeInput(e)} placeholder='დან' />
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>&nbsp;</label>
                                <input type='number' value={searchQueryData?.allfloor_to} name='allfloor_to' onChange={(e)=>handleChangeInput(e)} placeholder='მდე' />
                            </div>  
                        </Col>
                        <Col xxl={4} className="mb-3">&nbsp;</Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>სველი წერტილები</label>
                                <input type='number' value={searchQueryData?.bathrooms_from} name='bathrooms_from' onChange={(e)=>handleChangeInput(e)} placeholder='დან' />
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>&nbsp;</label>
                                <input type='number' value={searchQueryData?.bathrooms_to} name='bathrooms_to' onChange={(e)=>handleChangeInput(e)} placeholder='მდე' />
                            </div>  
                        </Col>
                        <Col xxl={4} className="mb-3">&nbsp;</Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.livingroom == 'yes' } onChange={(e)=>handleChangeInput(e)} name="livingroom"/><span className="customFilterCheckbox"></span><strong>მისაღები</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.studio == 'yes' } onChange={(e)=>handleChangeInput(e)} name="studio"/><span className="customFilterCheckbox"></span><strong>სტუდიო</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.kitchen == 'yes' } onChange={(e)=>handleChangeInput(e)} name="kitchen" /><span className="customFilterCheckbox"></span><strong>სამზარეულო</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.openbalcony == 'yes' } onChange={(e)=>handleChangeInput(e)} name="openbalcony"/><span className="customFilterCheckbox"></span><strong>ღია აივანი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.closedbalcony == 'yes' } onChange={(e)=>handleChangeInput(e)} name="closedbalcony"/><span className="customFilterCheckbox"></span><strong>დახურული აივანი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.frenchbalcony == 'yes' } onChange={(e)=>handleChangeInput(e)} name="frenchbalcony"/><span className="customFilterCheckbox"></span><strong>ფრანგული აივანი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.terrace == 'yes' } onChange={(e)=>handleChangeInput(e)} name="terrace"/><span className="customFilterCheckbox"></span><strong>ვერანდა/ტერასა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.dressingroom == 'yes' } onChange={(e)=>handleChangeInput(e)} name="dressingroom"/><span className="customFilterCheckbox"></span><strong>საგარდერობო ოთახი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.storage == 'yes' } onChange={(e)=>handleChangeInput(e)} name="storage"/><span className="customFilterCheckbox"></span><strong>სათავსო</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.loft == 'yes' } onChange={(e)=>handleChangeInput(e)} name="loft"/><span className="customFilterCheckbox"></span><strong>სხვენი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.duplex == 'yes' } onChange={(e)=>handleChangeInput(e)} name="duplex"/><span className="customFilterCheckbox"></span><strong>დუპლექსი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.triplex == 'yes' } onChange={(e)=>handleChangeInput(e)} name="triplex"/><span className="customFilterCheckbox"></span><strong>ტრიპლექსი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.summerkitchen == 'yes' } onChange={(e)=>handleChangeInput(e)} name="summerkitchen"/><span className="customFilterCheckbox"></span><strong>საზაფხულო სამზარეულო</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.basement == 'yes' } onChange={(e)=>handleChangeInput(e)} name="basement"/><span className="customFilterCheckbox"></span><strong>სარდაფი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.loggia == 'yes' } onChange={(e)=>handleChangeInput(e)} name="loggia"/><span className="customFilterCheckbox"></span><strong>ლოჯი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.hall == 'yes' } onChange={(e)=>handleChangeInput(e)} name="hall"/><span className="customFilterCheckbox"></span><strong>ჰოლი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.buildup == 'yes' } onChange={(e)=>handleChangeInput(e)} name="buildup"/><span className="customFilterCheckbox"></span><strong>დაშენება</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.buildup1 == 'yes' } onChange={(e)=>handleChangeInput(e)} name="buildup1"/><span className="customFilterCheckbox"></span><strong>მიშენება</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.commoncorridor == 'yes' } onChange={(e)=>handleChangeInput(e)} name="commoncorridor"/><span className="customFilterCheckbox"></span><strong>საერთო კორიდორი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.antresor == 'yes' } onChange={(e)=>handleChangeInput(e)} name="antresor"/><span className="customFilterCheckbox"></span><strong>ანტრესორი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.wintergarden == 'yes' } onChange={(e)=>handleChangeInput(e)} name="wintergarden"/><span className="customFilterCheckbox"></span><strong>ზამთრის ბაღი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.garage == 'yes' } onChange={(e)=>handleChangeInput(e)} name="garage"/><span className="customFilterCheckbox"></span><strong>ავტოფარეხი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.yardparking == 'yes' } onChange={(e)=>handleChangeInput(e)} name="yardparking"/><span className="customFilterCheckbox"></span><strong>ეზოს პარკინგი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.undergroundparking == 'yes' } onChange={(e)=>handleChangeInput(e)} name="undergroundparking"/><span className="customFilterCheckbox"></span><strong>მიწისქვეშა პარკინგი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.parkingspace == 'yes' } onChange={(e)=>handleChangeInput(e)} name="parkingspace"/><span className="customFilterCheckbox"></span><strong>პარკინგის ადგილი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.paidparking == 'yes' } onChange={(e)=>handleChangeInput(e)} name="paidparking"/><span className="customFilterCheckbox"></span><strong>ფასიანი პარკინგი</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsTitle">კეთილმოწყობა</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.elevator == 'yes' } onChange={(e)=>handleChangeInput(e)} name="elevator"/><span className="customFilterCheckbox"></span><strong>სამგზავრო ლიფტი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.freightelevator == 'yes' } onChange={(e)=>handleChangeInput(e)} name="freightelevator"/><span className="customFilterCheckbox"></span><strong>სატვირთო ლიფტი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.personalelevator == 'yes' } onChange={(e)=>handleChangeInput(e)} name="personalelevator"/><span className="customFilterCheckbox"></span><strong>პერსონალური ლიფტი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.outdoorpool == 'yes' } onChange={(e)=>handleChangeInput(e)} name="outdoorpool"/><span className="customFilterCheckbox"></span><strong>ღია აუზი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.Indoorpool == 'yes' } onChange={(e)=>handleChangeInput(e)} name="Indoorpool"/><span className="customFilterCheckbox"></span><strong>დახურული აუზი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.fireplace == 'yes' } onChange={(e)=>handleChangeInput(e)} name="fireplace"/><span className="customFilterCheckbox"></span><strong>ბუხარი</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">ცხელი წყალი</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.hotwater?.includes('centralheating') ? true : false } value="centralheating" onChange={(e)=>handleChangeInput(e)} name="hotwater[]" /><span className="customFilterCheckbox"></span><strong>ცენტრალური გათბობის სისტემა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.hotwater?.includes('gasheater') ? true : false } value="gasheater" onChange={(e)=>handleChangeInput(e)}  name="hotwater[]" /><span className="customFilterCheckbox"></span><strong>გაზის გამაცხელებელი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.hotwater?.includes('tank') ? true : false } value="tank" onChange={(e)=>handleChangeInput(e)}  name="hotwater[]" /><span className="customFilterCheckbox"></span><strong>ავზი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.hotwater?.includes('electricheater') ? true : false } value="electricheater" onChange={(e)=>handleChangeInput(e)}  name="hotwater[]" /><span className="customFilterCheckbox"></span><strong>დენის გამაცხელებელი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.hotwater?.includes('naturalhotwater') ? true : false } value="naturalhotwater" onChange={(e)=>handleChangeInput(e)}  name="hotwater[]" /><span className="customFilterCheckbox"></span><strong>ბუნებრივი ცხელი წყალი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.hotwater?.includes('solarheater') ? true : false } value="solarheater" onChange={(e)=>handleChangeInput(e)}  name="hotwater[]" /><span className="customFilterCheckbox"></span><strong>მზის გამათბობელი</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">გათბობა</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.heating?.includes('centralheating') ? true : false } value="centralheating" onChange={(e)=>handleChangeInput(e)} name="heating[]" /><span className="customFilterCheckbox"></span><strong>ცენტრალური გათბობა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.heating?.includes('gasheater') ? true : false } value="gasheater" onChange={(e)=>handleChangeInput(e)}  name="heating[]" /><span className="customFilterCheckbox"></span><strong>გაზის გამათბობელი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.heating?.includes('floorheating') ? true : false } value="floorheating" onChange={(e)=>handleChangeInput(e)}  name="heating[]" /><span className="customFilterCheckbox"></span><strong>იატაკის გათბობა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.heating?.includes('powerheater') ? true : false } value="powerheater" onChange={(e)=>handleChangeInput(e)}  name="heating[]" /><span className="customFilterCheckbox"></span><strong>დენის გამათბობელი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.heating?.includes('chiller') ? true : false } value="chiller" onChange={(e)=>handleChangeInput(e)}  name="heating[]" /><span className="customFilterCheckbox"></span><strong>ჩილერი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.heating?.includes('solarheater') ? true : false } value="solarheater" onChange={(e)=>handleChangeInput(e)}  name="heating[]" /><span className="customFilterCheckbox"></span><strong>მზის გამათბობელი</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">ზოგადი კეთილმოწყობა</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.generalamenities?.includes('naturalgas') ? true : false } value="naturalgas" onChange={(e)=>handleChangeInput(e)} name="generalamenities[]" /><span className="customFilterCheckbox"></span><strong>ბუნებრივი აირი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.generalamenities?.includes('generator') ? true : false } value="generator" onChange={(e)=>handleChangeInput(e)}  name="generalamenities[]" /><span className="customFilterCheckbox"></span><strong>გენერატორი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.generalamenities?.includes('internet') ? true : false } value="internet" onChange={(e)=>handleChangeInput(e)}  name="generalamenities[]" /><span className="customFilterCheckbox"></span><strong>ინტერნეტი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.generalamenities?.includes('television') ? true : false } value="television" onChange={(e)=>handleChangeInput(e)}  name="generalamenities[]" /><span className="customFilterCheckbox"></span><strong>ტელევიზია</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.generalamenities?.includes('alarmsystem') ? true : false } value="alarmsystem" onChange={(e)=>handleChangeInput(e)}  name="generalamenities[]" /><span className="customFilterCheckbox"></span><strong>სიგნალიზაცია</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.generalamenities?.includes('satelit') ? true : false } value="satelit" onChange={(e)=>handleChangeInput(e)}  name="generalamenities[]" /><span className="customFilterCheckbox"></span><strong>სატელიტური თეფში</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.generalamenities?.includes('soalrPanel') ? true : false } value="soalrPanel" onChange={(e)=>handleChangeInput(e)}  name="generalamenities[]" /><span className="customFilterCheckbox"></span><strong>მზის ენერგიის პანელი</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">ფასადი</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.facade1?.includes('galesili') ? true : false } value="galesili" onChange={(e)=>handleChangeInput(e)} name="facade1[]" /><span className="customFilterCheckbox"></span><strong>გალესილი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.facade1?.includes('facadePanel') ? true : false } value="facadePanel" onChange={(e)=>handleChangeInput(e)}  name="facade1[]" /><span className="customFilterCheckbox"></span><strong>ფასადის პანელი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.facade1?.includes('aguri') ? true : false } value="aguri" onChange={(e)=>handleChangeInput(e)}  name="facade1[]" /><span className="customFilterCheckbox"></span><strong>აგური</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.facade1?.includes('traventili') ? true : false } value="traventili" onChange={(e)=>handleChangeInput(e)}  name="facade1[]" /><span className="customFilterCheckbox"></span><strong>ტრავენტილი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.facade1?.includes('xisPaneli') ? true : false } value="xisPaneli" onChange={(e)=>handleChangeInput(e)}  name="facade1[]" /><span className="customFilterCheckbox"></span><strong>ხის პანელი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.facade1?.includes('hplPanel') ? true : false } value="hplPanel" onChange={(e)=>handleChangeInput(e)}  name="facade1[]" /><span className="customFilterCheckbox"></span><strong>HPL პანელი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.facade1?.includes('combined') ? true : false } value="combined" onChange={(e)=>handleChangeInput(e)}  name="facade1[]" /><span className="customFilterCheckbox"></span><strong>კომბინირებული</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">ავეჯი</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.furniture?.includes('livingroomfurniture') ? true : false } value="livingroomfurniture" onChange={(e)=>handleChangeInput(e)} name="furniture[]" /><span className="customFilterCheckbox"></span><strong>მისაღები ოთახის ავეჯი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.furniture?.includes('softfurniture') ? true : false } value="softfurniture" onChange={(e)=>handleChangeInput(e)}  name="furniture[]" /><span className="customFilterCheckbox"></span><strong>რბილი ავეჯი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.furniture?.includes('kitchenfurniture') ? true : false } value="kitchenfurniture" onChange={(e)=>handleChangeInput(e)}  name="furniture[]" /><span className="customFilterCheckbox"></span><strong>სამზარეულოს ავეჯი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.furniture?.includes('bedroomfurniture') ? true : false } value="bedroomfurniture" onChange={(e)=>handleChangeInput(e)}  name="furniture[]" /><span className="customFilterCheckbox"></span><strong>საძინებლის ავეჯი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.furniture?.includes('summerfurniture') ? true : false } value="summerfurniture" onChange={(e)=>handleChangeInput(e)}  name="furniture[]" /><span className="customFilterCheckbox"></span><strong>საზაფხულო ავეჯი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.furniture?.includes('officefurniture') ? true : false } value="officefurniture" onChange={(e)=>handleChangeInput(e)}  name="furniture[]" /><span className="customFilterCheckbox"></span><strong>საოფისე ავეჯი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.furniture?.includes('childrensfurniture') ? true : false } value="childrensfurniture" onChange={(e)=>handleChangeInput(e)}  name="furniture[]" /><span className="customFilterCheckbox"></span><strong>საბავშვო ავეჯი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.furniture?.includes('partiallyfurnished') ? true : false } value="partiallyfurnished" onChange={(e)=>handleChangeInput(e)}  name="furniture[]" /><span className="customFilterCheckbox"></span><strong>ნაწილობრივ ავეჯი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.furniture?.includes('unfurnished') ? true : false } value="unfurnished" onChange={(e)=>handleChangeInput(e)}  name="furniture[]" /><span className="customFilterCheckbox"></span><strong>ავეჯის გარეშე</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">ტექნიკა</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('television') ? true : false } value="television" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>ტელევიზორი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('refrigerator') ? true : false } value="refrigerator" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>მაცივარი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('microwaveoven') ? true : false } value="microwaveoven" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>მიკროტალღური ღუმელი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('dishwasher') ? true : false } value="dishwasher" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>ჭურჭლის სარეცხი მანქანა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('washingmachine') ? true : false } value="washingmachine" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>სარეცხი მანქანა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('airconditioner') ? true : false } value="airconditioner" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>კონდინციონერი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('stereosystem') ? true : false } value="stereosystem" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>მუსიკალური ცენტრი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('rangehood') ? true : false } value="rangehood" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>გამწოვი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('stove') ? true : false } value="stove" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>გაზქურა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('vacuumcleaner') ? true : false } value="vacuumcleaner" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>მტვერსასრუტი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('iron') ? true : false } value="iron" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>უთო</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('homecinema') ? true : false } value="homecinema" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>კინოთეატრი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('dryer') ? true : false } value="dryer" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>საშრობი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('coffeemaker') ? true : false } value="coffeemaker" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>ყავის აპარატი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('electrickettle') ? true : false } value="electrickettle" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>ელექტრო ჩაიდანი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('toaster') ? true : false } value="toaster" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>ტოსტერი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('juicer') ? true : false } value="juicer" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>წვენსაწური</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('mixer') ? true : false } value="mixer" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>მიქსერი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('blender') ? true : false } value="blender" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>ბლენდერი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('meatgrinder') ? true : false } value="meatgrinder" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>ხორცის საკეპი მანქანა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('computer') ? true : false } value="computer" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>კომპიუტერი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('printerscannerxerox') ? true : false } value="printerscannerxerox" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>პრინტერი/სკანერი/ქსეროქსი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('safe') ? true : false } value="safe" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>სეიფი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.technic?.includes('fitnessequipment') ? true : false } value="fitnessequipment" onChange={(e)=>handleChangeInput(e)} name="technic[]" /><span className="customFilterCheckbox"></span><strong>სპორტული ტრენაჟორები</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsTitle">უპირატესობები</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">დაცვის სერვისი</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.security?.includes('alarmsystem') ? true : false } value="alarmsystem" onChange={(e)=>handleChangeInput(e)} name="security[]" /><span className="customFilterCheckbox"></span><strong>სიგნალიზაცია</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.security?.includes('livesecurity') ? true : false } value="livesecurity" onChange={(e)=>handleChangeInput(e)}  name="security[]" /><span className="customFilterCheckbox"></span><strong>ცოცხალი დაცვა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.security?.includes('cctvcameras') ? true : false } value="cctvcameras" onChange={(e)=>handleChangeInput(e)}  name="security[]" /><span className="customFilterCheckbox"></span><strong>ვიდეო კამერები</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.security?.includes('entranceaccesssystem') ? true : false } value="entranceaccesssystem" onChange={(e)=>handleChangeInput(e)}  name="security[]" /><span className="customFilterCheckbox"></span><strong>სადარბაზოს დაშვების სისტემა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.security?.includes('gatedaccesssystem') ? true : false } value="gatedaccesssystem" onChange={(e)=>handleChangeInput(e)}  name="security[]" /><span className="customFilterCheckbox"></span><strong>ჩაკეტილი ეზოს დაშვების სისტემა</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">ჭკვიანი სახლი</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.smarthouse?.includes('controlpanels') ? true : false } value="controlpanels" onChange={(e)=>handleChangeInput(e)} name="smarthouse[]" /><span className="customFilterCheckbox"></span><strong>მართვის პანელები</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.smarthouse?.includes('securitysystems') ? true : false } value="securitysystems" onChange={(e)=>handleChangeInput(e)} name="smarthouse[]" /><span className="customFilterCheckbox"></span><strong>უსაფრთხოების სისტემები</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.smarthouse?.includes('smartcamera') ? true : false } value="smartcamera" onChange={(e)=>handleChangeInput(e)} name="smarthouse[]" /><span className="customFilterCheckbox"></span><strong>ჭკვიანი კამერა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.smarthouse?.includes('curtainblind') ? true : false } value="curtainblind" onChange={(e)=>handleChangeInput(e)} name="smarthouse[]" /><span className="customFilterCheckbox"></span><strong>ფარდა ჟალუზი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.smarthouse?.includes('lightingautomation') ? true : false } value="lightingautomation" onChange={(e)=>handleChangeInput(e)} name="smarthouse[]" /><span className="customFilterCheckbox"></span><strong>განათების ავტომატიზირება</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.smarthouse?.includes('smartlock') ? true : false } value="smartlock" onChange={(e)=>handleChangeInput(e)} name="smarthouse[]" /><span className="customFilterCheckbox"></span><strong>ჭკვიანი საკეტი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.smarthouse?.includes('householdappliancecontrol') ? true : false } value="householdappliancecontrol" onChange={(e)=>handleChangeInput(e)} name="smarthouse[]" /><span className="customFilterCheckbox"></span><strong>საყოფაცხოვრებო ტექნიკის მართვა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.smarthouse?.includes('audiosystem') ? true : false } value="audiosystem" onChange={(e)=>handleChangeInput(e)} name="smarthouse[]" /><span className="customFilterCheckbox"></span><strong>აუდიო სისტემა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.smarthouse?.includes('smartintercom') ? true : false } value="smartintercom" onChange={(e)=>handleChangeInput(e)} name="smarthouse[]" /><span className="customFilterCheckbox"></span><strong>ჭკვიანი დომოფონი</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">დამატებითი უპირატესობები</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('organizedinfrastructure') ? true : false } value="organizedinfrastructure" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>მოწესრიგებული ინფრასტრუქტურა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('panoramicview') ? true : false } value="panoramicview" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>პანორამული ხედი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('cityview') ? true : false } value="cityview" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>ხედი ქალაქზე</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('seaview') ? true : false } value="seaview" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>ხედი ზღვაზე</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('mountainview') ? true : false } value="mountainview" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>ხედი მთაზე</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('riverview') ? true : false } value="riverview" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>ხედი მდინარეზე</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('lakeview') ? true : false } value="lakeview" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>ხედი ტბაზე</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('courtyardview') ? true : false } value="courtyardview" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>ხედი ეზოში</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('bright') ? true : false } value="bright" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>ნათელი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('stainedglasswindowdoor') ? true : false } value="stainedglasswindowdoor" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>ვიტრაჟული კარ-ფანჯარა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('landscaping') ? true : false } value="landscaping" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>გამწვანება</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('childrenspace') ? true : false } value="childrenspace" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>საბავშვო სივრცე</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('sportsfield') ? true : false } value="sportsfield" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>სპორტული მოედანი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('concierge') ? true : false } value="concierge" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>კონსიაჟი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('coworkingspace') ? true : false } value="coworkingspace" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>საერთო სამუშაო სივრცე</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('businesslobby') ? true : false } value="businesslobby" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>ბიზნეს ლობი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('cafeteria') ? true : false } value="cafeteria" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>კაფეტერია</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('restaurant') ? true : false } value="restaurant" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>რესტორანი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('conferencehall') ? true : false } value="conferencehall" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>საკონფერენციო დარბაზი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('eventhall') ? true : false } value="eventhall" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>ივენთ ჰოლი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('commercialspace') ? true : false } value="commercialspace" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>კომერციული ფართი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('residentiallobby') ? true : false } value="residentiallobby" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>საცხოივრებელი ლობი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('freewifi') ? true : false } value="freewifi" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>უფასო Wifi</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('laundry') ? true : false } value="laundry" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>სამრეცხაო</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('spacenter') ? true : false } value="spacenter" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>სპა ცენტრი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('sauna') ? true : false } value="sauna" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>საუნა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('fitnessroom') ? true : false } value="fitnessroom" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>ფიტნეს დარბაზი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('tenniscourts') ? true : false } value="tenniscourts" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>კორტები</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('golfcourse') ? true : false } value="golfcourse" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>გოლფის მოედანი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('multifunctional') ? true : false } value="multifunctional" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>მულტიფუნქციური</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('energyefficient') ? true : false } value="energyefficient" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>ენერგო ეფექტური</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('modernarchitecture') ? true : false } value="modernarchitecture" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>თანამედროვე არქიტექტურა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('glazedbalconies') ? true : false } value="glazedbalconies" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>შემინული აივნები</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('jacuzzi') ? true : false } value="jacuzzi" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>ჯაკუზი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaladvantages?.includes('showercabinplusbathtub') ? true : false } value="showercabinplusbathtub" onChange={(e)=>handleChangeInput(e)} name="additionaladvantages[]" />
                                    <span className="customFilterCheckbox"></span><strong>დუშ კაბინა + ვანა</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">დამატებითი უსაფრთხოება</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionalsecurity?.includes('evacuationstaircase') ? true : false } value="evacuationstaircase" onChange={(e)=>handleChangeInput(e)} name="additionalsecurity[]" />
                                    <span className="customFilterCheckbox"></span><strong>საევაკუაციო კიბე</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionalsecurity?.includes('firesafetyssystem') ? true : false } value="firesafetyssystem" onChange={(e)=>handleChangeInput(e)} name="additionalsecurity[]" />
                                    <span className="customFilterCheckbox"></span><strong>ხანძრის საწინააღმდეგო სისტემა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionalsecurity?.includes('codetector') ? true : false } value="codetector" onChange={(e)=>handleChangeInput(e)} name="additionalsecurity[]" />
                                    <span className="customFilterCheckbox"></span><strong>CO დეტექტორი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionalsecurity?.includes('smokedetector') ? true : false } value="smokedetector" onChange={(e)=>handleChangeInput(e)} name="additionalsecurity[]" />
                                    <span className="customFilterCheckbox"></span><strong>კვამლის დეტექტორი</strong>
                                </label>
                            </div>
                        </Col>
                        
                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">ობიექტთან ახლოს</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('citycenter') ? true : false } value="citycenter" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>ქალაქის ცენტრი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('busstop') ? true : false } value="busstop" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>ავტობუსის გაჩერება</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('shoppingcenter') ? true : false } value="shoppingcenter" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>სავაჭრო ცენტრი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('school') ? true : false } value="school" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>სკოლა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('kindergarten') ? true : false } value="kindergarten" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>საბავშვო ბაღი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('metrosstation') ? true : false } value="metrosstation" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>მეტროსადგური</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('cinema') ? true : false } value="cinema" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>კინოთეატრი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('square') ? true : false } value="square" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>სკვერი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('museum') ? true : false } value="museum" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>მუზეუმი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('restaurant') ? true : false } value="restaurant" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>რესტორანი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('supermarket') ? true : false } value="supermarket" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>სუპერმარკეტი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('pharmacy') ? true : false } value="pharmacy" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>აფთიაქი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('hospital') ? true : false } value="hospital" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>საავადმყოფო</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('railwaystation') ? true : false } value="railwaystation" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>რკინიგზის სადგური</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('airport') ? true : false } value="airport" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>აეროპორტი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('sea') ? true : false } value="sea" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>ზღვა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('river') ? true : false } value="river" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>მდინარე</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('lake') ? true : false } value="lake" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>ტბა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('naturereserve') ? true : false } value="naturereserve" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>ნაკრძალი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('forest') ? true : false } value="forest" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>ტყე</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.nearobject?.includes('university') ? true : false } value="university" onChange={(e)=>handleChangeInput(e)} name="nearobject[]" />
                                    <span className="customFilterCheckbox"></span><strong>უნივერსიტეტი</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">დაშვებულია</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.allowed?.includes('eventhosting') ? true : false } value="eventhosting" onChange={(e)=>handleChangeInput(e)} name="allowed[]" />
                                    <span className="customFilterCheckbox"></span><strong>წვეულების გამართვა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.allowed?.includes('pets') ? true : false } value="pets" onChange={(e)=>handleChangeInput(e)} name="allowed[]" />
                                    <span className="customFilterCheckbox"></span><strong>შინაური ცხოველები</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.allowed?.includes('smoking') ? true : false } value="smoking" onChange={(e)=>handleChangeInput(e)} name="allowed[]" />
                                    <span className="customFilterCheckbox"></span><strong>მოწევა</strong>
                                </label>
                            </div>
                        </Col>

                        { searchQueryData?.property_type?.includes('house') || searchQueryData?.property_type?.includes('townhouse') ?
                        <>
                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsTitle">კერძო სახლი-აგარაკის / თაუნ ჰაუსის დეტალები</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.yeard == 'yes' } onChange={(e)=>handleChangeInput(e)}  name="yeard" />
                                    <span className="customFilterCheckbox"></span><strong>ეზო</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.fence == 'yes' } onChange={(e)=>handleChangeInput(e)}  name="fence" />
                                    <span className="customFilterCheckbox"></span><strong>ღობე</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.capitalfence == 'yes' } onChange={(e)=>handleChangeInput(e)}  name="capitalfence" />
                                    <span className="customFilterCheckbox"></span><strong>ღობე</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.vine == 'yes' } onChange={(e)=>handleChangeInput(e)}  name="vine" />
                                    <span className="customFilterCheckbox"></span><strong>ვაზი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.fruit == 'yes' } onChange={(e)=>handleChangeInput(e)}  name="fruit" />
                                    <span className="customFilterCheckbox"></span><strong>ხეხილი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.citrus == 'yes' } onChange={(e)=>handleChangeInput(e)}  name="citrus" />
                                    <span className="customFilterCheckbox"></span><strong>ციტრუსი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.leafy == 'yes' } onChange={(e)=>handleChangeInput(e)}  name="leafy" />
                                    <span className="customFilterCheckbox"></span><strong>ფოთლოვანი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.coniferous == 'yes' } onChange={(e)=>handleChangeInput(e)}  name="coniferous" />
                                    <span className="customFilterCheckbox"></span><strong>წიწვოვანი</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">გზა</h4>
                        </Col>
                        
                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.road?.includes('asphalted') ? true : false } value="asphalted" onChange={(e)=>handleChangeInput(e)} name="road[]" />
                                    <span className="customFilterCheckbox"></span><strong>ასფალტიანი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.road?.includes('graveled') ? true : false } value="graveled" onChange={(e)=>handleChangeInput(e)} name="road[]" />
                                    <span className="customFilterCheckbox"></span><strong>მოხრეშილი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.road?.includes('soil') ? true : false } value="soil" onChange={(e)=>handleChangeInput(e)} name="road[]" />
                                    <span className="customFilterCheckbox"></span><strong>გრუნტი</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">აშენების მასალა</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.buildingmaterial?.includes('block') ? true : false } value="block" onChange={(e)=>handleChangeInput(e)} name="buildingmaterial[]" />
                                    <span className="customFilterCheckbox"></span><strong>ბლოკი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.buildingmaterial?.includes('brick') ? true : false } value="brick" onChange={(e)=>handleChangeInput(e)} name="buildingmaterial[]" />
                                    <span className="customFilterCheckbox"></span><strong>აგური</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.buildingmaterial?.includes('stone') ? true : false } value="stone" onChange={(e)=>handleChangeInput(e)} name="buildingmaterial[]" />
                                    <span className="customFilterCheckbox"></span><strong>ქვა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.buildingmaterial?.includes('wood') ? true : false } value="wood" onChange={(e)=>handleChangeInput(e)} name="buildingmaterial[]" />
                                    <span className="customFilterCheckbox"></span><strong>ხე</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.buildingmaterial?.includes('pumiceblock') ? true : false } value="pumiceblock" onChange={(e)=>handleChangeInput(e)} name="buildingmaterial[]" />
                                    <span className="customFilterCheckbox"></span><strong>პემზის ბლოკი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.buildingmaterial?.includes('reinforcedconcrete') ? true : false } value="reinforcedconcrete" onChange={(e)=>handleChangeInput(e)} name="buildingmaterial[]" />
                                    <span className="customFilterCheckbox"></span><strong>რკინა-ბეტონი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.buildingmaterial?.includes('sendvichPanel') ? true : false } value="sendvichPanel" onChange={(e)=>handleChangeInput(e)} name="buildingmaterial[]" />
                                    <span className="customFilterCheckbox"></span><strong>სენდვიჩ პანელი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.buildingmaterial?.includes('minaPaket') ? true : false } value="minaPaket" onChange={(e)=>handleChangeInput(e)} name="buildingmaterial[]" />
                                    <span className="customFilterCheckbox"></span><strong>მინა პაკეტი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.buildingmaterial?.includes('combined') ? true : false } value="combined" onChange={(e)=>handleChangeInput(e)} name="buildingmaterial[]" />
                                    <span className="customFilterCheckbox"></span><strong>კომბინირებული</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">გადახურვა</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.overlap?.includes('flat') ? true : false } value="flat" onChange={(e)=>handleChangeInput(e)} name="overlap[]" />
                                    <span className="customFilterCheckbox"></span><strong>ბრტყელი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.overlap?.includes('conical') ? true : false } value="conical" onChange={(e)=>handleChangeInput(e)} name="overlap[]" />
                                    <span className="customFilterCheckbox"></span><strong>კონუსებური</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.overlap?.includes('tinroofing') ? true : false } value="tinroofing" onChange={(e)=>handleChangeInput(e)} name="overlap[]" />
                                    <span className="customFilterCheckbox"></span><strong>თუნუქის გადახურვა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.overlap?.includes('corrugatedroofing') ? true : false } value="corrugatedroofing" onChange={(e)=>handleChangeInput(e)} name="overlap[]" />
                                    <span className="customFilterCheckbox"></span><strong>ტოლით დაგახურვა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.overlap?.includes('tileroof') ? true : false } value="tileroof" onChange={(e)=>handleChangeInput(e)} name="overlap[]" />
                                    <span className="customFilterCheckbox"></span><strong>კრამიტის სახურავი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.overlap?.includes('sendvich') ? true : false } value="sendvich" onChange={(e)=>handleChangeInput(e)} name="overlap[]" />
                                    <span className="customFilterCheckbox"></span><strong>სენდვიჩ პანელი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.overlap?.includes('beton') ? true : false } value="beton" onChange={(e)=>handleChangeInput(e)} name="overlap[]" />
                                    <span className="customFilterCheckbox"></span><strong>ბეტონი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.overlap?.includes('tagovani') ? true : false } value="tagovani" onChange={(e)=>handleChangeInput(e)} name="overlap[]" />
                                    <span className="customFilterCheckbox"></span><strong>თაღოვანი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.overlap?.includes('piramid') ? true : false } value="piramid" onChange={(e)=>handleChangeInput(e)} name="overlap[]" />
                                    <span className="customFilterCheckbox"></span><strong>პირამიდული</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.overlap?.includes('valmur') ? true : false } value="valmur" onChange={(e)=>handleChangeInput(e)} name="overlap[]" />
                                    <span className="customFilterCheckbox"></span><strong>ვალმური</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.overlap?.includes('gambler') ? true : false } value="gambler" onChange={(e)=>handleChangeInput(e)} name="overlap[]" />
                                    <span className="customFilterCheckbox"></span><strong>გამბლერის სახურავი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.overlap?.includes('mansard') ? true : false } value="mansard" onChange={(e)=>handleChangeInput(e)} name="overlap[]" />
                                    <span className="customFilterCheckbox"></span><strong>მანსარდული</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">ფუნდამენტის ტიპი</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.fundament?.includes('lent') ? true : false } value="lent" onChange={(e)=>handleChangeInput(e)} name="fundament[]" />
                                    <span className="customFilterCheckbox"></span><strong>ლენტოვანი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.fundament?.includes('khiminji') ? true : false } value="khiminji" onChange={(e)=>handleChangeInput(e)} name="fundament[]" />
                                    <span className="customFilterCheckbox"></span><strong>ხიმინჯი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.fundament?.includes('balishovani') ? true : false } value="balishovani" onChange={(e)=>handleChangeInput(e)} name="fundament[]" />
                                    <span className="customFilterCheckbox"></span><strong>ბალიშოვანი</strong>
                                </label>
                            </div>
                        </Col>
                        
                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">პროექტი</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.project2 == 'yes' } onChange={(e)=>handleChangeInput(e)} name="project2" />
                                    <span className="customFilterCheckbox"></span><strong>პროექტი</strong>
                                </label>
                            </div>
                        </Col>
                        </>
                        : '' }

                        { searchQueryData?.property_type?.includes('comercial') || searchQueryData?.property_type?.includes('office') ?
                        <>
                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsTitle">კომერციული / საოფისე ფართის დეტალები</h4>
                        </Col>

                        <Col xxl={6} className="mb-3">
                            <label className="filterLbl">ფართის ტიპი</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="typeofspace"
                                            value={Options.areaTypes.filter(opt => 
                                                (searchQueryData?.typeofspace || '').includes(opt.value))}
                                            name="typeofspace"
                                            onChange={handleChangeSelect}
                                            options={Options.areaTypes}
                                            placeholder="აირჩიეთ ფართის ტიპი"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>
                        <Col xxl={6} className="mb-3">&nbsp;</Col>

                        <Col xxl={12} className="mb-1">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.showcase == 'yes' } onChange={(e)=>handleChangeInput(e)} name="showcase" />
                                    <span className="customFilterCheckbox"></span><strong>ვიტრინა</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>ვიტრინების რაოდენობა</label>
                                <input type='number' value={searchQueryData?.showcase_from} onChange={(e)=>handleChangeInput(e)} name='showcase_from' placeholder='დან' />
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>&nbsp;</label>
                                <input type='number' value={searchQueryData?.showcase_to} onChange={(e)=>handleChangeInput(e)} name='showcase_to' placeholder='მდე' />
                            </div>  
                        </Col>

                        <Col xxl={12} className="mb-1">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.hall2 == 'yes' } onChange={(e)=>handleChangeInput(e)} name="hall2" />
                                    <span className="customFilterCheckbox"></span><strong>დარბაზი</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>დარბაზის რაოდენობა</label>
                                <input type='number' value={searchQueryData?.hall2_from} onChange={(e)=>handleChangeInput(e)} name='hall2_from' placeholder='დან' />
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>&nbsp;</label>
                                <input type='number' value={searchQueryData?.hall2_to} onChange={(e)=>handleChangeInput(e)} name='hall2_to' placeholder='მდე' />
                            </div>  
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.facade == 'yes' } onChange={(e)=>handleChangeInput(e)} name="facade" />
                                    <span className="customFilterCheckbox"></span><strong>ფასადი</strong>
                                </label>
                            </div>
                        </Col>
                        </>
                        : '' }

                        { searchQueryData?.property_type?.includes('land') ?
                        <>
                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsTitle">მიწის დეტალები</h4>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">მიწის ტიპი</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="landtype"
                                            value={Options.landTypes.filter(opt => 
                                                (searchQueryData?.landtype || '').includes(opt.value))}
                                            name="landtype"
                                            onChange={handleChangeSelect}
                                            options={Options.landTypes}
                                            placeholder="აირჩიეთ ტიპი"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">ფუნქციური ზონა</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="functionalarea"
                                            value={Options.functgionZones.filter(opt => 
                                                (searchQueryData?.functionalarea || '').includes(opt.value))}
                                            name="functionalarea"
                                            onChange={handleChangeSelect}
                                            options={Options.functgionZones}
                                            placeholder="აირჩიეთ ზონა"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">სას. სამეურნეო მიწის ტიპი</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="agriculturalland"
                                            value={Options.agriculturalTypes.filter(opt => 
                                                (searchQueryData?.agriculturalland || '').includes(opt.value))}
                                            name="agriculturalland"
                                            onChange={handleChangeSelect}
                                            options={Options.agriculturalTypes}
                                            placeholder="აირჩიეთ ტიპი"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>

                        <Col xxl={12} className="mb-1">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.building == 'yes' } onChange={(e)=>handleChangeInput(e)} name="building" />
                                    <span className="customFilterCheckbox"></span><strong>შენობა ნაგებობა</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>შენობა ნაგებობის ფართი (მ<sup>2</sup>)</label>
                                <input type='number' value={searchQueryData?.buildingarea_from} onChange={(e)=>handleChangeInput(e)} name='buildingarea_from' placeholder='დან' />
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>&nbsp;</label>
                                <input type='number' value={searchQueryData?.buildingarea_to} onChange={(e)=>handleChangeInput(e)} name='buildingarea_to' placeholder='მდე' />
                            </div>  
                        </Col>

                        <Col xxl={12} className="mb-1">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.agreedproject == 'yes' } onChange={(e)=>handleChangeInput(e)}  name="agreedproject" />
                                    <span className="customFilterCheckbox"></span><strong>შეთანხმებული პროექტი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.buildingpermission == 'yes' } onChange={(e)=>handleChangeInput(e)}  name="buildingpermission" />
                                    <span className="customFilterCheckbox"></span><strong>მშენებლობის ნებართვა</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">კომუნიკაციები</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications?.includes('road') ? true : false } value="road" onChange={(e)=>handleChangeInput(e)} name="communications[]" />
                                    <span className="customFilterCheckbox"></span><strong>გზა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications?.includes('electricity') ? true : false } value="electricity" onChange={(e)=>handleChangeInput(e)} name="communications[]" />
                                    <span className="customFilterCheckbox"></span><strong>დენი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications?.includes('water') ? true : false } value="water" onChange={(e)=>handleChangeInput(e)} name="communications[]" />
                                    <span className="customFilterCheckbox"></span><strong>წყალი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications?.includes('gas') ? true : false } value="gas" onChange={(e)=>handleChangeInput(e)} name="communications[]" />
                                    <span className="customFilterCheckbox"></span><strong>გაზი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications?.includes('sewercollector') ? true : false } value="sewercollector" onChange={(e)=>handleChangeInput(e)} name="communications[]" />
                                    <span className="customFilterCheckbox"></span><strong>კოლექტორი-კანალიზაცია</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications?.includes('well') ? true : false } value="well" onChange={(e)=>handleChangeInput(e)} name="communications[]" />
                                    <span className="customFilterCheckbox"></span><strong>ჭა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications?.includes('easement') ? true : false } value="easement" onChange={(e)=>handleChangeInput(e)} name="communications[]" />
                                    <span className="customFilterCheckbox"></span><strong>სერვიტუტი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications?.includes('firstrow') ? true : false } value="firstrow" onChange={(e)=>handleChangeInput(e)} name="communications[]" />
                                    <span className="customFilterCheckbox"></span><strong>პირველი ზოლი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications?.includes('secondrow') ? true : false } value="secondrow" onChange={(e)=>handleChangeInput(e)} name="communications[]" />
                                    <span className="customFilterCheckbox"></span><strong>მეორე ზოლი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications?.includes('thirdrow') ? true : false } value="thirdrow" onChange={(e)=>handleChangeInput(e)} name="communications[]" />
                                    <span className="customFilterCheckbox"></span><strong>მესამე ზოლი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications?.includes('fence') ? true : false } value="fence" onChange={(e)=>handleChangeInput(e)} name="communications[]" />
                                    <span className="customFilterCheckbox"></span><strong>ღობე</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications?.includes('capitalfence') ? true : false } value="capitalfence" onChange={(e)=>handleChangeInput(e)} name="communications[]" />
                                    <span className="customFilterCheckbox"></span><strong>კაპიტალური ღობე</strong>
                                </label>
                            </div>
                        </Col>
                        </>
                        : '' }
                        
                        { searchQueryData?.property_type?.includes('hotel') ?
                        <>
                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsTitle">სასტუმროს დეტალები</h4>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">ვარსკვლავების რაოდენობა</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="numberofstars"
                                            value={Options.hotelStars.filter(opt => 
                                                (searchQueryData?.numberofstars || '').includes(opt.value))}
                                            name="numberofstars"
                                            onChange={handleChangeSelect}
                                            options={Options.hotelStars}
                                            placeholder="აირჩიეთ რაოდენობა"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">კვება</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="food"
                                            value={Options.eatings.filter(opt => 
                                                (searchQueryData?.food || '').includes(opt.value))}
                                            name="food"
                                            onChange={handleChangeSelect}
                                            options={Options.eatings}
                                            placeholder="აირჩიეთ კვება"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>
                        <Col xxl={4}>&nbsp;</Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>ნომრების რაოდენობა</label>
                                <input type='number' value={searchQueryData?.roomamount_from} onChange={(e)=>handleChangeInput(e)} name='roomamount_from' placeholder='დან' />
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>&nbsp;</label>
                                <input type='number' value={searchQueryData?.roomamount_to} onChange={(e)=>handleChangeInput(e)} name='roomamount_to' placeholder='მდე' />
                            </div>  
                        </Col>
                        <Col xxl={4} className="mb-3">&nbsp;</Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>ლუქსების რაოდენობა</label>
                                <input type='number' value={searchQueryData?.luxamount_from} onChange={(e)=>handleChangeInput(e)} name='luxamount_from' placeholder='დან' />
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>&nbsp;</label>
                                <input type='number' value={searchQueryData?.luxamount_to} onChange={(e)=>handleChangeInput(e)} name='luxamount_to' placeholder='მდე' />
                            </div>  
                        </Col>
                        <Col xxl={4} className="mb-3">&nbsp;</Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.familyhotel == 'yes' } onChange={(e)=>handleChangeInput(e)}  name="familyhotel" />
                                    <span className="customFilterCheckbox"></span><strong>საოჯახო სასტუმრო</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.hostel == 'yes' } onChange={(e)=>handleChangeInput(e)}  name="hostel" />
                                    <span className="customFilterCheckbox"></span><strong>ჰოსტელი</strong>
                                </label>
                            </div>
                        </Col>
                        </>
                        : '' }

                        { searchQueryData?.property_type?.includes('businesscenter') ?
                        <>
                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsTitle">ბიზნესცენტრის დეტალები</h4>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">ბიზნესცენტრის კლასი</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="businesscenterclass"
                                            value={Options.businesscenterClasses.filter(opt => 
                                                (searchQueryData?.businesscenterclass || '').includes(opt.value))}
                                            name="businesscenterclass"
                                            onChange={handleChangeSelect}
                                            options={Options.businesscenterClasses}
                                            placeholder="აირჩიეთ კლასი"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>
                        </>
                        : '' }

                        { searchQueryData?.property_type?.includes('business') ?
                        <>
                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsTitle">ბიზნეს ინვესტიციის დეტალები</h4>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">ბიზნეს ინვესტიციის კატეგორია</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="businessinvestmentcategory"
                                            value={Options.businesscenterClasses.filter(opt => 
                                                (searchQueryData?.businessinvestmentcategory || '').includes(opt.value))}
                                            name="businessinvestmentcategory"
                                            onChange={handleChangeSelect}
                                            options={Options.businesscenterClasses}
                                            placeholder="აირჩიეთ კატეგორია"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">მესაკუთრე</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="owner"
                                            value={Options.owners.filter(opt => 
                                                (searchQueryData?.owner || '').includes(opt.value))}
                                            name="owner"
                                            onChange={handleChangeSelect}
                                            options={Options.owners}
                                            placeholder="აირჩიეთ მესაკუთრე"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">საკუთრების ტიპი</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="propertytype"
                                            value={Options.ownerTypes.filter(opt => 
                                                (searchQueryData?.propertytype || '').includes(opt.value))}
                                            name="propertytype"
                                            onChange={handleChangeSelect}
                                            options={Options.ownerTypes}
                                            placeholder="აირჩიეთ საკუთრების ტიპი"
                                            className="filterSelect"
                                            isMulti
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">აქტივები</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="assets"
                                            value={Options.actives.filter(opt => 
                                                (searchQueryData?.assets || '').includes(opt.value))}
                                            name="assets"
                                            onChange={handleChangeSelect}
                                            options={Options.actives}
                                            placeholder="აირჩიეთ აქტივები"
                                            className="filterSelect"
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>
                        <Col xxl={8}>&nbsp;</Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.heavyequipment == 'yes' } onChange={(e)=>handleChangeInput(e)}  name="heavyequipment" />
                                    <span className="customFilterCheckbox"></span><strong>მძიმე ტექნიკა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.specechnique == 'yes' } onChange={(e)=>handleChangeInput(e)}  name="specechnique" />
                                    <span className="customFilterCheckbox"></span><strong>სპეც ტექნიკა</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-1">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.building1 == 'yes' } onChange={(e)=>handleChangeInput(e)} name="building1" />
                                    <span className="customFilterCheckbox"></span><strong>შენობა ნაგებობა</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>შენობა ნაგებობის რაოდენობა</label>
                                <input type='number' value={searchQueryData?.buildingamount1_from} onChange={(e)=>handleChangeInput(e)} name='buildingamount1_from' placeholder='დან' />
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>&nbsp;</label>
                                <input type='number' value={searchQueryData?.buildingamount1_to} onChange={(e)=>handleChangeInput(e)} name='buildingamount1_to' placeholder='მდე' />
                            </div>  
                        </Col>
                        <Col xxl={4}>&nbsp;</Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>შენობა ნაგებობის ფართი (მ<sup>2</sup>)</label>
                                <input type='number' value={searchQueryData?.buildingarea1_from} onChange={(e)=>handleChangeInput(e)} name='buildingarea1_from' placeholder='დან' />
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>&nbsp;</label>
                                <input type='number' value={searchQueryData?.buildingarea1_to} onChange={(e)=>handleChangeInput(e)} name='buildingarea1_to' placeholder='მდე' />
                            </div>  
                        </Col>
                        <Col xxl={4}>&nbsp;</Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>მიწის ფართი (მ<sup>2</sup>)</label>
                                <input type='number' value={searchQueryData?.landarea_from} onChange={(e)=>handleChangeInput(e)} name='landarea_from' placeholder='დან' />
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>&nbsp;</label>
                                <input type='number' value={searchQueryData?.landarea_to} onChange={(e)=>handleChangeInput(e)} name='landarea_to' placeholder='მდე' />
                            </div>  
                        </Col>
                        <Col xxl={4}>&nbsp;</Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput iconInput">
                                <label>წლიური დარიცხვა</label>
                                <input type='number' value={searchQueryData?.annualaccrual_from} onChange={(e)=>handleChangeInput(e)} name='annualaccrual_from' placeholder='დან' />
                                <span>{currency}</span>
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput iconInput">
                                <label>&nbsp;</label>
                                <input type='number' value={searchQueryData?.annualaccrual_to} onChange={(e)=>handleChangeInput(e)} name='annualaccrual_to' placeholder='მდე' />
                                <span>{currency}</span>
                            </div>   
                        </Col>
                        <Col xxl={4}>&nbsp;</Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput iconInput">
                                <label>წლიური წმინდა მოგება</label>
                                <input type='number' value={searchQueryData?.annualnetprofit_from} onChange={(e)=>handleChangeInput(e)} name='annualnetprofit_from' placeholder='დან' />
                                <span>{currency}</span>
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput iconInput">
                                <label>&nbsp;</label>
                                <input type='number' value={searchQueryData?.annualnetprofit_to} onChange={(e)=>handleChangeInput(e)} name='annualnetprofit_to' placeholder='მდე' />
                                <span>{currency}</span>
                            </div>   
                        </Col>
                        <Col xxl={4}>&nbsp;</Col>
                        </>
                        : '' }

                        { searchQueryData?.property_type?.includes('farm') ?
                        <>
                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsTitle">ფერმის დეტალები</h4>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">ფერმის ტიპი</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="farmtype"
                                            value={Options.farmTypes.filter(opt => 
                                                (searchQueryData?.farmtype || '').includes(opt.value))}
                                            name="farmtype"
                                            onChange={handleChangeSelect}
                                            options={Options.farmTypes}
                                            placeholder="აირჩიეთ ტიპი"
                                            className="filterSelect"
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">მიწის ტიპი</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="landtype1"
                                            value={Options.farmLandTypes.filter(opt => 
                                                (searchQueryData?.landtype1 || '').includes(opt.value))}
                                            name="landtype1"
                                            onChange={handleChangeSelect}
                                            options={Options.farmLandTypes}
                                            placeholder="აირჩიეთ ტიპი"
                                            className="filterSelect"
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <label className="filterLbl">სას. სამეურნეო მიწის ტიპი</label>
                            <Dropdown className="filterDetailedDropdown">
                                <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                    <div className="filterdetailsFormGroup">
                                        <Select
                                            id="agrilandtype"
                                            value={Options.farmAgriculturalTypes.filter(opt => 
                                                (searchQueryData?.agrilandtype || '').includes(opt.value))}
                                            name="agrilandtype"
                                            onChange={handleChangeSelect}
                                            options={Options.farmAgriculturalTypes}
                                            placeholder="აირჩიეთ ტიპი"
                                            className="filterSelect"
                                        />
                                    </div>
                                </Dropdown.Toggle>
                            </Dropdown>
                        </Col>

                        <Col xxl={12} className="mb-1">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.buildingstructureoffice == 'yes' } onChange={(e)=>handleChangeInput(e)} name="buildingstructureoffice" />
                                    <span className="customFilterCheckbox"></span><strong>შენობა ნაგებობა (ოფისი)</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>რაოდენობა</label>
                                <input type='number' value={searchQueryData?.buildingstructureofficeamount_from} onChange={(e)=>handleChangeInput(e)} name='buildingstructureofficeamount_from' placeholder='დან' />
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>&nbsp;</label>
                                <input type='number' value={searchQueryData?.buildingstructureofficeamount_to} onChange={(e)=>handleChangeInput(e)} name='buildingstructureofficeamount_to' placeholder='მდე' />
                            </div>  
                        </Col>
                        <Col xxl={4} className="mb-3">&nbsp;</Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>სრული ფართი (მ<sup>2</sup>)</label>
                                <input type='number' value={searchQueryData?.buildingstructureofficearea_from} onChange={(e)=>handleChangeInput(e)} name='buildingstructureofficearea_from' placeholder='დან' />
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>&nbsp;</label>
                                <input type='number' value={searchQueryData?.buildingstructureofficearea_to} onChange={(e)=>handleChangeInput(e)} name='buildingstructureofficearea_to' placeholder='მდე' />
                            </div>  
                        </Col>
                        <Col xxl={4} className="mb-3">&nbsp;</Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">კომუნიკაციები</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications1?.includes('road') ? true : false } value="road" onChange={(e)=>handleChangeInput(e)} name="communications1[]" />
                                    <span className="customFilterCheckbox"></span><strong>გზა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications1?.includes('electricity') ? true : false } value="electricity" onChange={(e)=>handleChangeInput(e)} name="communications1[]" />
                                    <span className="customFilterCheckbox"></span><strong>დენი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications1?.includes('water') ? true : false } value="water" onChange={(e)=>handleChangeInput(e)} name="communications1[]" />
                                    <span className="customFilterCheckbox"></span><strong>წყალი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications1?.includes('gas') ? true : false } value="gas" onChange={(e)=>handleChangeInput(e)} name="communications1[]" />
                                    <span className="customFilterCheckbox"></span><strong>გაზი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications1?.includes('sewercollector') ? true : false } value="sewercollector" onChange={(e)=>handleChangeInput(e)} name="communications1[]" />
                                    <span className="customFilterCheckbox"></span><strong>კოლექტორი-კანალიზაცია</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications1?.includes('well') ? true : false } value="well" onChange={(e)=>handleChangeInput(e)} name="communications1[]" />
                                    <span className="customFilterCheckbox"></span><strong>ჭა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications1?.includes('easement') ? true : false } value="easement" onChange={(e)=>handleChangeInput(e)} name="communications1[]" />
                                    <span className="customFilterCheckbox"></span><strong>სერვიტუტი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications1?.includes('firstrow') ? true : false } value="firstrow" onChange={(e)=>handleChangeInput(e)} name="communications1[]" />
                                    <span className="customFilterCheckbox"></span><strong>პირველი ზოლი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications1?.includes('secondrow') ? true : false } value="secondrow" onChange={(e)=>handleChangeInput(e)} name="communications1[]" />
                                    <span className="customFilterCheckbox"></span><strong>მეორე ზოლი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications1?.includes('thirdrow') ? true : false } value="thirdrow" onChange={(e)=>handleChangeInput(e)} name="communications1[]" />
                                    <span className="customFilterCheckbox"></span><strong>მესამე ზოლი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications1?.includes('fence') ? true : false } value="fence" onChange={(e)=>handleChangeInput(e)} name="communications1[]" />
                                    <span className="customFilterCheckbox"></span><strong>ღობე</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications1?.includes('owntransformer') ? true : false } value="owntransformer" onChange={(e)=>handleChangeInput(e)} name="communications1[]" />
                                    <span className="customFilterCheckbox"></span><strong>საკუთარი ტრანსფორმატორი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.communications1?.includes('owngenerator') ? true : false } value="owngenerator" onChange={(e)=>handleChangeInput(e)} name="communications1[]" />
                                    <span className="customFilterCheckbox"></span><strong>საკუთარი გენერატორი</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsSubTitle">დამატებითი დეტალები</h4>
                        </Col>

                        <Col xxl={12} className="mb-3">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaldetails?.includes('pumpsystem') ? true : false } value="pumpsystem" onChange={(e)=>handleChangeInput(e)} name="additionaldetails[]" />
                                    <span className="customFilterCheckbox"></span><strong>სატუმბი სისტემა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaldetails?.includes('borehole') ? true : false } value="borehole" onChange={(e)=>handleChangeInput(e)} name="additionaldetails[]" />
                                    <span className="customFilterCheckbox"></span><strong>ჭაბურღილი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaldetails?.includes('dripirrigationsystem') ? true : false } value="dripirrigationsystem" onChange={(e)=>handleChangeInput(e)} name="additionaldetails[]" />
                                    <span className="customFilterCheckbox"></span><strong>წვეთოვანი სარწყავი სისტემა</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaldetails?.includes('railwayspur') ? true : false } value="railwayspur" onChange={(e)=>handleChangeInput(e)} name="additionaldetails[]" />
                                    <span className="customFilterCheckbox"></span><strong>რკინიგზის ჩიხი</strong>
                                </label>
                                <label className="customfltCheckbox">
                                    <input type="checkbox" checked={searchQueryData?.additionaldetails?.includes('capitalfence') ? true : false } value="capitalfence" onChange={(e)=>handleChangeInput(e)} name="additionaldetails[]" />
                                    <span className="customFilterCheckbox"></span><strong>კაპიტალური ღობე</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={12} className="mb-1">
                            <div className="customFltrLabelCheck">
                                <label className="customfltCheckbox">
                                    <input type="checkbox" value="yes" checked={ searchQueryData?.lake == 'yes' } onChange={(e)=>handleChangeInput(e)} name="lake" />
                                    <span className="customFilterCheckbox"></span><strong>ტბა</strong>
                                </label>
                            </div>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>რაოდენობა</label>
                                <input type='number' value={searchQueryData?.lakeamount_from} onChange={(e)=>handleChangeInput(e)} name='lakeamount_from' placeholder='დან' />
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>&nbsp;</label>
                                <input type='number' value={searchQueryData?.lakeamount_to} onChange={(e)=>handleChangeInput(e)} name='lakeamount_to' placeholder='მდე' />
                            </div>  
                        </Col>
                        <Col xxl={4} className="mb-3">&nbsp;</Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>სრული ფართი (მ<sup>2</sup>)</label>
                                <input type='number' value={searchQueryData?.lakearea_from} onChange={(e)=>handleChangeInput(e)} name='lakearea_from' placeholder='დან' />
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput">
                                <label htmlFor='area'>&nbsp;</label>
                                <input type='number' value={searchQueryData?.lakearea_to} onChange={(e)=>handleChangeInput(e)} name='lakearea_to' placeholder='მდე' />
                            </div>  
                        </Col>
                        <Col xxl={4} className="mb-3">&nbsp;</Col>
                        </>
                        : '' }

                        { searchQueryData?.transaction_type?.includes('installment') ?
                        
                        <>
                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsTitle">განვადებით გაყიდვის დეტალები</h4>
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput iconInput">
                                <label>პირველადი შესატანი თანხა</label>
                                <input type='number' value={searchQueryData?.Initialdepositamount_from} onChange={(e)=>handleChangeInput(e)} name='Initialdepositamount_from' placeholder='დან' />
                                <span>{currency}</span>
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput iconInput">
                                <label>&nbsp;</label>
                                <input type='number' value={searchQueryData?.Initialdepositamount_to} onChange={(e)=>handleChangeInput(e)} name='Initialdepositamount_to' placeholder='მდე' />
                                <span>{currency}</span>
                            </div>   
                        </Col>
                        <Col xxl={4} className="mb-3">&nbsp;</Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput iconInput">
                                <label>ყოველთვიური შესატანი თანხა</label>
                                <input type='number' value={searchQueryData?.monthlydepositamount_from} onChange={(e)=>handleChangeInput(e)} name='monthlydepositamount_from' placeholder='დან' />
                                <span>{currency}</span>
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput iconInput">
                                <label>&nbsp;</label>
                                <input type='number' value={searchQueryData?.monthlydepositamount_to} onChange={(e)=>handleChangeInput(e)} name='monthlydepositamount_to' placeholder='მდე' />
                                <span>{currency}</span>
                            </div>   
                        </Col>
                        <Col xxl={4} className="mb-3">&nbsp;</Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput iconInput">
                                <label>საბოლოო თანხა ჯამში</label>
                                <input type='number' value={searchQueryData?.finalamounttotal_from} onChange={(e)=>handleChangeInput(e)} name='finalamounttotal_from' placeholder='დან' />
                                <span>{currency}</span>
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput iconInput">
                                <label>&nbsp;</label>
                                <input type='number' value={searchQueryData?.finalamounttotal_to} onChange={(e)=>handleChangeInput(e)} name='finalamounttotal_to' placeholder='მდე' />
                                <span>{currency}</span>
                            </div>   
                        </Col>
                        <Col xxl={4} className="mb-3">&nbsp;</Col>
                        </>
                        : '' }
                        
                        { searchQueryData?.transaction_type?.includes('auction') ?
                        <>
                        <Col xxl={12} className="mb-3">
                            <h4 className="filterInsTitle">აუქციონით გაყიდვის დეტალები</h4>
                        </Col>

                        <Col xxl={4}>
                            <div className="form-group customFormGroupInput mb-3">
                                <label htmlFor='auctiondeadline_from'>აუქციონის ბოლო ვადა (დან)</label>
                                <div className="dateBox">
                                    <DatePicker
                                        id="auctiondeadline_from"
                                        onChange={(date)=>handleChangeDate('auctiondeadline_from', date)}
                                        selected={searchQueryData?.auctiondeadline_from}
                                        dateFormat="dd-MM-yyyy"
                                    />
                                </div>
                            </div>
                        </Col>

                        <Col xxl={4}>
                            <div className="form-group customFormGroupInput mb-3">
                                <label htmlFor='auctiondeadline_to'>აუქციონის ბოლო ვადა (მდე)</label>
                                <div className="dateBox">
                                    <DatePicker
                                        id="auctiondeadline_to"
                                        onChange={(date)=>handleChangeDate('auctiondeadline_to', date)}
                                        selected={searchQueryData?.auctiondeadline_to}
                                        dateFormat="dd-MM-yyyy"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col xxl={4} className="mb-3">&nbsp;</Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput iconInput">
                                <label>ყოველთვიური შესატანი თანხა</label>
                                <input type='number' value={searchQueryData?.monthlydepositamount1_from} onChange={(e)=>handleChangeInput(e)} name='monthlydepositamount1_from' placeholder='დან' />
                                <span>{currency}</span>
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput iconInput">
                                <label>&nbsp;</label>
                                <input type='number' value={searchQueryData?.monthlydepositamount1_to} onChange={(e)=>handleChangeInput(e)} name='monthlydepositamount1_to' placeholder='მდე' />
                                <span>{currency}</span>
                            </div>   
                        </Col>
                        <Col xxl={4} className="mb-3">&nbsp;</Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput iconInput">
                                <label>საბოლოო თანხა ჯამში</label>
                                <input type='number' value={searchQueryData?.finalamounttotal1_from} onChange={(e)=>handleChangeInput(e)} name='finalamounttotal1_from' placeholder='დან' />
                                <span>{currency}</span>
                            </div>   
                        </Col>

                        <Col xxl={4} className="mb-3">
                            <div className="form-group filterFormGroupInput iconInput">
                                <label>&nbsp;</label>
                                <input type='number' value={searchQueryData?.finalamounttotal1_to} onChange={(e)=>handleChangeInput(e)} name='finalamounttotal1_to' placeholder='მდე' />
                                <span>{currency}</span>
                            </div>   
                        </Col>
                        </>
                        : '' }

                    </Row>
                </div>

                <div className="addonFilterBottom">
                    <button onClick={() => setFilterModal(false)} type='button' className='erasePropertyButton'>გასუფთავება</button>
                    <button type='button' className='searchPropertyButton' onClick={()=>filter()}><FontAwesomeIcon icon={faMagnifyingGlass} /> ძებნა</button>
                </div>

            </div>
        </>
    );
}
