import {useState, useEffect} from 'react';

//import tags
import { Container, Row, Col, Image } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton'

import 'swiper/css';
import 'swiper/css/navigation';

// import api

import { get_video_news } from '../../api/Api'

function Video({locale}) {


    const baseUrl = 'https://reluxapi.myweb.com.ge';
    const [videoNews, setVideoNews] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
          try {
            get_video_news(setVideoNews);
    
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        
        if(!videoNews) {
            fetchData();
        }
    
      }, [videoNews]);

      //get youtube video id

      function getYoutubeVideoId(url) {
        const pattern = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(pattern);
        return match && match[1];
      }

    return (
        <Container>
            <Row>
                <Col xxl={12}>
                    <section className="videoSection">

                        <div className="sectionHeader">
                            <div className="sectionHeaderLeft">
                                <h2>ვიდეო სიახლეები</h2>
                                <p>გაეცანით ჩვენს ვიდეო სიახლეებს.</p>
                            </div>
                        </div>
                            
                        <Row>

                            { 
                                typeof videoNews === 'object' ? 
                                    videoNews.map(function(item) {
                                        return(
                                            <Col xxl={4} key={item.id}>
                                                <div className="singleVideosBox">
                                                    <iframe width="100%" height="220px" src={`https://www.youtube.com/embed/`+getYoutubeVideoId(item.url)} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                                                </div>
                                            </Col>
                                        );
                                    })
                                : 

                                <>
                                    <Col xxl={4}>
                                        <Skeleton count={4} style={{ width: '100%' }} />
                                    </Col>
                                    
                                    <Col xxl={4}>
                                        <Skeleton count={4} style={{ width: '100%' }} />
                                    </Col>
                                    <Col xxl={4}>
                                        <Skeleton count={4} style={{ width: '100%' }} />
                                    </Col>
                                </>
                                
                            }
                            

                            </Row>

                        </section>
                    </Col>
                </Row>
            </Container>
    )

}

export default Video;