import { useEffect } from 'react';
import { useAppContext } from './../Context';

// import tags
import {Link, useLocation, useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHeart, faRightFromBracket, faUserTie, faSliders} from '@fortawesome/free-solid-svg-icons';

//import translation
import { useTranslation } from "react-i18next";
import "../../i18n";

function ProfileMenu({locale}) {

    //get locale
    const { t } = useTranslation();

    const { isLoggedIn, logout } = useAppContext();

    const location = useLocation();
    const navigate = useNavigate();


    useEffect(()=>{
        if(isLoggedIn == false) {
            navigate('/');
        }
    }, [logout])

    return (
        <div className="profileCard">
            <div className="profileMenuTop">
                <div className="profileMenuTopUser">
                    <span>{ isLoggedIn && typeof isLoggedIn == 'object' ? isLoggedIn?.fname[0] + '.' + isLoggedIn?.lname[0] : '' }</span>
                </div>
                <div className="profileMenuTopTitle">
                    <h2>{ isLoggedIn && typeof isLoggedIn == 'object' ? isLoggedIn?.fname + ' ' + isLoggedIn?.lname : '' }</h2>
                    <p>ID: { isLoggedIn && typeof isLoggedIn == 'object' ? isLoggedIn?.id : '' }</p>
                </div>
            </div>
            <div className="profileMenuBottom">
                <ul>
                    <li className={location.pathname == '/profile/main' ? 'active' : ''}>
                        <FontAwesomeIcon icon={faUserTie}/>
                        <Link to={`/${locale}/profile/main`}>პროფილი</Link>
                    </li>
                    <li className={location.pathname == '/profile/ads' ? 'active' : ''}>
                        <FontAwesomeIcon icon={faSliders}/>
                        <Link to={`/${locale}/profile/ads`}>ჩემი განცხადებები</Link>
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faRightFromBracket}/>
                        <Link onClick={()=>logout()}>გამოსვლა</Link>
                    </li>
                </ul>
            </div>

        </div>
    )

}

export default ProfileMenu;
