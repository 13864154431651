import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//import tags
import { Container, Row, Col, Image } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton'
import parse from 'html-react-parser';
import moment from 'moment';

//import resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

//import translation
import { useTranslation } from "react-i18next";
import "../../i18n";

//api
import { get_news_details } from '../../api/Api'

function Detail({locale}) {

    //get locale
    const { t } = useTranslation();

    const { id, slug } = useParams();

    const baseUrl = 'https://reluxapi.myweb.com.ge';
    const [news, setNews] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
          try {
            get_news_details(setNews, id);

            } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        
        if(!news) {
            fetchData();
        }
    
      }, [news]);

    return (
        <div className='main'>
            <section className="breadcumbSection" id="sectionBreadCumb">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12">
                            <div className="breadcumb">
                                <div className="breadcumbLeft">
                                    <h1>სიახლეები</h1>
                                </div>
                                <div className="breadcumbRight">
                                    <ul>
                                        <li>
                                            <a href={`/${locale}`}>მთავარი</a>
                                        </li>
                                        <li>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                                <path fill="currentColor" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path>
                                            </svg>
                                        </li>
                                        <li>სიახლეები</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className='mainNewsDetails'>
                <Container>
                    <Row>
                        <Col xxl={6}>
                            <h1 className="postTitle">
                                {typeof news == 'object' ? news.translations[0].title : <Skeleton />}
                            </h1>
                            <div className='postDate mt-4 mb-4'>
                                {
                                    typeof news == 'object' ?
                                        <>
                                        <FontAwesomeIcon className='me-2' icon={faCalendar} />
                                        {moment(news.updated_at).format('DD/MM/YYYY HH:mm')}
                                        </>
                                    :
                                        <Skeleton />
                                }
                            </div>
                            <div className='postDescription'>
                                {
                                    typeof news == 'object' ?
                                        <>
                                            { parse(news.translations[0].text) }
                                        </>
                                    :
                                        <Skeleton />
                                }
                            </div>
                        </Col>
                        <Col xxl={6}>
                            <div className='postImage'>
                                {
                                    typeof news == 'object' ?
                                        <Image className='w-100' src={baseUrl+'/storage/news/'+news.image} alt={news.translations[0].title} />
                                    :
                                        <Skeleton />
                                }
                                                                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
    
}

export default Detail;