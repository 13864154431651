import axios from "axios";

let base_url = "https://reluxapi.myweb.com.ge";
// let base_url = "http://relux.loc";

// Get Auth
export function auth(setData, data) {

    const url = base_url + "/login";

    axios
        .post(url, data)
        .then((res) => {
            setData(res.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

// Register
export function register(setData, data) {

    const url = base_url + "/register";

    axios
        .post(url, data)
        .then((res) => {
            setData(res.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

// Get About
export function get_about(setData) {

    const url = base_url + "/about";

    axios
        .get(url)
        .then((res) => {
            setData(res.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

// Get Info
export function get_info(setData, id) {

    const url = base_url + "/info/"+id;

    axios
        .get(url)
        .then((res) => {
            setData(res.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

// Get News
export function get_news(setData) {

    const url = base_url + "/news";

    axios
        .get(url)
        .then((res) => {
            setData(res.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

// Get News Details
export function get_news_details(setData, id) {

    const url = base_url + "/post/" + id;

    axios
        .get(url)
        .then((res) => {
            setData(res.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

// Get User
export function get_user(setData, header) {


    const url = base_url + "/user/get";

    axios
        .get(url, {
            headers: header
        })
        .then((res) => {
            setData(res.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

// Update User
export function update_user(setData, data, header) {


    const url = base_url + "/user/update";

    axios
        .post(url, data, {
            headers: header
        })
        .then((res) => {
            setData(res.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

// Get Video News
export function get_video_news(setData) {


    const url = base_url + "/videonews";

    axios
        .get(url)
        .then((res) => {
            setData(res.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

// Get Contact
export function get_contact(setData) {


    const url = base_url + "/contact";

    axios
        .get(url)
        .then((res) => {
            setData(res.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}


//insert property

export function insert_property(setData, data, header) {


    const url = base_url + "/property/add";

    axios
        .post(url, data, {
            headers: header
        })
        .then((res) => {
            setData(res.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}



// Get Property Details
export function get_property_details(setData, header, slug) {

    const url = base_url + "/property/details/" + slug;

    axios
        .get(url, {
            headers: header
        })
        .then((res) => {
            setData(res.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

// Get Property
export function get_property(setData, filterArray) {

    const queryString = new URLSearchParams(filterArray).toString();
    
    const url = `${base_url}/property?${queryString}`;

    axios
        .get(url)
        .then((res) => {
            setData(res.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

// Get Favorites
export function get_favorites(setData, formData) {

    const url = base_url + "/favorites";
    
    axios
        .post(url, formData)
        .then((res) => {
            setData(res.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}


// Get user ads
export function get_user_properties(setData, header, filterArray) {

    const queryString = new URLSearchParams(filterArray).toString();

    const url = `${base_url}/property/user?${queryString}`;
    
    axios
        .get(url, {
            headers: header
        })
        .then((res) => {
            setData(res.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

// Get Relux ads
export function get_relux_properties(setData) {


    const url = `${base_url}/property/relux`;
    
    axios
        .get(url)
        .then((res) => {
            setData(res.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

// get cities
export function get_cities(setData, cities) {

    const url = `${base_url}/property/count_city/` + cities;
    
    axios
        .get(url)
        .then((res) => {
            setData(res.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function get_count_destrict(setData, destricts) {

    const url = `${base_url}/property/count_destrict/` + destricts;
    
    axios
        .get(url)
        .then((res) => {
            setData(res.data);
        })
        .catch(function (error) {
            console.log(error);
        });
}