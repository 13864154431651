import { useEffect, useState } from 'react';

import { cityOptions } from '../utils/PropertyOptions';

//import tags
import { Container, Row, Col, Image } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from "react-router-dom";
import { Navigation, A11y } from 'swiper/modules';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import 'swiper/css';
import 'swiper/css/navigation';

// import api
import { get_cities } from '../../api/Api';

function City({locale}) {

    const [data, setData] = useState(false);
    const [cityData, setCityData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                get_cities(setData, cityData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        // Collect the cities only once
        const cities = cityOptions
            .filter(item => item.popular === 1)
            .map(item => item.value);
    
        // Set city data only if it's empty
        if (cities.length > 0 && cityData.length === 0) {
            setCityData(cities);
        }
    
        // Only call fetchData if 'data' is empty and 'cityData' is populated
        if (!data && cityData.length > 0) {
            fetchData();
        }
    }, [data, cityData, cityOptions]);
    

    return (
        <Container>
            <Row>
                <Col xxl={12}>
                    <section className="citySection">
                        
                        <div className="sectionHeader">
                            <div className="sectionHeaderLeft">
                                <h2>პოპულარული ქალაქები</h2>
                                <p>მოიძიეთ სასურველი განცხადება ქალაქების მიხედვით.</p>
                            </div>
                            <div className="sectionHeaderRight">
                                <Link to={`/${locale}/cities`}>ყველას ნახვა <FontAwesomeIcon icon={faChevronRight} /></Link>
                            </div>
                        </div>
                        
                        <div className="citySlider">
                            <Swiper
                                // install Swiper modules
                                modules={[Navigation, A11y]}
                                spaceBetween={20}
                                slidesPerView={5}
                                navigation
                            >
                                {
                                    cityOptions.filter(filterItem => filterItem.popular == 1 ).slice(0, 8).map((item) => {
                                        if(item.popular == 0) {
                                            return;
                                        }
                                        return (
                                            <SwiperSlide key={item.value}>
                                                <Link to={`/${locale}/property?city=${item.value}`}>
                                                    <div className="singleCityBox">
                                                        <div className="singleCityBoxImage">
                                                            <Image src={item.image} alt={item.label} />
                                                        </div>
                                                        <div className="cityOverlay">
                                                            <div className="overCity">
                                                                <h3>{item.label}</h3>
                                                                <p>{data?.[item.value] ?? 0} განცხადება</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </SwiperSlide>
                                        )
                                    }) 
                                }

                                
                            </Swiper>
                        </div>

                    </section>
                </Col>
            </Row>
        </Container>
    )

}

export default City;