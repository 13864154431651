//import tags
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

//import components
import Video from '../components/dynamic/Video'

function Videos({locale}) {

    return (

        <div className='main'>

            <section className="breadcumbSection" id="sectionBreadCumb">
                <Container>
                    <Row>
                        <Col xxl={12}>
                            <div className="breadcumb">
                                <div className="breadcumbLeft">
                                    <h1>ვიდეო</h1>
                                </div>
                                <div className="breadcumbRight">
                                    <ul>
                                        <li><Link to={`/${locale}`}>მთავარი</Link></li>
                                        <li><FontAwesomeIcon icon={faChevronRight} /></li>
                                        <li>ვიდეო</li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>

            <Video/>
            
        </div>
    )

}

export default Videos;