import React, { useState, useEffect } from 'react';
import { useAppContext } from './../Context';

//import tags
import { Container, Image, Row, Col } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faBars, faPhone, faEnvelope, faHeart, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook, faTiktok, faYoutube } from '@fortawesome/free-brands-svg-icons';

// import components
import Auth from '../dynamic/Auth'
import Sidemenu from '../dynamic/Sidemenu';

//import translation
import { useTranslation } from "react-i18next";
import "../../i18n";

//import resources
import logo from '../../assets/image/logo.png'

function Header({locale}) {

    const { isLoggedIn, logout, favoritesCount, globalContact } = useAppContext();

    //get locale
    const { t } = useTranslation();

    const [isDropdownVisible, setDropdownVisible] = useState(false);
    const [authModal, setAuthModal] = useState(false);
    const [sideMenuModal, setSideMenuModal] = useState(false);

    const handleMouseEnter = () => {
        setDropdownVisible(true);
    };

    const handleMouseLeave = () => {
        setDropdownVisible(false);
    };


    const getFullUrlAfterLocale = () => {
        const path = window.location.pathname;
        const locale = path.split('/')[1];
        const fullUrlAfterLocale = path.substring(path.indexOf(`/${locale}`) + locale.length + 1);
        return fullUrlAfterLocale;
    };


    return (
        <>
            <header>
                <div className='construction'>ვებ გვერდი მზადების პროცესშია!</div>
                <div className='headerTop'>
                    <Container>
                        <Row>
                            <Col xxl={6}>
                                <div className="HeaderTopLeft">
                                    <Link to="tel:+995 123 456 789"><span><FontAwesomeIcon icon={faPhone} /></span> { typeof globalContact == 'object' && globalContact != null ? globalContact.phone : '' }</Link>
                                    <Link to="mailto:info@relux.ge"><span><FontAwesomeIcon icon={faEnvelope} /></span> { typeof globalContact == 'object' && globalContact != null ? globalContact.email : '' }</Link>
                                </div>
                            </Col>
                            <Col xxl={6}>
                                <div className="HeaderTopRight">
                                    <Link className="saved_box" to={`/${locale}/favorites`}>
                                        <FontAwesomeIcon icon={faHeart} title={t('saved_ads')} />
                                        <span>{favoritesCount}</span>
                                    </Link>
                                    <Link className="socialTop" to={globalContact?.facebook} target='_blank'>
                                        <FontAwesomeIcon icon={faFacebook} />
                                    </Link>
                                    <Link className="socialTop" to={globalContact?.instagram} target='_blank'>
                                        <FontAwesomeIcon icon={faInstagram} />
                                    </Link>
                                    <Link className="socialTop" to={globalContact?.tiktok} target='_blank'>
                                        <FontAwesomeIcon icon={faTiktok} />
                                    </Link>
                                    <Link className="socialTop" to={globalContact?.youtube} target='_blank'>
                                        <FontAwesomeIcon icon={faYoutube} />
                                    </Link>
                                    <Link>&nbsp;</Link>
                                    <Link to={`/ka`+getFullUrlAfterLocale()}>Ka</Link>
                                    <Link to={`/en`+getFullUrlAfterLocale()}>En</Link>
                                    <Link to={`/ru`+getFullUrlAfterLocale()}>Ru</Link>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className='header'>
                    <Container>
                        <Row>
                            <Col xxl={2}>
                                <div className='logo'>
                                    <Link to={`/${locale}`}>
                                        <Image src={logo} alt="Relux" />
                                    </Link>
                                </div>
                            </Col>
                            <Col xxl={4}>
                                <nav className="shortNav">
                                    <ul>
                                        <li onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            <Link to={`/${locale}/property`}>{t('for_Sale')} <span><FontAwesomeIcon icon={faChevronDown} /></span></Link>
                                            {isDropdownVisible && (
                                                <ul className="dropdown">
                                                    <div className="dropdownBox">
                                                        <li><Link to={`/${locale}/property?page=1&transaction_type=sale&property_type=apartment`}>ბინა</Link></li>
                                                        <li><Link to={`/${locale}/property?page=1&transaction_type=sale&property_type=house`}>სახლი</Link></li>
                                                    </div>

                                                </ul>
                                            )}
                                        </li>
                                        <li onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            <Link to={`/${locale}/property`}>{t('for_rent')} <span><FontAwesomeIcon icon={faChevronDown} /></span></Link>
                                            {isDropdownVisible && (
                                                <ul className="dropdown">
                                                    <div className="dropdownBox">
                                                        <li><Link to={`/${locale}/property?page=1&transaction_type=rent&property_type=apartment`}>ბინა</Link></li>
                                                        <li><Link to={`/${locale}/property?page=1&transaction_type=rent&property_type=house`}>სახლი</Link></li>
                                                    </div>
                                                </ul>
                                            )}
                                        </li>
                                        <li onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                                            <Link to={`/${locale}/property`}>{t('for_pledge')} <span><FontAwesomeIcon icon={faChevronDown} /></span></Link>
                                            {isDropdownVisible && (
                                                <ul className="dropdown">
                                                    <div className="dropdownBox">
                                                        <li><Link to={`/${locale}/property?page=1&transaction_type=pawn&property_type=apartment`}>ბინა</Link></li>
                                                        <li><Link to={`/${locale}/property?page=1&transaction_type=pawn&property_type=house`}>სახლი</Link></li>
                                                    </div>
                                                </ul>
                                            )}
                                        </li>
                                    </ul>
                                </nav>
                            </Col>
                            <Col xxl={6}>
                                <nav className="rightNav">
                                    <Link to={`/${locale}/property/add`}><FontAwesomeIcon icon={faPlus} /> {t('add_property')}</Link>
                                    {isLoggedIn && typeof isLoggedIn == 'object' ? 
                                        <>
                                        <Link className="loginBtn" to={`/${locale}/profile/main`}>{t('hello')}: {isLoggedIn?.fname}</Link>
                                        <Link className="loginBtn" onClick={()=>logout()}>{t('log_out')}</Link>
                                        </>
                                        :
                                        <Link className="loginBtn" onClick={()=>setAuthModal(true)}>{t('login_register')}</Link>
                                    }
                                    
                                    <Link className="sideMenu" onClick={()=>setSideMenuModal(true)}><span><FontAwesomeIcon icon={faBars} /></span></Link>
                                </nav>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </header>
            
            <div className={`authModal ` + (authModal ? 'active' : '')}>
                <div className='authModalOuter'>
                    {
                        authModal ? 
                            <>
                                <Auth setAuthModal={setAuthModal} />
                            </>
                        : 
                        ''
                    }
                </div>
            </div>

            <div className={`sideMenuModal ` + (sideMenuModal ? 'active' : '')}>
                <div className='sideMenuModalOuter'>
                    {
                        sideMenuModal ? 
                            <>
                                <Sidemenu locale={locale} setSideMenuModal={setSideMenuModal} />
                            </>
                        : 
                        ''
                    }
                </div>
            </div>
        </>
        
    )

}

export default Header;