import destrict from '../../assets/image/destricts/destrict.jpg'
import cityImage from '../../assets/image/destricts/destrict.jpg'

export const propertyTypeOptions = [
    { value: 'apartment', label: 'ბინა, აპარტამენტი' },
    { value: 'penthouse', label: 'პენტჰაუსი' },
    { value: 'house', label: 'კერძო სახლი - აგარაკი' },
    { value: 'townhouse', label: 'თაუნ ჰაუსი' },
    { value: 'comercial', label: 'კომერციული ფართი' },
    { value: 'office', label: 'საოფისე ფართი' },
    { value: 'hotel', label: 'სასტუმრო' },
    { value: 'businesscenter', label: 'ბიზნეს ცენტრი' },
    { value: 'land', label: 'მიწა' },
    { value: 'business', label: 'ბიზნესი - ინვესტიცია' },
    { value: 'farm', label: 'ფერმა' },
];

export const transactionTypeOptions = [
    { value: 'sale', label: 'იყიდება' },
    { value: 'installment', label: 'იყიდება განვადებით' },
    { value: 'rent', label: 'ქირავდება' },
    { value: 'dailyrent', label: 'ქირავდება დღიურად' },
    { value: 'pawn', label: 'გირავდება' },
    { value: 'pawnwithowner', label: 'გირავდება ცხოვრების უფლებით' },
    { value: 'auction', label: 'იყიდება აუქციონით' },
    { value: 'change', label: 'იცვლება' },
];


export const propertyStatusOptions = [
    { value: 'new', label: 'ახალი აშენებული' },
    { value: 'underconstruction', label: 'მშენებარე' },
    { value: 'old', label: 'ძველი აშენებული' },
    { value: 'hystorical', label: 'ისტრიული შენობა' },
];

export const propertyProjectOptions = [
    { value: 'nonstandard', label: 'არასტანდარტული' },
    { value: 'iugoslavia', label: 'იუგოსლავია' },
    { value: 'tashkenti', label: 'ტაშკენტი' },
    { value: 'amkhanagoba', label: 'ამხანაგობა' },
    { value: 'saerto', label: 'საერთო საცხოვრებელი' },
    { value: 'leningrad', label: 'ლენინგრადი' },
    { value: 'kiev', label: 'კიევი' },
    { value: 'vedzisi', label: 'ვეძისი' },
    { value: 'metromsheni', label: 'მეტრომშენი' },
    { value: 'czechia', label: 'ჩეხური' },
    { value: 'urban', label: 'ქალაქური' },
    { value: 'khrushchev', label: 'ხრუშოვი' },
    { value: 'kavlashvili', label: 'ყავლაშვილი' },
    { value: 'tukharuli', label: 'თუხარული' },
    { value: 'moscow', label: 'მოსკოვი' },
    { value: 'lvov', label: 'ლვოვი' },
    { value: 'tbilisi', label: 'თბილისური ეზო' },
    { value: 'repair', label: 'სარემონტო მდგომარეობაში' },
];

export const propertyProjectClassOptions = [
    { value: 'premiumbusiness', label: 'პრემიუმ ბიზნეს კლასი' },
    { value: 'premium', label: 'პრემიუმ კლასი' },
    { value: 'medium', label: 'მედიუმ კლასი' },
    { value: 'econom', label: 'ეკონომ კლასი' },
];

export const propertyConditionOptions = [
    { value: 'newly', label: 'ახალი გარემონტებული' },
    { value: 'modern', label: 'თანამედროვე რემონტი (Lux)' },
    { value: 'ongoing', label: 'მიმდინარე რემონტი' },
    { value: 'white', label: 'თეთრი რემონტი' },
    { value: 'greenframe', label: 'მწვანე კარკასი' },
    { value: 'whiteframe', label: 'თეთრი კარკასი' },
    { value: 'greyframe', label: 'რუხი კარკასი' },
    { value: 'blackframe', label: 'შავი კარკასი' },
    { value: 'underconstruction', label: 'მშენებარე' },
];

export const statementTypeOptions = [
    { value: 'relux', label: 'RELUX' },
    { value: 'exclusive', label: 'EXCLUSIVE' },
];

export const cityOptions = [
    { value: 'tbilisi', label: 'თბილისი', image: cityImage, popular: 1 },
    { value: 'batumi', label: 'ბათუმი', image: cityImage, popular: 1 },
    { value: 'kutaisi', label: 'ქუთაისი', image: cityImage, popular: 1 },
    { value: 'rustavi', label: 'რუსთავი', image: cityImage, popular: 1 },
    { value: 'abastumani', label: 'აბასთუმანი', image: cityImage, popular: 0 },
    { value: 'abasha', label: 'აბაშა', image: cityImage, popular: 0 },
    { value: 'agara', label: 'აგარა', image: cityImage, popular: 0 },
    { value: 'adigeni', label: 'ადიგენი', image: cityImage, popular: 0 },
    { value: 'algeti', label: 'ალგეთი', image: cityImage, popular: 0 },
    { value: 'ambrolauri', label: 'ამბროლაური', image: cityImage, popular: 0 },
    { value: 'anaklia', label: 'ანაკლია', image: cityImage, popular: 0 },
    { value: 'ananuri', label: 'ანანური', image: cityImage, popular: 0 },
    { value: 'aspindza', label: 'ასპინძა', image: cityImage, popular: 0 },
    { value: 'akhaldaba', label: 'ახალდაბა', image: cityImage, popular: 0 },
    { value: 'akhalgori', label: 'ახალგორი', image: cityImage, popular: 0 },
    { value: 'akhalkalaki', label: 'ახალქალაქი', image: cityImage, popular: 0 },
    { value: 'akhaltsikhe', label: 'ახალციხე', image: cityImage, popular: 0 },
    { value: 'akhmeta', label: 'ახმეტა', image: cityImage, popular: 0 },
    { value: 'bakuriani', label: 'ბაკურიანი', image: cityImage, popular: 0 },
    { value: 'bandza', label: 'ბანძა', image: cityImage, popular: 0 },
    { value: 'baghdati', label: 'ბაღდათი', image: cityImage, popular: 0 },
    { value: 'bakhmaro', label: 'ბახმარო', image: cityImage, popular: 0 },
    { value: 'boboqvati', label: 'ბობოყვათი', image: cityImage, popular: 0 },
    { value: 'bodbe', label: 'ბოდბე', image: cityImage, popular: 0 },
    { value: 'bolnisi', label: 'ბოლნისი', image: cityImage, popular: 0 },
    { value: 'borjomi', label: 'ბორჯომი', image: cityImage, popular: 0 },
    { value: 'bulachauri', label: 'ბულაჩაური', image: cityImage, popular: 0 },
    { value: 'gamarjveba', label: 'გამარჯვება', image: cityImage, popular: 0 },
    { value: 'gardabani', label: 'გარდაბანი', image: cityImage, popular: 0 },
    { value: 'geguti', label: 'გეგუთი', image: cityImage, popular: 0 },
    { value: 'gombori', label: 'გომბორი', image: cityImage, popular: 0 },
    { value: 'gonio', label: 'გონიო', image: cityImage, popular: 0 },
    { value: 'gori', label: 'გორი', image: cityImage, popular: 0 },
    { value: 'gremi', label: 'გრემი', image: cityImage, popular: 0 },
    { value: 'grigoleti', label: 'გრიგოლეთი', image: cityImage, popular: 0 },
    { value: 'gudauri', label: 'გუდაური', image: cityImage, popular: 0 },
    { value: 'gulgula', label: 'გულგულა', image: cityImage, popular: 0 },
    { value: 'gurjaani', label: 'გურჯაანი', image: cityImage, popular: 0 },
    { value: 'danispiruli', label: 'დანისპირული', image: cityImage, popular: 0 },
    { value: 'dedoplistsqaro', label: 'დედოფლისწყარო', image: cityImage, popular: 0 },
    { value: 'dilikauri', label: 'დილიკაური', image: cityImage, popular: 0 },
    { value: 'dmanisi', label: 'დმანისი', image: cityImage, popular: 0 },
    { value: 'doesi', label: 'დოესი', image: cityImage, popular: 0 },
    { value: 'dusheti', label: 'დუშეთი', image: cityImage, popular: 0 },
    { value: 'eniseli', label: 'ენისელი', image: cityImage, popular: 0 },
    { value: 'vazisubani', label: 'ვაზისუბანი', image: cityImage, popular: 0 },
    { value: 'vani', label: 'ვანი', image: cityImage, popular: 0 },
    { value: 'vartsikhe', label: 'ვარციხე', image: cityImage, popular: 0 },
    { value: 'velistsikhe', label: 'ველისციხე', image: cityImage, popular: 0 },
    { value: 'zana', label: 'ზანა', image: cityImage, popular: 0 },
    { value: 'zedazeni', label: 'ზედაზენი', image: cityImage, popular: 0 },
    { value: 'zestaponi', label: 'ზესტაფონი', image: cityImage, popular: 0 },
    { value: 'zugdidi', label: 'ზუგდიდი', image: cityImage, popular: 1 },
    { value: 'tetritsqlebi', label: 'თეთრი წყლები', image: cityImage, popular: 0 },
    { value: 'tetritsqaro', label: 'თეთრიწყარო', image: cityImage, popular: 0 },
    { value: 'telavi', label: 'თელავი', image: cityImage, popular: 1 },
    { value: 'terjola', label: 'თერჯოლა', image: cityImage, popular: 0 },
    { value: 'tianeti', label: 'თიანეთი', image: cityImage, popular: 0 },
    { value: 'igoeti', label: 'იგოეთი', image: cityImage, popular: 0 },
    { value: 'iqalto', label: 'იყალთო', image: cityImage, popular: 0 },
    { value: 'kavtiskhevi', label: 'კავთისხევი', image: cityImage, popular: 0 },
    { value: 'kazreti', label: 'კაზრეთი', image: cityImage, popular: 0 },
    { value: 'kardenakhi', label: 'კარდენახი', image: cityImage, popular: 0 },
    { value: 'kaspi', label: 'კასპი', image: cityImage, popular: 0 },
    { value: 'kechkhobi', label: 'კეჩხობი', image: cityImage, popular: 0 },
    { value: 'kvariati', label: 'კვარიათი', image: cityImage, popular: 0 },
    { value: 'lagodekhi', label: 'ლაგოდეხი', image: cityImage, popular: 0 },
    { value: 'lanchkhuti', label: 'ლანჩხუთი', image: cityImage, popular: 0 },
    { value: 'likani', label: 'ლიკანი', image: cityImage, popular: 0 },
    { value: 'manavi', label: 'მანავი', image: cityImage, popular: 0 },
    { value: 'manglisi', label: 'მანგლისი', image: cityImage, popular: 0 },
    { value: 'marneuli', label: 'მარნეული', image: cityImage, popular: 0 },
    { value: 'martvili', label: 'მარტვილი', image: cityImage, popular: 0 },
    { value: 'martqopi', label: 'მარტყოფი', image: cityImage, popular: 0 },
    { value: 'matani', label: 'მატანი', image: cityImage, popular: 0 },
    { value: 'makhinjauri', label: 'მახინჯაური', image: cityImage, popular: 0 },
    { value: 'mestia', label: 'მესტია', image: cityImage, popular: 0 },
    { value: 'metekhi', label: 'მეტეხი', image: cityImage, popular: 0 },
    { value: 'misaktsieli', label: 'მისაქციელი', image: cityImage, popular: 0 },
    { value: 'mukuzani', label: 'მუკუზანი', image: cityImage, popular: 0 },
    { value: 'mukhrani', label: 'მუხრანი', image: cityImage, popular: 0 },
    { value: 'mtskheta', label: 'მცხეთა', image: cityImage, popular: 0 },
    { value: 'mtsvanekontskhi', label: 'მწვანე კონცხი', image: cityImage, popular: 0 },
    { value: 'natanebi', label: 'ნატანები', image: cityImage, popular: 0 },
    { value: 'natakhtari', label: 'ნატახტარი', image: cityImage, popular: 0 },
    { value: 'ninotsminda', label: 'ნინოწმინდა', image: cityImage, popular: 0 },
    { value: 'norio', label: 'ნორიო', image: cityImage, popular: 0 },
    { value: 'noste', label: 'ნოსტე', image: cityImage, popular: 0 },
    { value: 'nunisi', label: 'ნუნისი', image: cityImage, popular: 0 },
    { value: 'odishi', label: 'ოდიში', image: cityImage, popular: 0 },
    { value: 'ozurgeti', label: 'ოზურგეთი', image: cityImage, popular: 0 },
    { value: 'okami', label: 'ოკამი', image: cityImage, popular: 0 },
    { value: 'oni', label: 'ონი', image: cityImage, popular: 0 },
    { value: 'pankisi', label: 'პანკისი', image: cityImage, popular: 0 },
    { value: 'pirvelimaisi', label: 'პირველი მაისი', image: cityImage, popular: 0 },
    { value: 'zhinvali', label: 'ჟინვალი', image: cityImage, popular: 0 },
    { value: 'rukhi', label: 'რუხი', image: cityImage, popular: 0 },
    { value: 'sagarejo', label: 'საგარეჯო', image: cityImage, popular: 0 },
    { value: 'sadakhlo', label: 'სადახლო', image: cityImage, popular: 0 },
    { value: 'sazano', label: 'საზანო', image: cityImage, popular: 0 },
    { value: 'sairme', label: 'საირმე', image: cityImage, popular: 0 },
    { value: 'samtavisi', label: 'სამთავისი', image: cityImage, popular: 0 },
    { value: 'samtredia', label: 'სამტრედია', image: cityImage, popular: 0 },
    { value: 'sartichala', label: 'სართიჭალა', image: cityImage, popular: 0 },
    { value: 'sarpi', label: 'სარფი', image: cityImage, popular: 0 },
    { value: 'sachkhere', label: 'საჩხერე', image: cityImage, popular: 0 },
    { value: 'senaki', label: 'სენაკი', image: cityImage, popular: 0 },
    { value: 'sviri', label: 'სვირი', image: cityImage, popular: 0 },
    { value: 'simoneti', label: 'სიმონეთი', image: cityImage, popular: 0 },
    { value: 'sioni', label: 'სიონი', image: cityImage, popular: 0 },
    { value: 'sighnaghi', label: 'სიღნაღი', image: cityImage, popular: 0 },
    { value: 'surami', label: 'სურამი', image: cityImage, popular: 0 },
    { value: 'supsa', label: 'სუფსა', image: cityImage, popular: 0 },
    { value: 'tibaani', label: 'ტიბაანი', image: cityImage, popular: 0 },
    { value: 'tqibuli', label: 'ტყიბული', image: cityImage, popular: 0 },
    { value: 'ureki', label: 'ურეკი', image: cityImage, popular: 0 },
    { value: 'utsera', label: 'უწერა', image: cityImage, popular: 0 },
    { value: 'ujarma', label: 'უჯარმა', image: cityImage, popular: 0 },
    { value: 'pasanauri', label: 'ფასანაური', image: cityImage, popular: 0 },
    { value: 'poti', label: 'ფოთი', image: cityImage, popular: 0 },
    { value: 'pshaveli', label: 'ფშაველი', image: cityImage, popular: 0 },
    { value: 'kareli', label: 'ქარელი', image: cityImage, popular: 0 },
    { value: 'keda', label: 'ქედა', image: cityImage, popular: 0 },
    { value: 'kvishkheti', label: 'ქვიშხეთი', image: cityImage, popular: 0 },
    { value: 'kvemoaranisi', label: 'ქვემო არანისი', image: cityImage, popular: 0 },
    { value: 'kobuleti', label: 'ქობულეთი', image: cityImage, popular: 0 },
    { value: 'ksani', label: 'ქსანი', image: cityImage, popular: 0 },
    { value: 'kubriantkari', label: 'ქუბრიანთკარი', image: cityImage, popular: 0 },
    { value: 'kveseti', label: 'კვესეთი', image: cityImage, popular: 0 },
    { value: 'qazbegi', label: 'ყაზბეგი', image: cityImage, popular: 0 },
    { value: 'qvareli', label: 'ყვარელი', image: cityImage, popular: 0 },
    { value: 'qulevi', label: 'ყულევი', image: cityImage, popular: 0 },
    { value: 'shatili', label: 'შატილი', image: cityImage, popular: 0 },
    { value: 'shekvetili', label: 'შეკვეთილი', image: cityImage, popular: 0 },
    { value: 'shilda', label: 'შილდა', image: cityImage, popular: 0 },
    { value: 'shindisi', label: 'შინდისი', image: cityImage, popular: 0 },
    { value: 'shiomghvime', label: 'შიომღვიმე', image: cityImage, popular: 0 },
    { value: 'shovi', label: 'შოვი', image: cityImage, popular: 0 },
    { value: 'shorapani', label: 'შორაპანი', image: cityImage, popular: 0 },
    { value: 'shuamta', label: 'შუამთა', image: cityImage, popular: 0 },
    { value: 'shuakhevi', label: 'შუახევი', image: cityImage, popular: 0 },
    { value: 'chakvi', label: 'ჩაქვი', image: cityImage, popular: 0 },
    { value: 'chokhatauri', label: 'ჩოხატაური', image: cityImage, popular: 0 },
    { value: 'chkhorotsqu', label: 'ჩხოროწყუ', image: cityImage, popular: 0 },
    { value: 'tsageri', label: 'ცაგერი', image: cityImage, popular: 0 },
    { value: 'tsemi', label: 'ცემი', image: cityImage, popular: 0 },
    { value: 'tsikhisdziri', label: 'ციხისძირი', image: cityImage, popular: 0 },
    { value: 'tskhvarichamia', label: 'ცხვარიჭამია', image: cityImage, popular: 0 },
    { value: 'tskhinvali', label: 'ცხინვალი', image: cityImage, popular: 0 },
    { value: 'tsalenjikha', label: 'წალენჯიხა', image: cityImage, popular: 0 },
    { value: 'tsalka', label: 'წალკა', image: cityImage, popular: 0 },
    { value: 'tsaghveri', label: 'წაღვერი', image: cityImage, popular: 0 },
    { value: 'tserovani', label: 'წეროვანი', image: cityImage, popular: 0 },
    { value: 'tsilkani', label: 'წილკანი', image: cityImage, popular: 0 },
    { value: 'tsinandali', label: 'წინანდალი', image: cityImage, popular: 0 },
    { value: 'tsitsamuri', label: 'წიწამური', image: cityImage, popular: 0 },
    { value: 'tsnori', label: 'წნორი', image: cityImage, popular: 0 },
    { value: 'tsqaltubo', label: 'წყალტუბო', image: cityImage, popular: 0 },
    { value: 'chiatura', label: 'ჭიათურა', image: cityImage, popular: 0 },
    { value: 'choporti', label: 'ჭოპორტი', image: cityImage, popular: 0 },
    { value: 'kharagauli', label: 'ხარაგაული', image: cityImage, popular: 0 },
    { value: 'khashmi', label: 'ხაშმი', image: cityImage, popular: 0 },
    { value: 'khashuri', label: 'ხაშური', image: cityImage, popular: 0 },
    { value: 'khevsureti', label: 'ხევსურეთი', image: cityImage, popular: 0 },
    { value: 'khelvachauri', label: 'ხელვაჩაური', image: cityImage, popular: 0 },
    { value: 'khobi', label: 'ხობი', image: cityImage, popular: 0 },
    { value: 'khoni', label: 'ხონი', image: cityImage, popular: 0 },
    { value: 'khulo', label: 'ხულო', image: cityImage, popular: 0 },
    { value: 'kachreti', label: 'კაჭრეთი', image: cityImage, popular: 0 },
    { value: 'java', label: 'ჯავა', image: cityImage, popular: 0 },
];

export const destrictOptions = [
    { value: 'gldani', label: 'გლდანის რაიონი', image: destrict },
    { value: 'didube', label: 'დიდუბის რაიონი', image: destrict },
    { value: 'vake', label: 'ვაკის რაიონი', image: destrict },
    { value: 'isani', label: 'ისნის რაიონი', image: destrict },
    { value: 'krtsanisi', label: 'კრწანისის რაიონი', image: destrict },
    { value: 'mtatsminda', label: 'მთაწმინდის რაიონი', image: destrict },
    { value: 'nadzaladevi', label: 'ნაძალადევის რაიონი', image: destrict },
    { value: 'saburtalo', label: 'საბურთალოს რაიონი', image: destrict },
    { value: 'samgori', label: 'სამგორის რაიონი', image: destrict },
    { value: 'chughureti', label: 'ჩუღურეთის რაიონი', image: destrict },
];

export const districtOptions = [
    { value: 'apkhazeti', label: 'აფხაზეთი' },
    { value: 'achara', label: 'აჭარა' },
    { value: 'guria', label: 'გურია' },
    { value: 'imereti', label: 'იმერეთი' },
    { value: 'kakheti', label: 'კახეთი' },
    { value: 'mtskhetamtianeti', label: 'მცხეთა-მთიანეთი' },
    { value: 'rachalechkhumi', label: 'რაჭა-ლეჩხუმი და ქვემო სვანეთი' },
    { value: 'samegrelozemosvaneti', label: 'სამეგრელო-ზემო სვანეთი' },
    { value: 'samtskhejavakheti', label: 'სამცხე-ჯავახეთი' },
    { value: 'kvemokartli', label: 'ქვემო ქართლი' },
    { value: 'shidakartli', label: 'შიდა ქართლი' },
];

export const municipalOptions = [
    { value: 'abasha', label: 'აბაშის მუნიციპალიტეტი' },
    { value: 'adigeni', label: 'ადიგენის მუნიციპალიტეტი' },
    { value: 'khulo', label: 'ხულოს მუნიციპალიტეტი' },
    { value: 'ambrolauri', label: 'ამბროლაურის მუნიციპალიტეტი' },
    { value: 'aspindza', label: 'ასპინძის მუნიციპალიტეტი' },
    { value: 'apkhazeti', label: 'აფხაზეთის ავტონომიური რესპუბლიკა' },
    { value: 'khoni', label: 'ხონის მუნიციპალიტეტი' },
    { value: 'akhalkalaki', label: 'ახალქალაქის მუნიციპალიტეტი' },
    { value: 'khobi', label: 'ხობის მუნიციპალიტეტი' },
    { value: 'khelvachauri', label: 'ხელვაჩაურის მუნიციპალიტეტი' },
    { value: 'akhaltsikhe', label: 'ახალციხის მუნიციპალიტეტი' },
    { value: 'khashuri', label: 'ხაშურის მუნიციპალიტეტი' },
    { value: 'akhmeta', label: 'ახმეტის მუნიციპალიტეტი' },
    { value: 'baghdati', label: 'ბაღდათის მუნიციპალიტეტი' },
    { value: 'bolnisi', label: 'ბოლნისის მუნიციპალიტეტი' },
    { value: 'borjomi', label: 'ბორჯომის მუნიციპალიტეტი' },
    { value: 'kharagauli', label: 'ხარაგაულის მუნიციპალიტეტი' },
    { value: 'gardabni', label: 'გარდაბნის მუნიციპალიტეტი' },
    { value: 'gori', label: 'გორის მუნიციპალიტეტი' },
    { value: 'tsqaltubo', label: 'წყალტუბოს მუნიციპალიტეტი' },
    { value: 'tsalka', label: 'წალკის მუნიციპალიტეტი' },
    { value: 'gurjaani', label: 'გურჯაანის მუნიციპალიტეტი' },
    { value: 'tsalenjikha', label: 'წალენჯიხის მუნიციპალიტეტი' },
    { value: 'dedoplistsqaro', label: 'დედოფლისწყაროს მუნიციპალიტეტი' },
    { value: 'dmanisi', label: 'დმანისის რაიონი' },
    { value: 'tskhinvali', label: 'ცხინვალის რაიონი' },
    { value: 'tsageri', label: 'ცაგერის მუნიციპალიტეტი' },
    { value: 'chkhorotsqu', label: 'ჩხოროწყუს მუნიციპალიტეტი' },
    { value: 'vani', label: 'ვანის მუნიციპალიტეტი' },
    { value: 'zestaponi', label: 'ზესტაფონის მუნიციპალიტეტი' },
    { value: 'zugdidi', label: 'ზუგდიდის მუნიციპალიტეტი' },
    { value: 'chokhatauri', label: 'ჩოხატაურის მუნიციპალიტეტი' },
    { value: 'shuakhevi', label: 'შუახევის მუნიციპალიტეტი' },
    { value: 'tetritsqaro', label: 'თეთრიწყაროს მუნიციპალიტეტი' },
    { value: 'telavi', label: 'თელავის მუნიციპალიტეტი' },
    { value: 'qvareli', label: 'ყვარლის მუნიციპალიტეტი' },
    { value: 'terjola', label: 'თერჯოლის მუნიციპალიტეტი' },
    { value: 'tianeti', label: 'თიანეთის მუნიციპალიტეტი' },
    { value: 'qazbegi', label: 'ყაზბეგის მუნიციპალიტეტი' },
    { value: 'kutaisi', label: 'ქუთაისის მუნიციპალიტეტი' },
    { value: 'kobuleti', label: 'ქობულეთის მუნიციპალიტეტი' },
    { value: 'kaspi', label: 'კასპის მუნიციპალიტეტი' },
    { value: 'lagodekhi', label: 'ლაგოდეხის მუნიციპალიტეტი' },
    { value: 'keda', label: 'ქედის მუნიციპალიტეტი' },
    { value: 'lanchkhuti', label: 'ლანჩხუთის მუნიციპალიტეტი' },
    { value: 'lentekhi', label: 'ლენტეხის მუნიციპალიტეტი' },
    { value: 'kareli', label: 'ქარელის მუნიციპალიტეტი' },
    { value: 'marneuli', label: 'მარნეულის მუნიციპალიტეტი' },
    { value: 'tqibuli', label: 'ტყიბულის მუნიციპალიტეტი' },
    { value: 'martvili', label: 'მარტვილის მუნიციპალიტეტი' },
    { value: 'sighnaghi', label: 'სიღნაღის მუნიციპალიტეტი' },
    { value: 'senaki', label: 'სენაკის რაიონი' },
    { value: 'sachkhere', label: 'საჩხერის რაიონი' },
    { value: 'mestia', label: 'მესტიის მუნიციპალიტეტი' },
    { value: 'samtredia', label: 'სამტრედიის მუნიციპალიტეტი' },
    { value: 'sagarejo', label: 'საგარეჯოს მუნიციპალიტეტი' },
    { value: 'oni', label: 'ონის რაიონი' },
    { value: 'ozurgeti', label: 'ოზურგეთის მუნიციპალიტეტი' },
    { value: 'ninotsminda', label: 'ნინოწმინდის რაიონი' },
    { value: 'mtskheta', label: 'მცხეთის მუნიციპალიტეტი' },
    { value: 'akhalgori', label: 'ახალგორის მუნიციპალიტეტი' },
];

export const areaTypes = [
    { value: 'commercial', label: 'სავაჭრო' },
    { value: 'warehouse', label: 'სასაწყობე' },
    { value: 'industrialspace', label: 'საწარმოო ფართი' },
    { value: 'foodestablishment', label: 'კვების ობიექტი' },
    { value: 'special', label: 'სპეციალური' },
    { value: 'kiosk', label: 'ჯიხური' },
    { value: 'pavilion', label: 'პავილიონი' },
    { value: 'basement', label: 'სარდაფი' },
    { value: 'semibasement', label: 'ნახევარ სარდაფი' },
    { value: 'garage', label: 'ავტოფარეხი' },
    { value: 'autoservice', label: 'ავტო სერვისი' },
    { value: 'carwash', label: 'ავტო სამრეცხაო' },
    { value: 'businessyard', label: 'საქმიანი ეზო' },
    { value: 'wholebuilding', label: 'მთლიანი შენობა' },
    { value: 'newlybuiltbuilding', label: 'ახალი აშენებული კორპუსი' },
    { value: 'oldbuilding', label: 'ძველი აშენებული კორპუსი' },
    { value: 'universal', label: 'უნივერსალური' },
];

export const landTypes = [
    { value: 'agricultural', label: 'სასოფლო სამეურნეო' },
    { value: 'nonagricultural', label: 'არასასოფლო სამეურნეო' },
    { value: 'commercial', label: 'კომერციული' },
    { value: 'special', label: 'სპეციალური' },
    { value: 'investmentconstruction', label: 'საინვესტიციო მშენებლობისთვის' },
    { value: 'arableland', label: 'სახნავ სათესი' },
];

export const functgionZones = [
    { value: 'sz', label: 'სზ (1-6)' },
    { value: 'apz', label: 'APZ' },
    { value: 'grg', label: 'GRG' },
];

export const agriculturalTypes = [
    { value: 'arable', label: 'სახნავი' },
    { value: 'hayfield', label: 'სათიბი' },
    { value: 'pasture', label: 'საძოვარი' },
    { value: 'orchard', label: 'საბაღე' },
    { value: 'vegetablegarden', label: 'საბოსტნე' },
    { value: 'homestead', label: 'საკარმიდამო' },
    { value: 'forestry', label: 'სატყეო' },
];

export const hotelStars = [
    { value: '1star', label: '1 ვარსკვლავი' },
    { value: '2star', label: '2 ვარსკვლავი' },
    { value: '3star', label: '3 ვარსკვლავი' },
    { value: '4star', label: '4 ვარსკვლავი' },
    { value: '5star', label: '5 ვარსკვლავი' },
];

export const eatings = [
    { value: 'singleuse', label: 'ერთჯერადი' },
    { value: 'doubleuse', label: 'ორჯერადი' },
    { value: 'tripleuse', label: 'სამჯერადი' },
];

export const businesscenterClasses = [
    { value: 'aclass', label: 'A კლასი' },
    { value: 'aaclass', label: 'AA კლასი' },
    { value: 'bclass', label: 'B კლასი' },
    { value: 'cclass', label: 'C კლასი' },
    { value: 'withoutclass', label: 'კლასის გარეშე' },
];

export const businessIndustryes = [
    { value: 'manufacturingfactory', label: 'წარმოება ქარხანა' },
    { value: 'foodestablishment', label: 'კვების ობიექტი' },
    { value: 'equipmentmachines', label: 'ტექნიკა დანადგარები' },
    { value: 'hotel', label: 'სასტუმრო' },
    { value: 'gasstation', label: 'ბენზინგასამართი სადგური' },
    { value: 'gasstationlicense', label: 'ბენზინგასამართი სადგურის ლიცენზია' },
    { value: 'carwash', label: 'ავტოსამრეცხაო' },
    { value: 'shop', label: 'მაღაზია' },
    { value: 'operatingbusiness', label: 'მოქმედი ბიზნესი' },
    { value: 'construction', label: 'მშენებლობა' },
    { value: 'autopartsbusiness', label: 'ავტონაწილების ბიზნესი' },
    { value: 'furniturebusiness', label: 'ავეჯის ბიზნესი' },
    { value: 'investmentproject', label: 'საინვესტიციო პროექტი' },
    { value: 'buildingmaterials', label: 'სამშენებლო მასალები' },
    { value: 'other', label: 'სხვა' },
];

export const owners = [
    { value: 'individual', label: 'ფიზიკური პირი' },
    { value: 'smallbusiness', label: 'მცირე მეწარმე' },
    { value: 'legalentity', label: 'იურიდიული პირი' },
];

export const ownerTypes = [
    { value: 'owner', label: 'მესაკუთრე' },
    { value: 'lease', label: 'იჯარა' },
    { value: 'legalentity', label: 'იურიდიული პირი' },
];

export const actives = [
    { value: 'yes', label: 'კი' },
    { value: '', label: 'არა' },
];

export const farmTypes = [
    { value: 'cattle', label: 'მსხვილფეხა' },
    { value: 'poultryfarming', label: 'მეფრინველეობა' },
    { value: 'dairyfarm', label: 'რძის ფერმა' },
    { value: 'greenhouse', label: 'სათბური' },
    { value: 'winemaking', label: 'მეღვინეობა' },
    { value: 'vineyard', label: 'ვენახი' },
    { value: 'fruitorchard', label: 'ხეხილი-ბაღი' },
    { value: 'vegetables', label: 'ბოსტნეული' },
    { value: 'berries', label: 'კენკროვანი' },
    { value: 'hazelnuts', label: 'თხილის' },
    { value: 'walnuts', label: 'კაკლის' },
    { value: 'fishfarm', label: 'საკალმახე' },
    { value: 'incubator', label: 'ინკუბატორი' },
    { value: 'storagefridge', label: 'შესანახი მაცივარი' },
    { value: 'breedingfarm', label: 'საჯიშე ფერმერული მეურნეობა' },
    { value: 'other', label: 'სხვა' },
];

export const farmLandTypes = [
    { value: 'agricultural', label: 'სასოფლო სამეურნეო' },
    { value: 'nonagricultural', label: 'არასასოფლო სამეურნეო' },
    { value: 'commercial', label: 'კომერციული' },
    { value: 'special', label: 'სპეციალური' },
    { value: 'investmentconstruction', label: 'საინვესტიციო მშენებლობისთვის' },
];

export const farmAgriculturalTypes = [
    { value: 'arable', label: 'სახნავი' },
    { value: 'hayfield', label: 'სათიბი' },
    { value: 'pasture', label: 'საძოვარი' },
    { value: 'orchard', label: 'საბაღე' },
    { value: 'vegetablegarden', label: 'საბოსტნე' },
    { value: 'homestead', label: 'საკარმიდამო' },
    { value: 'forestry', label: 'სატყეო' },
];
