import { useState, useEffect } from "react";

//import components
import * as Options from '../utils/PropertyOptions';
import Addonfilter from '../dynamic/Addonfilter'

//import tags
import { Col, Row, Dropdown } from "react-bootstrap";
import Select from 'react-select';
import { json } from "react-router-dom";

//import resources
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faSliders } from "@fortawesome/free-solid-svg-icons";

export default function Filter({setQueryData, queryData}) {

    const [searchQueryData, setSearchQueryData] = useState({});
    const [filterModal, setFilterModal] = useState(false);

    const queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        const newSearchQueryData = {};
    
        queryParams.forEach((value, key) => {
            newSearchQueryData[key] = value.includes(",") ? value.split(",") : value;
        });
    
        setSearchQueryData((prevSearchQueryData) => ({
        ...prevSearchQueryData,
        ...newSearchQueryData,
        }));
    }, []);

    // hide scroll when modal is opened
    useEffect(() => {
        if (filterModal) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [filterModal]);

    function handleChangeSelect(selectedOption, actionMeta) {
        if (actionMeta && selectedOption) {
            let values;
    
            if (Array.isArray(selectedOption)) {
                values = selectedOption.map(option => option.value);
            } else {
                values = selectedOption.value;
            }
    
            setSearchQueryData((prevsearchQueryData) => ({
                ...prevsearchQueryData,
                [actionMeta.name]: values
            }));
        }
    }
      

    const filter = () => {
        setQueryData((prevQueryData) => {
            // Get all keys from the searchQueryData
            const updatedQueryData = { ...searchQueryData };
    
            // Remove any keys from prevQueryData that aren't in updatedQueryData
            Object.keys(prevQueryData).forEach(key => {
                if (!(key in updatedQueryData)) {
                    delete prevQueryData[key];
                }
            });
    
            // Merge the updated query data with prevQueryData (after cleanup)
            return {
                ...prevQueryData,
                ...updatedQueryData,
                page: 1, // Reset the page to 1
            };
        });
    }

    const handleChangeInpputText = (e) => {
        setSearchQueryData((prevsearchQueryData) => ({
            ...prevsearchQueryData,
            key: e.target.value
        }));

    }

    return(
        <>
            <Col xxl={12}>
                <div className="filterCard">
                    <div className="filterInside">
                        <div className="singleFilterBox">
                            <div className="filterBoxMain">
                                
                                <Row>
                                    <Col xxl={3}>
                                        <Dropdown className="filterDropdown">
                                            <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                                <span className="selectedOptionContainer">
                                                    {Options.transactionTypeOptions
                                                        .filter(opt => (searchQueryData?.transaction_type || '').includes(opt.value))
                                                        .map(opt => opt.label)
                                                        .join(', ') || 'აირჩიეთ გარიგების ტიპი'}
                                                </span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <div className="filterFormGroup">
                                                    <Select
                                                        id="transaction-type"
                                                        value={Options.transactionTypeOptions.filter(opt => (searchQueryData?.transaction_type || '').includes(opt.value))}
                                                        name="transaction_type"
                                                        onChange={handleChangeSelect}
                                                        options={Options.transactionTypeOptions}
                                                        placeholder="აირჩიეთ გარიგების ტიპი"
                                                        className="filterSelect"
                                                        isMulti
                                                    />
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>

                                    <Col xxl={3}>
                                        <Dropdown className="filterDropdown">
                                            <Dropdown.Toggle id="dropdown-basic" className="w-100">
                                                <span className="selectedOptionContainer">
                                                    {Options.propertyTypeOptions
                                                        .filter(opt => (searchQueryData?.property_type || '').includes(opt.value))
                                                        .map(opt => opt.label)
                                                        .join(', ') || 'აირჩიეთ უძრავი ქონების ტიპი'}
                                                </span>
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <div className="filterFormGroup">
                                                    <Select
                                                        id="property-type"
                                                        value={Options.propertyTypeOptions.filter(opt => 
                                                            (searchQueryData?.property_type || '').includes(opt.value))}
                                                        name="property_type"
                                                        onChange={handleChangeSelect}
                                                        options={Options.propertyTypeOptions}
                                                        placeholder="აირჩიეთ უძრავი ქონების ტიპი"
                                                        className="filterSelect"
                                                        isMulti
                                                    />
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="filterFormGroup">
                                            <input type='text' name='key' value={searchQueryData?.key || ''} onChange={handleChangeInpputText} placeholder='მოძებნეთ ID, სიტყვა, ს/კ' />
                                        </div>
                                    </Col>
                                    <Col xxl={2}>
                                        <div className="searchButtons">
                                            <div className="moreFilter" onClick={()=>setFilterModal(true)}>
                                                <FontAwesomeIcon icon={faSliders} />
                                            </div>
                                            <button type="button" onClick={()=>filter()} className="mainSearchButton">
                                                ძებნა
                                                <FontAwesomeIcon icon={faMagnifyingGlass} />
                                            </button>
                                        </div>
                                        
                                    </Col>
                                </Row>

                                <div className={`filterModal ` + (filterModal ? 'active' : '')}>
                                    <div className='filterModalOuter'>
                                        {
                                            filterModal ? 
                                                <>
                                                    <Addonfilter 
                                                        setFilterModal={setFilterModal} 
                                                        searchQueryData={searchQueryData} 
                                                        setSearchQueryData={setSearchQueryData} 
                                                        handleChangeSelect={handleChangeSelect}
                                                        filter={filter}
                                                    />
                                                </>
                                            : 
                                            ''
                                        }
                                    </div>
                                </div>
                                
                                
                                
                            </div>


                        </div>
                    </div>
                </div>
            </Col>
        </>
    );
}