//import tags
import React, { useState, useEffect } from "react";
import { useAppContext } from "./../../components/Context";

//import tags
import parse from "html-react-parser";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y } from "swiper/modules";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { toast } from 'react-toastify';

//import resources
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faLocationCrosshairs,
  faBed,
  faDoorOpen,
  faLayerGroup,
  faHashtag,
  faMobileScreen,
  faChevronRight,
  faWifi,
  faBuilding,
  faStreetView,
  faCalendar,
  faBuildingShield,
  faBuildingColumns,
  faStarHalf,
  faStar,
  faBuildingFlag,
  faBuildingNgo,
  faBuildingWheat,
  faBuildingUn,
  faCodeCommit,
  faCode,
  faListNumeric,
  faCheck,
  faCheckCircle,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import {
  faHeart as faHeartRegular,
  faClone,
  faCalendarDays,
  faFlag,
  faCheckSquare,
} from "@fortawesome/free-regular-svg-icons";

import "swiper/css";
import "swiper/css/navigation";

import Propertyimage from "../../assets/image/property.jpg";

//api
import { get_property_details } from "../../api/Api";
import { faCodepen } from "@fortawesome/free-brands-svg-icons";

function Detail({ locale }) {
  const baseUrl = "https://reluxapi.myweb.com.ge";

  const { getenumbykey, setFavoritesCount } = useAppContext();

  const { id, slug } = useParams();

  const [property, setProperty] = useState(false);
  const [toggleView, setToggleView] = useState("0");
  const [favorites, setFavorites] = useState([]);
  const [message, setMessage] = useState("");
  const [currentCurrency, setCurrentCurrency] = useState("GEL");
  const [sortBy, setSortBy] = useState("0");
  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const toggleViewHandler = () => {
    setToggleView(!toggleView);
  };

   //get favorites if saved
  useEffect(() => {
    const storedFavorites = JSON.parse(localStorage.getItem("favorites")) || [];
    setFavorites(storedFavorites);
  }, []);

  //set favorites
  const toggleFavorite = (id) => {
    let updatedFavorites;

    if (favorites.includes(id)) {
      updatedFavorites = favorites.filter((favId) => favId !== id);
      toast.error('წაიშალა ფავორიტებიდან', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } else {
      updatedFavorites = [...favorites, id];
      toast.success('დაემატა ფავორიტებში', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }

    setFavorites(updatedFavorites);

    localStorage.setItem("favorites", JSON.stringify(updatedFavorites));
  };

  //set favorites count context
  useEffect(() => {
    setFavoritesCount(null);
  }, [favorites]);

  const toggleCurrency = (currency) => {
    setCurrentCurrency(currency);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const header = {
          Authorization: "Bearer " + localStorage.getItem("token"),
          Locale: locale,
        };

        get_property_details(setProperty, header, slug);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (!property) {
      fetchData();
    }
  }, [property]);

  return (
    <div className="main">
      <section className="propertyDetailsSection">
        <Container>
          <Row>
            <Col xxl={12}>
              <div className="shortBreadcumb">
                <div className="shortBreadcumbLeft">
                  <h1>
                    {typeof property == "object" &&
                    property?.transaction_type != null
                      ? getenumbykey(parse(property.transaction_type))+' '
                      : ""}
                    {typeof property == "object" &&
                    property?.property_type != null
                      ? getenumbykey(parse(property.property_type))
                      : ""}
                  </h1>
                </div>
                <div className="shortBreadcumbRight">
                  <ul>
                    <li>
                      <Link to="/">მთავარი</Link>
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </li>
                    <li>განცხადებები</li>
                  </ul>
                </div>
              </div>
            </Col>

            {/* {id ?? id}
                        {slug ?? slug} */}

            <Col xxl={12}>
              {message && (
                <div className="addedToFafourites">
                  <h4>{message}</h4>
                </div>
              )}
              <div className="mainImageSlider">
                { typeof property == 'object' ?
                  <Swiper
                    modules={[Navigation, A11y]}
                    spaceBetween={5}
                    slidesPerView={1}
                    navigation
                    scrollbar={{ draggable: true }}
                  >
                    {Array.from({ length: 15 }, (_, i) => `image${i + 1}`).map(
                      (imageKey, index) =>
                        property[imageKey] && property[imageKey] != null ? (
                          <SwiperSlide key={index}>
                            <div className="singleProductImage">
                              <Image
                                src={`${baseUrl}/storage/property/${property.image_folder}/${property[imageKey]}`}
                                alt={`Property image ${index + 1}`}
                              />
                            </div>
                          </SwiperSlide>
                        ) : (
                          ''
                        )
                    )}
                  </Swiper>
                :
                  <div className="singleProductImage">
                    <Skeleton height={500} />
                  </div>
                }

                <div
                  className={`singleProductGridTag ${
                    property.statement_type === "exclusive"
                      ? "exclusiveAd"
                      : "reluxAd"
                  }`}
                >
                  <Link to="/">
                    <h4>
                      {typeof property == "object" &&
                      property?.statement_type != null
                        ? getenumbykey(parse(property.statement_type))
                        : ""}
                    </h4>
                  </Link>
                </div>

                <div
                  className="singleProductFavourite"
                  onClick={() => toggleFavorite(property?.id)}
                >
                  {favorites.includes(property?.id) ? (
                    <FontAwesomeIcon icon={faHeart} />
                  ) : (
                    <FontAwesomeIcon icon={faHeartRegular} />
                  )}
                </div>
              </div>
            </Col>

            <Col xxl={8}>
              <div className="propertyDetailsLeft">
                <div className="propertyDetailsTags">
                  <div className="singleTag">
                    {typeof property == "object" &&
                    property?.property_type != null ? (
                      getenumbykey(parse(property.property_type))
                    ) : (
                      <Skeleton width={100} />
                    )}
                  </div>
                  <div className="singleTag">
                    {typeof property == "object" && property?.city != null ? (
                      getenumbykey(parse(property.city))
                    ) : (
                      <Skeleton width={100} />
                    )}
                  </div>
                </div>

                <h2>
                  {typeof property == "object" &&
                  property?.transaction_type != null
                    ? getenumbykey(parse(property.transaction_type))+' '
                    : ""}
                  {typeof property == "object" &&
                  property?.property_status != null
                    ? getenumbykey(parse(property.property_status))+' '
                    : ""}
                  {typeof property == "object" &&
                  property?.property_type != null
                    ? getenumbykey(parse(property.property_type))
                    : ""}
                </h2>

                <div className="singlePropertyLocation">
                  <FontAwesomeIcon icon={faLocationCrosshairs} />
                  <p>
                    {typeof property == "object" && property?.region != null
                      ? getenumbykey(parse(property.region))+', '
                      : ""}
                    {typeof property == "object" && property?.destrict != null
                      ? getenumbykey(parse(property.destrict))+', '
                      : ""}
                    {typeof property == "object" &&
                    property?.municipality != null
                      ? getenumbykey(parse(property.municipality))+', '
                      : ""}
                    {typeof property == "object" && property?.village != null
                      ? property.village +', '
                      : ""}
                    {typeof property == "object" && property?.address != null
                      ? property.address
                      : ""}
                  </p>
                </div>

                <div className="propertyDetailsData">
                  <p>
                    <FontAwesomeIcon icon={faHashtag} />
                    <strong>
                      {typeof property == "object" && property?.id != null
                        ? property.id
                        : ""}
                    </strong>
                  </p>
                  <p>
                    <FontAwesomeIcon icon={faCalendarDays} />
                    <strong>
                      {typeof property === "object" && property?.created_at
                        ? new Date(property.created_at).toLocaleString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )
                        : ""}
                    </strong>
                  </p>
                </div>

                <div className="singlePropertyMiniBox">
                  {(() => {
                    switch (property?.property_type) {
                      case "land":
                        return (
                          <div className="singlePropertyMini" title="ფართი">
                            <FontAwesomeIcon icon={faClone} />:
                            <span>
                              ფართი
                              <strong>
                                {property?.area != null ? property.area : ""}
                              </strong>
                              მ<sup>2</sup>
                            </span>
                          </div>
                        );
                      case "apartment":
                        return (
                          <>
                            <div
                              className="singlePropertyMini"
                              title="საძინებელი"
                            >
                              <FontAwesomeIcon icon={faBed} />
                              <span>
                                საძინებელი
                                <strong>
                                  {property?.bedrooms != null
                                    ? property.bedrooms
                                    : ""}
                                </strong>
                              </span>
                            </div>
                            <div className="singlePropertyMini" title="ოთახი">
                              <FontAwesomeIcon icon={faDoorOpen} />
                              <span>
                                ოთახი
                                <strong>
                                  {property?.rooms != null
                                    ? property.rooms
                                    : ""}
                                </strong>
                              </span>
                            </div>
                            <div className="singlePropertyMini" title="სართული">
                              <FontAwesomeIcon icon={faLayerGroup} />
                              <span>
                                სართული
                                <strong>
                                  {property?.floor != null
                                    ? property.floor
                                    : ""}
                                </strong>
                              </span>
                            </div>
                            <div className="singlePropertyMini" title="ფართი">
                              <FontAwesomeIcon icon={faClone} />
                              <span>
                                ფართი
                                <strong>
                                  {property?.area != null ? property.area : ""}
                                </strong>
                                მ<sup>2</sup>
                              </span>
                            </div>
                          </>
                        );
                      case "business":
                        return (
                          <>
                            <div className="singlePropertyMini" title="ფართი">
                              <FontAwesomeIcon icon={faClone} />
                              <span>
                                ფართი
                                <strong>
                                  {property?.area != null ? property.area : ""}
                                </strong>
                                მ<sup>2</sup>
                              </span>
                            </div>
                            <div className="singlePropertyMini" title="ოთახი">
                              <FontAwesomeIcon icon={faDoorOpen} />
                              <span>
                                ოთახი
                                <strong>
                                  {property?.rooms != null
                                    ? property.rooms
                                    : ""}
                                </strong>
                              </span>
                            </div>
                          </>
                        );
                      default:
                        return null;
                    }
                  })()}
                </div>

                <div className="propertyCharacteristics">
                  <h3>მახასიათებლები</h3>

                  <div className="propertyCharacteristicsInner">
                    <Row>
                      <Col xxl={4}>
                        <div className="propertyCharacteristicsSingleBox">
                          <div className="propertyCharacteristicsSingleBoxLeft">
                            <FontAwesomeIcon icon={faBuilding} />
                          </div>
                          <div className="propertyCharacteristicsSingleBoxRight">
                            <p>პროექტი</p>
                            <h5>
                              {typeof property == "object" &&
                              property?.project != null
                                ? getenumbykey(parse(property.project))
                                : ""}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col xxl={4}>
                        <div className="propertyCharacteristicsSingleBox">
                          <div className="propertyCharacteristicsSingleBoxLeft">
                            <FontAwesomeIcon icon={faStar} />
                          </div>
                          <div className="propertyCharacteristicsSingleBoxRight">
                            <p>კლასი</p>
                            <h5>
                              {typeof property == "object" &&
                              property?.class != null
                                ? getenumbykey(parse(property.class))
                                : ""}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col xxl={4}>
                        <div className="propertyCharacteristicsSingleBox">
                          <div className="propertyCharacteristicsSingleBoxLeft">
                            <FontAwesomeIcon icon={faBuildingShield} />
                          </div>
                          <div className="propertyCharacteristicsSingleBoxRight">
                            <p>მდგომარეობა</p>
                            <h5>
                              {typeof property == "object" &&
                              property?.condition != null
                                ? getenumbykey(parse(property.condition))
                                : ""}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col xxl={4}>
                        <div className="propertyCharacteristicsSingleBox">
                          <div className="propertyCharacteristicsSingleBoxLeft">
                            <FontAwesomeIcon icon={faBuildingColumns} />
                          </div>
                          <div className="propertyCharacteristicsSingleBoxRight">
                            <p>სამშენებლო კომპანია</p>
                            <h5>
                              {typeof property == "object" &&
                              property?.building_company != null
                                ? property.building_company
                                : ""}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col xxl={4}>
                        <div className="propertyCharacteristicsSingleBox">
                          <div className="propertyCharacteristicsSingleBoxLeft">
                            <FontAwesomeIcon icon={faCalendarDays} />
                          </div>
                          <div className="propertyCharacteristicsSingleBoxRight">
                            <p>აშენების თარიღი</p>
                            <h5>
                              {typeof property == "object" &&
                              property?.build_date != null
                                ? property.build_date
                                : ""}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      <Col xxl={4}>
                        <div className="propertyCharacteristicsSingleBox">
                          <div className="propertyCharacteristicsSingleBoxLeft">
                            <FontAwesomeIcon icon={faCalendarDays} />
                          </div>
                          <div className="propertyCharacteristicsSingleBoxRight">
                            <p>დაწყება-დასრულების დრო</p>
                            <h5>
                              {typeof property == "object" &&
                              property?.start_date != null
                                ? property.start_date
                                : ""}
                              {typeof property == "object" &&
                              property?.end_date != null
                                ? property.end_date
                                : ""}
                            </h5>
                          </div>
                        </div>
                      </Col>

                      {
                        [property?.cadaster_1, property?.cadaster_2, property?.cadaster_3, property?.cadaster_4, property?.cadaster_5].map(item => {
                          return (
                            item != null ?
                              <Col xxl={4}>
                                <div className="propertyCharacteristicsSingleBox">
                                  <div className="propertyCharacteristicsSingleBoxLeft">
                                    <FontAwesomeIcon icon={faHashtag} />
                                  </div>
                                  <div className="propertyCharacteristicsSingleBoxRight">
                                    <p>საკადასტრო კოდი</p>
                                    <h5>{item}</h5>
                                  </div>
                                </div>
                              </Col>
                            : null
                          )
                        })
                      }
                      

                    </Row>
                  </div>
                </div>

                <div className="propertyDescription">
                  <h3>აღწერა</h3>
                  <p>
                    {showFullText ? property?.translations?.[0]?.text : property?.translations?.[0]?.text.slice(0, 142)}
                    {property?.translations?.[0]?.text.length > 150 && (
                      <div className="showMoreText" onClick={toggleText}>
                        {showFullText ? "დამალვა" : "მეტის ნახვა"}
                      </div>
                    )}
                  </p>
                </div>

                <div className="propertyDetails">
                  <div className="propertyCharacteristics">
                    <h3>დეტალები</h3>
                    <div className="propertyCharacteristicsInner">
                      <Row>
                        <Col xxl={4}>
                          <div className="propertyCharacteristicsSingleBox">
                            <div className="propertyCharacteristicsSingleBoxLeft">
                              <FontAwesomeIcon icon={faBuilding} />
                            </div>
                            <div className="propertyCharacteristicsSingleBoxRight">
                              <p>სრული ფართი</p>
                              <h5>
                                {property?.area != null ? property.area : ""} მ
                                <sup>2</sup>
                              </h5>
                            </div>
                          </div>
                        </Col>

                        <Col xxl={4}>
                          <div className="propertyCharacteristicsSingleBox">
                            <div className="propertyCharacteristicsSingleBoxLeft">
                              <FontAwesomeIcon icon={faBuilding} />
                            </div>
                            <div className="propertyCharacteristicsSingleBoxRight">
                              <p>ოთახების რაოდენობა</p>
                              <h5>
                                {property?.rooms != null ? property.rooms : ""}
                              </h5>
                            </div>
                          </div>
                        </Col>

                        <Col xxl={4}>
                          <div className="propertyCharacteristicsSingleBox">
                            <div className="propertyCharacteristicsSingleBoxLeft">
                              <FontAwesomeIcon icon={faBuilding} />
                            </div>
                            <div className="propertyCharacteristicsSingleBoxRight">
                              <p>საძინებლების რაოდენობა</p>
                              <h5>
                                {property?.bedrooms != null
                                  ? property.bedrooms
                                  : ""}
                              </h5>
                            </div>
                          </div>
                        </Col>

                        <Col xxl={4}>
                          <div className="propertyCharacteristicsSingleBox">
                            <div className="propertyCharacteristicsSingleBoxLeft">
                              <FontAwesomeIcon icon={faBuilding} />
                            </div>
                            <div className="propertyCharacteristicsSingleBoxRight">
                              <p>მასტერ საძინებლების რ.ბა</p>
                              <h5>
                                {property?.masterbedrooms != null
                                  ? property.masterbedrooms
                                  : ""}
                              </h5>
                            </div>
                          </div>
                        </Col>

                        <Col xxl={4}>
                          <div className="propertyCharacteristicsSingleBox">
                            <div className="propertyCharacteristicsSingleBoxLeft">
                              <FontAwesomeIcon icon={faBuilding} />
                            </div>
                            <div className="propertyCharacteristicsSingleBoxRight">
                              <p>სართული</p>
                              <h5>
                                {property?.floor != null ? property.floor : ""}
                              </h5>
                            </div>
                          </div>
                        </Col>

                        <Col xxl={4}>
                          <div className="propertyCharacteristicsSingleBox">
                            <div className="propertyCharacteristicsSingleBoxLeft">
                              <FontAwesomeIcon icon={faBuilding} />
                            </div>
                            <div className="propertyCharacteristicsSingleBoxRight">
                              <p>სართული სულ</p>
                              <h5>
                                {property?.allfloor != null
                                  ? property.allfloor
                                  : ""}
                              </h5>
                            </div>
                          </div>
                        </Col>

                        <Col xxl={4}>
                          <div className="propertyCharacteristicsSingleBox">
                            <div className="propertyCharacteristicsSingleBoxLeft">
                              <FontAwesomeIcon icon={faBuilding} />
                            </div>
                            <div className="propertyCharacteristicsSingleBoxRight">
                              <p>სველი წერტილების რ.ბა</p>
                              <h5>
                                {property?.bathrooms != null
                                  ? property.bathrooms
                                  : ""}
                              </h5>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>პარამეტრები</h3>

                          <div className="propertyDetailsPropBox">
                            {property?.livingroom == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>მისაღები</h5>
                                  <h6>
                                    ფართი კვ.მ:
                                    <strong>
                                      {property?.livingspace != null
                                        ? property.livingspace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                  <p>
                                    რაოდენობა:
                                    <strong>
                                      {property?.livingamount != null
                                        ? property.livingamount
                                        : ""}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.studio == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>სტუდიო</h5>
                                  <h6>
                                    ფართი კვ.მ:
                                    <strong>
                                      {property?.studiospace != null
                                        ? property.studiospace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.kitchen == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>სამზარეულო</h5>
                                  <h6>
                                    ფართი კვ.მ:
                                    <strong>
                                      {property?.kitchenspace != null
                                        ? property.kitchenspace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                  <p>
                                    რაოდენობა:
                                    <strong>
                                      {property?.kitchenamount != null
                                        ? property.kitchenamount
                                        : ""}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.openbalcony == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>ღია აივანი</h5>
                                  <h6>
                                    ფართი კვ.მ:
                                    <strong>
                                      {property?.openbalconyspace != null
                                        ? property.openbalconyspace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                  <p>
                                    რაოდენობა:
                                    <strong>
                                      {property?.openbalconyamount != null
                                        ? property.openbalconyamount
                                        : ""}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.closedbalcony == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>დახურული აივანი</h5>
                                  <h6>
                                    ფართი კვ.მ:
                                    <strong>
                                      {property?.closedbalconyspace != null
                                        ? property.closedbalconyspace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                  <p>
                                    რაოდენობა:
                                    <strong>
                                      {property?.closedbalconyamount != null
                                        ? property.closedbalconyamount
                                        : ""}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.frenchbalcony == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>ფრანგული აივანი</h5>
                                  <h6>
                                    ფართი კვ.მ:
                                    <strong>
                                      {property?.frenchbalconyspace != null
                                        ? property.frenchbalconyspace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                  <p>
                                    რაოდენობა:
                                    <strong>
                                      {property?.frenchbalconyamount != null
                                        ? property.frenchbalconyamount
                                        : ""}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.terrace == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>ვერანდა/ტერასა</h5>
                                  <h6>
                                    ფართი კვ.მ:
                                    <strong>
                                      {property?.terracespace != null
                                        ? property.terracespace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                  <p>
                                    რაოდენობა:
                                    <strong>
                                      {property?.terraceamount != null
                                        ? property.terraceamount
                                        : ""}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.dressingroom == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>საგარდერობო ოთახი</h5>
                                  <h6>
                                    ფართი კვ.მ:
                                    <strong>
                                      {property?.dressingroomspace != null
                                        ? property.dressingroomspace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                  <p>
                                    რაოდენობა:
                                    <strong>
                                      {property?.dressingroomamount != null
                                        ? property.dressingroomamount
                                        : ""}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.storage == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>სათავსო</h5>
                                  <h6>
                                    ფართი კვ.მ
                                    <strong>
                                      {property?.storagespace != null
                                        ? property.storagespace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                  <p>
                                    რაოდენობა:
                                    <strong>
                                      {property?.storageamount != null
                                        ? property.storageamount
                                        : ""}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.loft == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>სხვენი</h5>
                                  <h6>
                                    ფართი კვ.მ
                                    <strong>
                                      {property?.loftspace != null
                                        ? property.loftspace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.duplex == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>დუპლექსი</h5>
                                  <h6>
                                    ფართი კვ.მ
                                    <strong>
                                      {property?.duplexspace != null
                                        ? property.duplexspace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.triplex == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>ტრიპლექსი</h5>
                                  <h6>
                                    ფართი კვ.მ
                                    <strong>
                                      {property?.triplexspace != null
                                        ? property.triplexspace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.summerkitchen == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>საზაფხულო სამზარეულო</h5>
                                  <h6>
                                    ფართი კვ.მ
                                    <strong>
                                      {property?.summerkitchenspace != null
                                        ? property.summerkitchenspace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.basement == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>სარდაფი</h5>
                                  <h6>
                                    ფართი კვ.მ
                                    <strong>
                                      {property?.basementspace != null
                                        ? property.basementspace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                  <p>
                                    რაოდენობა:
                                    <strong>
                                      {property?.basementamount != null
                                        ? property.basementamount
                                        : ""}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.loggia == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>ლოჯი</h5>
                                  <h6>
                                    ფართი კვ.მ
                                    <strong>
                                      {property?.loggiaspace != null
                                        ? property.loggiaspace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.hall == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>ჰოლი</h5>
                                  <h6>
                                    ფართი კვ.მ
                                    <strong>
                                      {property?.hallspace != null
                                        ? property.hallspace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.buildup == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>დაშენება</h5>
                                  <h6>
                                    ფართი კვ.მ
                                    <strong>
                                      {property?.buildupspace != null
                                        ? property.buildupspace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.buildup1 == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>მიშენება</h5>
                                  <h6>
                                    ფართი კვ.მ
                                    <strong>
                                      {property?.buildupspace1 != null
                                        ? property.buildupspace1
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.commoncorridor == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>საერთო კორიდორი</h5>
                                  <h6>
                                    ფართი კვ.მ
                                    <strong>
                                      {property?.commoncorridorspace != null
                                        ? property.commoncorridorspace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.antresor == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>ანტრესორი</h5>
                                  <h6>
                                    ფართი კვ.მ
                                    <strong>
                                      {property?.antresorspace != null
                                        ? property.antresorspace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.wintergarden == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>ზამთრის ბაღი</h5>
                                  <h6>
                                    ფართი კვ.მ
                                    <strong>
                                      {property?.wintergardenspace != null
                                        ? property.wintergardenspace
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.garage == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>ავტოფარეხი</h5>
                                  <h6>
                                    ფართი კვ.მ
                                    <strong>
                                      {property?.garageamount != null
                                        ? property.garageamount
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.yardparking == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>ეზოს პარკინგი</h5>
                                  <h6>
                                    ფართი კვ.მ
                                    <strong>
                                      {property?.yardparkingamount != null
                                        ? property.yardparkingamount
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.yardparking == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>ეზოს პარკინგი</h5>
                                  <h6>
                                    ფართი კვ.მ
                                    <strong>
                                      {property?.yardparkingamount != null
                                        ? property.yardparkingamount
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.undergroundparking == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>მიწისქვეშა პარკინგი</h5>
                                  <h6>
                                    ფართი კვ.მ
                                    <strong>
                                      {property?.undergroundparkingamount !=
                                      null
                                        ? property.undergroundparkingamount
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.parkingspace == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>პარკინგის ადგილი</h5>
                                  <h6>
                                    ფართი კვ.მ
                                    <strong>
                                      {property?.parkingspaceamount != null
                                        ? property.parkingspaceamount
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.paidparking == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>ფასიანი პარკინგი</h5>
                                  <h6>
                                    ფართი კვ.მ
                                    <strong>
                                      {property?.paidparkingamount != null
                                        ? property.paidparkingamount
                                        : ""}
                                      მ<sup>2</sup>
                                    </strong>
                                  </h6>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>დამატებითი პარამეტრები</h3>

                          <div className="propertyDetailsPropBox">
                            {property?.elevator == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>სამგზავრო ლიფტი</h5>
                                  <p>
                                    რაოდენობა:
                                    <strong>
                                      {property?.elevatoramount != null
                                        ? property.elevatoramount
                                        : ""}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.freightelevator == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>სატვირთო ლიფტი</h5>
                                  <p>
                                    რაოდენობა:
                                    <strong>
                                      {property?.freightelevatoramount != null
                                        ? property.freightelevatoramount
                                        : ""}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.personalelevator == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>პერსონალური ლიფტი</h5>
                                  <p>
                                    <strong>კი</strong>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.outdoorpool == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>ღია აუზი</h5>
                                  <p>
                                    <strong>კი</strong>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.Indoorpool == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>დახურული აუზი</h5>
                                  <p>
                                    <strong>კი</strong>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            {property?.fireplace == "yes" ? (
                              <div className="mainParamBox">
                                <div className="paramIcon">
                                  <FontAwesomeIcon icon={faCheckSquare} />
                                </div>
                                <div className="paramText">
                                  <h5>ბუხარი</h5>
                                  <p>
                                    რაოდენობა:
                                    <strong>
                                      {property?.fireplaceamount != null
                                        ? property.fireplaceamount
                                        : ""}
                                    </strong>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>

                  {property?.hotwater ? (
                    <div className="detailsCardBox">
                      <Row>
                        <Col xxl={12}>
                          <div className="propertyDetailsProp">
                            <h3>ცხელი წყალი</h3>

                            <div className="propertyDetailsPropBox">
                              <Row>
                                {property?.hotwater?.includes(
                                  "centralheating"
                                ) ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ცენტრალური გათბობის სისტემა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.hotwater?.includes("gasheater") ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>გაზის გამაცხელებელი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.hotwater?.includes("tank") ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ავზი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.hotwater?.includes(
                                  "electricheater"
                                ) ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>დენის გამაცხელებელი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.hotwater?.includes(
                                  "naturalhotwater"
                                ) ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ბუნებრივი ცხელი წყალი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.hotwater?.includes("solarheater") ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>მზის გამათბობელი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}

                  {property?.heating ? (
                    <div className="detailsCardBox">
                      <Row>
                        <Col xxl={12}>
                          <div className="propertyDetailsProp">
                            <h3>გათბობა</h3>

                            <div className="propertyDetailsPropBox">
                              <Row>
                                {property?.heating?.includes(
                                  "centralheating"
                                ) ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ცენტრალური გათბობა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.heating?.includes("gasheater") ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>გაზის გამათბობელი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.heating?.includes("floorheating") ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>იატაკის გათბობა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.heating?.includes("powerheater") ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>დენის გამათბობელი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.heating?.includes("chiller") ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ჩილერი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.heating?.includes("solarheater") ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>მზის გამათბობელი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}

                  {property?.generalamenities ? (
                    <div className="detailsCardBox">
                      <Row>
                        <Col xxl={12}>
                          <div className="propertyDetailsProp">
                            <h3>ზოგადი კეთილმოწყობა</h3>

                            <div className="propertyDetailsPropBox">
                              <Row>
                                {property?.generalamenities?.includes(
                                  "naturalgas"
                                ) ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ბუნებრივი აირი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.generalamenities?.includes(
                                  "generator"
                                ) ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>გენერატორი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.generalamenities?.includes(
                                  "internet"
                                ) ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ინტერნეტი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.generalamenities?.includes(
                                  "television"
                                ) ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ტელევიზია</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.generalamenities?.includes(
                                  "alarmsystem"
                                ) ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>სიგნალიზაცია</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.generalamenities?.includes(
                                  "satelit"
                                ) ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>სატელიტური თეფში</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                
                                {property?.generalamenities?.includes(
                                  "solarPanel"
                                ) ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>მზის ენერგიის პანელი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}

                  {property?.facade1 ? (
                    <div className="detailsCardBox">
                      <Row>
                        <Col xxl={12}>
                          <div className="propertyDetailsProp">
                            <h3>ფასადი</h3>

                            <div className="propertyDetailsPropBox">
                              <Row>
                                
                                {property?.facade1?.includes("galesili") ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>გალესილი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.facade1?.includes("facadePanel") ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ფასადის პანელი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.facade1?.includes("aguri") ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>აგური</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.facade1?.includes("traventili") ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ტრავენტილი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.facade1?.includes("xisPaneli") ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ხის პანელი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                
                                {property?.facade1?.includes("hplPanel") ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>HPL პანელი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                                
                                {property?.facade1?.includes("combined") ? (
                                  <Col xxl={6}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>კომბინირებული</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}

                  {property?.furniture ? (
                    <div className="detailsCardBox">
                      <Row>
                        <Col xxl={12}>
                          <div className="propertyDetailsProp">
                            <h3>ავეჯი</h3>

                            <div className="propertyDetailsPropBox">
                              <Row>
                                {property?.furniture?.includes(
                                  "livingroomfurniture"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>მისაღები ოთახის ავეჯი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.furniture?.includes(
                                  "softfurniture"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>რბილი ავეჯი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.furniture?.includes(
                                  "kitchenfurniture"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>სამზარეულოს ავეჯი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.furniture?.includes(
                                  "bedroomfurniture"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>საძინებლის ავეჯი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.furniture?.includes(
                                  "summerfurniture"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>საზაფხულო ავეჯი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.furniture?.includes(
                                  "officefurniture"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>საოფისე ავეჯი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.furniture?.includes(
                                  "childrensfurniture"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>საბავშვო ავეჯი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.furniture?.includes(
                                  "partiallyfurnished"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ნაწილობრივ ავეჯი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.furniture?.includes(
                                  "unfurnished"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ავეჯის გარეშე</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}

                  {property?.technic ? (
                    <div className="detailsCardBox">
                      <Row>
                        <Col xxl={12}>
                          <div className="propertyDetailsProp">
                            <h3>ტექნიკა</h3>

                            <div className="propertyDetailsPropBox">
                              <Row>
                                {property?.technic?.includes("television") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ტელევიზორი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes("refrigerator") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>მაცივარი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes(
                                  "microwaveoven"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>მიკროტალღური ღუმელი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes("dishwasher") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ჭურჭლის სარეცხი მანქანა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes(
                                  "washingmachine"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>სარეცხი მანქანა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes(
                                  "airconditioner"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>კონდინციონერი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes("stereosystem") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>მუსიკალური ცენტრი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes("rangehood") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>გამწოვი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes("stove") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>გაზქურა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes(
                                  "vacuumcleaner"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>მტვერსასრუტი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes("iron") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>უთო</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes("homecinema") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>კინოთეატრი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes("dryer") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>საშრობი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes("coffeemaker") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ყავის აპარატი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes(
                                  "electrickettle"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ელექტრო ჩაიდანი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes("toaster") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ტოსტერი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes("juicer") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>წვენსაწური</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes("mixer") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>მიქსერი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes("blender") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ბლენდერი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes("meatgrinder") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ხორცის საკეპი მანქანა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes("computer") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>კომპიუტერი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes(
                                  "printerscannerxerox"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>პრინტერი / სკანერი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes("safe") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>სეიფი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.technic?.includes(
                                  "fitnessequipment"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>სპორტული ტრენაჟორები</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}

                  {property?.security ? (
                    <div className="detailsCardBox">
                      <Row>
                        <Col xxl={12}>
                          <div className="propertyDetailsProp">
                            <h3>დაცვის სერვისი</h3>

                            <div className="propertyDetailsPropBox">
                              <Row>
                                {property?.security?.includes("alarmsystem") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>სიგნალიზაცია</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.security?.includes(
                                  "livesecurity"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ცოცხალი დაცვა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.security?.includes("cctvcameras") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ვიდეო კამერები</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.security?.includes(
                                  "entranceaccesssystem"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>სადარბაზოს დაშვების სისტემა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.security?.includes(
                                  "gatedaccesssystem"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ეზოს დაშვების სისტემა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}

                  {property?.smarthouse ? (
                    <div className="detailsCardBox">
                      <Row>
                        <Col xxl={12}>
                          <div className="propertyDetailsProp">
                            <h3>ჭკვიანი სახლი</h3>

                            <div className="propertyDetailsPropBox">
                              <Row>
                                {property?.smarthouse?.includes(
                                  "controlpanels"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>მართვის პანელები</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.smarthouse?.includes(
                                  "securitysystems"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>უსაფრთხოების სისტემები</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.smarthouse?.includes(
                                  "smartcamera"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ჭკვიანი კამერა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.smarthouse?.includes(
                                  "curtainblind"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ფარდა ჟალუზი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.smarthouse?.includes(
                                  "lightingautomation"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>განათების ავტომატიზირება</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.smarthouse?.includes("smartlock") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ჭკვიანი საკეტი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.smarthouse?.includes(
                                  "householdappliancecontrol"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>საყოფაცხოვრებო ტექნიკის მართვა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.smarthouse?.includes(
                                  "audiosystem"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>აუდიო სისტემა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.smarthouse?.includes(
                                  "audiosystem"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>აუდიო სისტემა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.smarthouse?.includes(
                                  "smartintercom"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ჭკვიანი დომოფონი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}

                  {property?.additionaladvantages ? (
                    <div className="detailsCardBox">
                      <Row>
                        <Col xxl={12}>
                          <div className="propertyDetailsProp">
                            <h3>დამატებითი უპირატესობები</h3>

                            <div className="propertyDetailsPropBox">
                              <Row>
                                {property?.additionaladvantages?.includes(
                                  "organizedinfrastructure"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>მოწესრიგებული ინფრასტრუქტურა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "panoramicview"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>პანორამული ხედი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "cityview"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ხედი ქალაქზე</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "seaview"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ხედი ზღვაზე</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "mountainview"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ხედი მთაზე</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "riverview"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ხედი მდინარეზე</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "lakeview"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ხედი ტბაზე</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "courtyardview"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ხედი ეზოში</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "bright"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ნათელი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "stainedglasswindowdoor"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ვიტრაჟული კარ-ფანჯარა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "landscaping"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>გამწვანება</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "childrenspace"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>საბავშვო სივრცე</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "sportsfield"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>სპორტული მოედანი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "concierge"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>კონსიაჟი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "coworkingspace"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>საერთო სამუშაო სივრცე</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "businesslobby"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ბიზნეს ლობი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "cafeteria"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>კაფეტერია</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "restaurant"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>რესტორანი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "conferencehall"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>საკონფერენციო დარბაზი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "eventhall"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ივენთ ჰოლი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "commercialspace"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>კომერციული ფართი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "residentiallobby"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>საცხოივრებელი ლობი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "freewifi"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>უფასო Wifi</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "laundry"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>სამრეცხაო</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "spacenter"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>სპა ცენტრი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "sauna"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>საუნა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "fitnessroom"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ფიტნეს დარბაზი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "tenniscourts"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>კორტები</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "golfcourse"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>გოლფის მოედანი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "multifunctional"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>მულტიფუნქციური</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "energyefficient"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ენერგო ეფექტური</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "modernarchitecture"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>თანამედროვე არქიტექტურა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "glazedbalconies"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>შემინული აივნები</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "jacuzzi"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ჯაკუზი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionaladvantages?.includes(
                                  "showercabinplusbathtub"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>დუშ კაბინა + ვანა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}

                  {property?.additionalsecurity ? (
                    <div className="detailsCardBox">
                      <Row>
                        <Col xxl={12}>
                          <div className="propertyDetailsProp">
                            <h3>დამატებითი უსაფრთხოება</h3>

                            <div className="propertyDetailsPropBox">
                              <Row>
                                {property?.additionalsecurity?.includes(
                                  "evacuationstaircase"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>საევაკუაციო კიბე</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionalsecurity?.includes(
                                  "firesafetyssystem"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ხანძრის საწინააღმდეგო სისტემა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionalsecurity?.includes(
                                  "codetector"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>CO დეტექტორი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.additionalsecurity?.includes(
                                  "smokedetector"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>კვამლის დეტექტორი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}

                  {property?.nearobject ? (
                    <div className="detailsCardBox">
                      <Row>
                        <Col xxl={12}>
                          <div className="propertyDetailsProp">
                            <h3>ობიექტთან ახლოს</h3>

                            <div className="propertyDetailsPropBox">
                              <Row>
                                {property?.nearobject?.includes(
                                  "citycenter"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ქალაქის ცენტრი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes("busstop") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ავტობუსის გაჩერება</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes(
                                  "shoppingcenter"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>სავაჭრო ცენტრი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes("school") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>სკოლა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes(
                                  "kindergarten"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>საბავშვო ბაღი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes(
                                  "metrosstation"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>მეტროსადგური</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes("cinema") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>კინოთეატრი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes("square") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>სკვერი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes("museum") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>მუზეუმი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes(
                                  "restaurant"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>რესტორანი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes(
                                  "supermarket"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>სუპერმარკეტი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes("pharmacy") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>აფთიაქი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes("hospital") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>საავადმყოფო</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes(
                                  "railwaystation"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>რკინიგზის სადგური</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes("airport") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>აეროპორტი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes("sea") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ზღვა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes("river") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>მდინარე</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes("lake") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ტბა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes(
                                  "naturereserve"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ნაკრძალი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes(
                                  "forest"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ტყე</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.nearobject?.includes(
                                  "university"
                                ) ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>უნივერსიტეტი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}

                  {property?.allowed ? (
                    <div className="detailsCardBox">
                      <Row>
                        <Col xxl={12}>
                          <div className="propertyDetailsProp">
                            <h3>დაშვებულია</h3>

                            <div className="propertyDetailsPropBox">
                              <Row>
                                {property?.allowed?.includes("eventhosting") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>წვეულების გამართვა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.allowed?.includes("pets") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>შინაური ცხოველები</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.allowed?.includes("smoking") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>მოწევა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* Hidden details start */}

                  {/* კერძო სახლი / თაუნჰაუსი */}
                  { property?.property_type == 'house' || property?.property_type == 'townhouse' ?
                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>კერძო სახლი-აგარაკის / თაუნ ჰაუსის დეტალები</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              
                              {property?.yeard == "yes" ? (
                                <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faCheckSquare} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ეზო</h5>
                                    <h6>
                                      ფართი კვ.მ:
                                      <strong>
                                      {property?.yeardarea != null
                                      ? property.yeardarea
                                      : ""} მ<sup>2</sup>
                                      </strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>
                              ) : (
                                ""
                              )}

                              {property?.fence == "yes" ? (
                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>ღობე</h5>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {property?.vine == "yes" ? (
                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>ვაზი</h5>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {property?.fruit == "yes" ? (
                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>ხეხილი</h5>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {property?.citrus == "yes" ? (
                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>ციტრუსი</h5>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {property?.leafy == "yes" ? (
                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>ფოთლოვანი</h5>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {property?.coniferous == "yes" ? (
                                <Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>წიწვოვანი</h5>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              
                            </Row>
                          </div>
                          
                          {property?.road ? (
                            <div className="propertyDetailsInner">
                              <h4>გზა</h4>

                              <Row>

                                {property?.road?.includes("asphalted") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ასფალტიანი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.road?.includes("graveled") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>მოხრეშილი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.road?.includes("soil") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>გრუნტი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}
                              </Row>
                            </div>
                            ) : (
                            ""
                          )}

                          
                          {property?.buildingmaterial ? (
                            <div className="propertyDetailsInner">
                              <h4>აშენების მასალა</h4>

                              <Row>

                                {property?.buildingmaterial?.includes("block") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ბლოკი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.buildingmaterial?.includes("brick") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>აგური</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.buildingmaterial?.includes("stone") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ქვა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.buildingmaterial?.includes("wood") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ხე</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.buildingmaterial?.includes("pumiceblock") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>პემზის ბლოკი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.buildingmaterial?.includes("reinforcedconcrete") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>რკინა-ბეტონი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.buildingmaterial?.includes("sendvichPanel") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>სენდვიჩ პანელი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.buildingmaterial?.includes("minaPaket") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>მინა პაკეტი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.buildingmaterial?.includes("combined") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>კომბინირებული</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                              </Row>
                            </div>
                          ) : (
                            ""
                          )}
                          
                          
                          <div className="propertyDetailsInner">
                            <h4>პროექტი</h4>
                            <Row>
                            
                              {property?.project2 == "project" ? (
                                <Col xxl={12}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramListText">
                                      <h5>პროექტი</h5>
                                      <p>სამშენებლო კომპანიის დასახელება: <span>
                                        {property?.constcompanyname != null
                                        ? property.constcompanyname
                                        : ""}</span>
                                      </p>
                                    </div>
                                  </div>
                                </Col> 
                              ) : (
                                ""
                              )}

                            </Row>
                          </div>
                          
                          {property?.overlap ? (
                            <div className="propertyDetailsInner">
                              <h4>გადახურვა</h4>

                              <Row>

                                {property?.overlap?.includes("flat") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ბრტყელი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.overlap?.includes("conical") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>კონუსებური</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.overlap?.includes("tinroofing") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>თუნუქის გადახურვა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.overlap?.includes("corrugatedroofing") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>ტოლით გადახურვა</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.overlap?.includes("tileroof") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>კრამიტის სახურავი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.overlap?.includes("sendvich") ? (
                                  <Col xxl={4}>
                                    <div className="mainListParamBox">
                                      <div className="paramListIcon">
                                        <FontAwesomeIcon icon={faCheckSquare} />
                                      </div>
                                      <div className="paramListText">
                                        <h5>სენდვიჩ პანელი</h5>
                                      </div>
                                    </div>
                                  </Col>
                                ) : (
                                  ""
                                )}

                                {property?.overlap?.includes("beton") ? (<Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon"><FontAwesomeIcon icon={faCheckSquare} /></div>
                                    <div className="paramListText"><h5>ბეტონი</h5></div>
                                  </div>
                                </Col>) : ("")}

                                {property?.overlap?.includes("tagovani") ? (<Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon"><FontAwesomeIcon icon={faCheckSquare} /></div>
                                    <div className="paramListText"><h5>თაღოვანი</h5></div>
                                  </div>
                                </Col>) : ("")}

                                {property?.overlap?.includes("piramid") ? (<Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon"><FontAwesomeIcon icon={faCheckSquare} /></div>
                                    <div className="paramListText"><h5>პირამიდული</h5></div>
                                  </div>
                                </Col>) : ("")}

                                {property?.overlap?.includes("valmur") ? (<Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon"><FontAwesomeIcon icon={faCheckSquare} /></div>
                                    <div className="paramListText"><h5>ვალმური</h5></div>
                                  </div>
                                </Col>) : ("")}

                                {property?.overlap?.includes("gambler") ? (<Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon"><FontAwesomeIcon icon={faCheckSquare} /></div>
                                    <div className="paramListText"><h5>გამბლერის სახურავი</h5></div>
                                  </div>
                                </Col>) : ("")}

                                {property?.overlap?.includes("mansard") ? (<Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon"><FontAwesomeIcon icon={faCheckSquare} /></div>
                                    <div className="paramListText"><h5>მანსარდული</h5></div>
                                  </div>
                                </Col>) : ("")}
                                
                              </Row>
                            </div>
                          ) : (
                            ""
                          )}

                          {property?.fundament ? (
                            <div className="propertyDetailsInner">
                              <h4>ფუნდამენტის ტიპი</h4>

                              <Row>

                                {property?.fundament?.includes("lent") ? (<Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon"><FontAwesomeIcon icon={faCheckSquare} /></div>
                                    <div className="paramListText"><h5>ლენტოვანი</h5></div>
                                  </div>
                                </Col>) : ("")}

                                {property?.fundament?.includes("khiminji") ? (<Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon"><FontAwesomeIcon icon={faCheckSquare} /></div>
                                    <div className="paramListText"><h5>ხიმინჯი</h5></div>
                                  </div>
                                </Col>) : ("")}

                                {property?.fundament?.includes("balishovani") ? (<Col xxl={4}>
                                  <div className="mainListParamBox">
                                    <div className="paramListIcon"><FontAwesomeIcon icon={faCheckSquare} /></div>
                                    <div className="paramListText"><h5>ბალიშოვანი</h5></div>
                                  </div>
                                </Col>) : ("")}
                                
                              </Row>
                            </div>
                          ) : (
                            ""
                          )}
                          
                        </div>
                      </Col>
                    </Row>
                  </div>
                  :
                  ''
                  }

                  {/* კომერციული / საოფისე ფართი */}
                  { property?.property_type == 'comercial' || property?.property_type == 'office' ?
                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>კომერციული / საოფისე ფართის დეტალები</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={12}>
                                <div className="mainParamBox mb-32">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ფართის ტიპი</h5>
                                    <h6>
                                    {typeof property == "object" &&
                                    property?.typeofspace != null
                                    ? getenumbykey(parse(property.typeofspace))
                                    : ""}
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              {property?.showcase == "yes" ? (
                                <Col xxl={12}>
                                  <div className="mainParamBox mb-12">
                                    <div className="paramIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramText">
                                      <h5>ვიტრინა</h5>
                                      <h6>
                                        რაოდენობა: <strong>
                                        {property?.showcaseamount != null
                                        ? property.showcaseamount
                                        : ""}
                                        </strong>
                                      </h6>
                                      <p>
                                        სიგრძე (სმ): <strong>
                                        {property?.showcaselength != null
                                        ? property.showcaselength
                                        : ""}
                                        </strong>
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {property?.facade == "yes" ? (
                                <Col xxl={12}>
                                  <div className="mainParamBox mb-12">
                                    <div className="paramIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramText">
                                      <h5>ფასადი</h5>
                                      <h6>
                                        სიგრძე (სმ): <strong>
                                        {property?.facadelength != null
                                        ? property.facadelength
                                        : ""}
                                        </strong>
                                      </h6>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {property?.facade == "hall2" ? (
                                <Col xxl={12}>
                                  <div className="mainParamBox mb-12">
                                    <div className="paramIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramText">
                                      <h5>დარბაზი</h5>
                                      <h6>
                                        რაოდენობა: <strong>
                                        {property?.hall2amount != null
                                        ? property.hall2amount
                                        : ""}
                                        </strong>
                                      </h6>
                                      <p>
                                        ფართი კვ.მ:
                                        <strong>
                                        {property?.hall2area != null
                                        ? property.hall2area
                                        : ""} მ<sup>2</sup>
                                        </strong>
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  :
                  ''
                  }

                  {/* სასტუმრო */}
                  { property?.property_type == 'hotel' ?
                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>სასტუმროს დეტალები</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>სასტუმროს ტიპი</h5>
                                    <h6>
                                    {typeof property == "object" &&
                                    property?.property_type != null
                                    ? getenumbykey(parse(property.property_type))
                                    : ""}
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ვარსკვლავების რაოდენობა</h5>
                                    <h6>
                                    {typeof property == "object" &&
                                    property?.numberofstars != null
                                    ? getenumbykey(parse(property.numberofstars))
                                    : ""}
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ნომრების რაოდენობა</h5>
                                    <h6>
                                    {property?.roomamount != null
                                    ? property.roomamount
                                    : ""}
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12} className="mb-3">
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ლუქსების რაოდენობა</h5>
                                    <h6>
                                    {property?.luxamount != null
                                    ? property.luxamount
                                    : ""}
                                    </h6>
                                  </div>
                                </div>
                              </Col>
                              
                              {property?.familyhotel == "yes" ? (
                                <Col xxl={4}>
                                  <div className="mainParamBox mb-12">
                                    <div className="paramIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramText">
                                      <h5>საოჯახო სასტუმრო</h5>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {property?.hostel == "yes" ? (
                                <Col xxl={4}>
                                  <div className="mainParamBox mb-12">
                                    <div className="paramIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramText">
                                      <h5>ჰოსტელი</h5>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              
                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>კვება</h5>
                                    <h6>
                                    {typeof property == "object" &&
                                    property?.food != null
                                    ? getenumbykey(parse(property.food))
                                    : ""}
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>აქტივობა</h5>
                                    <h6>
                                    {property?.activity != null
                                    ? property.activity
                                    : ""}
                                    </h6>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  :
                  ''
                  }

                  {/* ბიზნესცენტრი */}
                  { property?.property_type == 'businesscenter' ?
                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>ბიზნესცენტრის დეტალები</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ბიზნესცენტრის კლასი</h5>
                                    <h6>
                                    {typeof property == "object" &&
                                    property?.businesscenterclass != null
                                    ? getenumbykey(parse(property.businesscenterclass))
                                    : ""}
                                    </h6>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  :
                  ''
                  }

                  {/* მიწა */}
                  { property?.property_type == 'land' ?
                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>მიწის დეტალები</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>მიწის ტიპი</h5>
                                    <h6>{typeof property == "object" &&
                                    property?.landtype != null
                                    ? getenumbykey(parse(property.landtype))
                                    : ""}</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ფუნქციური ზონა</h5>
                                    <h6>{typeof property == "object" &&
                                    property?.functionalarea != null
                                    ? getenumbykey(parse(property.functionalarea))
                                    : ""}</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-32">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>სას. სამეურნეო მიწის ტიპი</h5>
                                    <h6>{typeof property == "object" &&
                                    property?.agriculturalland != null
                                    ? getenumbykey(parse(property.agriculturalland))
                                    : ""}</h6>
                                  </div>
                                </div>
                              </Col>
                              
                              {property?.building == "yes" ? (
                                <Col xxl={12}>
                                  <div className="mainParamBox mb-12">
                                    <div className="paramIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div> 
                                    <div className="paramText">
                                      <h5>შენობა ნაგებობა</h5>
                                      <h6>
                                        სრული ფართი კვ.მ
                                        <strong>
                                        {property?.buildingarea != null
                                        ? property.buildingarea
                                        : ""} მ<sup>2</sup>
                                        </strong>
                                      </h6>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              
                              {property?.agreedproject == "yes" ? (
                                <Col xxl={4}>
                                  <div className="mainParamBox mb-12">
                                    <div className="paramIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramText">
                                      <h5>შეთანხმებული პროექტი</h5>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {property?.buildingpermission == "yes" ? (
                                <Col xxl={4}>
                                  <div className="mainParamBox mb-12">
                                    <div className="paramIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramText">
                                      <h5>მშენებლობის ნებართვა</h5>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              
                            </Row>
                            
                              {property?.communications ? (
                                <div className="propertyDetailsInner">
                                  <h4>კომუნიკაციები</h4>
    
                                  <Row>

                                    {property?.communications?.includes("road") ? (
                                      <Col xxl={4}>
                                        <div className="mainListParamBox">
                                          <div className="paramListIcon">
                                            <FontAwesomeIcon icon={faCheckSquare} />
                                          </div>
                                          <div className="paramListText">
                                            <h5>გზა</h5>
                                          </div>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}

                                    {property?.communications?.includes("electricity") ? (
                                      <Col xxl={4}>
                                        <div className="mainListParamBox">
                                          <div className="paramListIcon">
                                            <FontAwesomeIcon icon={faCheckSquare} />
                                          </div>
                                          <div className="paramListText">
                                            <h5>დენი</h5>
                                          </div>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}

                                    {property?.communications?.includes("water") ? (
                                      <Col xxl={4}>
                                        <div className="mainListParamBox">
                                          <div className="paramListIcon">
                                            <FontAwesomeIcon icon={faCheckSquare} />
                                          </div>
                                          <div className="paramListText">
                                            <h5>წყალი</h5>
                                          </div>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}

                                    {property?.communications?.includes("gas") ? (
                                      <Col xxl={4}>
                                        <div className="mainListParamBox">
                                          <div className="paramListIcon">
                                            <FontAwesomeIcon icon={faCheckSquare} />
                                          </div>
                                          <div className="paramListText">
                                            <h5>გაზი</h5>
                                          </div>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}

                                    {property?.communications?.includes("sewercollector") ? (
                                      <Col xxl={4}>
                                        <div className="mainListParamBox">
                                          <div className="paramListIcon">
                                            <FontAwesomeIcon icon={faCheckSquare} />
                                          </div>
                                          <div className="paramListText">
                                            <h5>კოლექტორი-კანალიზაცია</h5>
                                          </div>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}

                                    {property?.communications?.includes("well") ? (
                                      <Col xxl={4}>
                                        <div className="mainListParamBox">
                                          <div className="paramListIcon">
                                            <FontAwesomeIcon icon={faCheckSquare} />
                                          </div>
                                          <div className="paramListText">
                                            <h5>ჭა</h5>
                                          </div>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}

                                    {property?.communications?.includes("easement") ? (
                                      <Col xxl={4}>
                                        <div className="mainListParamBox">
                                          <div className="paramListIcon">
                                            <FontAwesomeIcon icon={faCheckSquare} />
                                          </div>
                                          <div className="paramListText">
                                            <h5>სერვიტუტი</h5>
                                          </div>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}

                                    {property?.communications?.includes("firstrow") ? (
                                      <Col xxl={4}>
                                        <div className="mainListParamBox">
                                          <div className="paramListIcon">
                                            <FontAwesomeIcon icon={faCheckSquare} />
                                          </div>
                                          <div className="paramListText">
                                            <h5>პირველი ზოლი</h5>
                                          </div>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}

                                    {property?.communications?.includes("secondrow") ? (
                                      <Col xxl={4}>
                                        <div className="mainListParamBox">
                                          <div className="paramListIcon">
                                            <FontAwesomeIcon icon={faCheckSquare} />
                                          </div>
                                          <div className="paramListText">
                                            <h5>მეორე ზოლი</h5>
                                          </div>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}

                                    {property?.communications?.includes("thirdrow") ? (
                                      <Col xxl={4}>
                                        <div className="mainListParamBox">
                                          <div className="paramListIcon">
                                            <FontAwesomeIcon icon={faCheckSquare} />
                                          </div>
                                          <div className="paramListText">
                                            <h5>მესამე ზოლი</h5>
                                          </div>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}

                                    {property?.communications?.includes("fence") ? (
                                      <Col xxl={4}>
                                        <div className="mainListParamBox">
                                          <div className="paramListIcon">
                                            <FontAwesomeIcon icon={faCheckSquare} />
                                          </div>
                                          <div className="paramListText">
                                            <h5>ღობე</h5>
                                          </div>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}

                                    {property?.communications?.includes("capitalfence") ? (
                                      <Col xxl={4}>
                                        <div className="mainListParamBox">
                                          <div className="paramListIcon">
                                            <FontAwesomeIcon icon={faCheckSquare} />
                                          </div>
                                          <div className="paramListText">
                                            <h5>კაპიტალური ღობე</h5>
                                          </div>
                                        </div>
                                      </Col>
                                    ) : (
                                      ""
                                    )}
                                    
                                  </Row>
                                </div>
                                ) : (
                                ""
                              )}
                            
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  :
                  ''
                  }

                  {/* ბიზნეს ინვესტიცა */}
                  { property?.property_type == 'business' ?
                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>ბიზნეს ინვესტიციის დეტალები</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ბიზნეს ინვესტიციის კატეგორია</h5>
                                    <h6>{typeof property == "object" &&
                                    property?.businessinvestmentcategory != null
                                    ? getenumbykey(parse(property.businessinvestmentcategory))
                                    : ""}</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>სხვა ინდუსტრია</h5>
                                    <h6>{property?.otherindustry != null
                                        ? property.otherindustry
                                        : ""}</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>მესაკუთრე</h5>
                                    <h6>{typeof property == "object" &&
                                    property?.owner != null
                                    ? getenumbykey(parse(property.owner))
                                    : ""}</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>საიდენტიფიკაციო კოდი</h5>
                                    <h6>{property?.Identificationcode != null
                                        ? property.Identificationcode
                                        : ""}</h6>
                                  </div>
                                </div>
                              </Col>
                              
                              {property?.building1 == "yes" ? (
                                <Col xxl={12}>
                                  <div className="mainParamBox mb-12">
                                    <div className="paramIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramText">
                                      <h5>შენობა ნაგებობა</h5>
                                      <h6>
                                        რაოდენობა: <strong>
                                        {property?.buildingamount1 != null
                                          ? property.buildingamount1
                                          : ""}
                                        </strong>
                                      </h6>
                                      <h6>
                                        სრული ფართი კვ.მ
                                        <strong>
                                        {property?.buildingarea1 != null
                                          ? property.buildingarea1
                                          : ""} მ<sup>2</sup>
                                        </strong>
                                      </h6>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>საკუთრების ტიპი</h5>
                                    <h6>{typeof property == "object" &&
                                    property?.propertytype != null
                                    ? getenumbykey(parse(property.propertytype))
                                    : ""}</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>მიწის ფართი</h5>
                                    <h6>
                                      <strong>
                                        {property?.landarea != null
                                        ? property.landarea
                                        : ""} მ<sup>2</sup>
                                      </strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>თანამშრომლების რაოდენობა</h5>
                                    <h6>
                                      <strong>{property?.numberofemployees != null
                                      ? property.numberofemployees
                                      : ""}</strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>
                              
                              { typeof property == "object" && property?.assets == 'yes' ?
                              <Col xxl={12}>
                                <div className="mainParamBox mb-32">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>აქტივები</h5>
                                    <h6>
                                      <strong>კი</strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>
                              : ""}
                              
                              {property?.heavyequipment == "yes" ? (
                                <Col xxl={4}>
                                  <div className="mainParamBox mb-32">
                                    <div className="paramIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramText">
                                      <h5>მძიმე ტექნიკა</h5>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {property?.specechnique == "yes" ? (
                                <Col xxl={4}>
                                  <div className="mainParamBox mb-32">
                                    <div className="paramIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramText">
                                      <h5>სპეც ტექნიკა</h5>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              
                              {property?.annualaccrual == "yes" ? (
                                <Col xxl={12}>
                                  <div className="mainParamBox mb-12">
                                    <div className="paramIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramText">
                                      <h5>წლიური დარიცხვა</h5>
                                      <h6>
                                        GEL: <strong>
                                        {property?.annualaccrualgel != null
                                        ? property.annualaccrualgel
                                        : ""}
                                        </strong>
                                      </h6>
                                      <h6>
                                        USD: <strong>
                                        {property?.annualaccrualusd != null
                                        ? property.annualaccrualusd
                                        : ""}
                                        </strong>
                                      </h6>
                                      <h6>
                                        RUB: <strong>
                                        {property?.annualaccrualeur != null
                                        ? property.annualaccrualeur
                                        : ""}
                                        </strong>
                                      </h6>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                              {property?.annualnetprofit == "yes" ? (
                                <Col xxl={12}>
                                  <div className="mainParamBox mb-12">
                                    <div className="paramIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramText">
                                      <h5>წლიური წმინდა მოგება</h5>
                                      <h6>
                                        GEL: <strong>
                                        {property?.annualnetprofitgel != null
                                        ? property.annualnetprofitgel
                                        : ""}
                                        </strong>
                                      </h6>
                                      <h6>
                                        USD: <strong>
                                        {property?.annualnetprofitusd != null
                                        ? property.annualnetprofitusd
                                        : ""}
                                        </strong>
                                      </h6>
                                      <h6>
                                        RUB: <strong>
                                        {property?.annualnetprofiteur != null
                                        ? property.annualnetprofiteur
                                        : ""}
                                        </strong>
                                      </h6>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}

                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  :
                  ''
                  }

                  {/* ფერმა */}
                  { property?.property_type == 'farm' ?
                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>ფერმის დეტალები</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ფერმის ტიპი</h5>
                                    <h6>{typeof property == "object" &&
                                      property?.farmtype != null
                                      ? getenumbykey(parse(property.farmtype))
                                      : ""}</h6>
                                  </div>
                                </div>
                              </Col>

                              { typeof property == "object" && property?.farmtype == 'other' ?
                                <Col xxl={12}>
                                  <div className="mainParamBox mb-12">
                                    <div className="paramIcon">
                                      <FontAwesomeIcon icon={faBuilding} />
                                    </div>
                                    <div className="paramText">
                                      <h5>სხვა ფერმის ტიპი</h5>
                                      <h6>{property?.otherfarmtype != null
                                          ? property.otherfarmtype
                                          : ""}</h6>
                                    </div>
                                  </div>
                                </Col>
                              : ""}

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>მიწის ტიპი</h5>
                                    <h6>{typeof property == "object" &&
                                      property?.landtype1 != null
                                      ? getenumbykey(parse(property.landtype1))
                                      : ""}</h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>სას. სამეურნეო მიწის ტიპი</h5>
                                    <h6>{typeof property == "object" &&
                                      property?.agrilandtype != null
                                      ? getenumbykey(parse(property.agrilandtype))
                                      : ""}</h6>
                                  </div>
                                </div>
                              </Col>
                              
                              {property?.buildingstructureoffice == "yes" ? (
                                <Col xxl={12}>
                                  <div className="mainParamBox mb-12">
                                    <div className="paramIcon">
                                      <FontAwesomeIcon icon={faCheckSquare} />
                                    </div>
                                    <div className="paramText">
                                      <h5>შენობა ნაგებობა (ოფისი)</h5>
                                      <h6>
                                        რაოდენობა: <strong>
                                        {property?.buildingstructureofficeamount != null
                                        ? property.buildingstructureofficeamount
                                        : ""}
                                        </strong>
                                      </h6>
                                      <h6>
                                        სრული ფართი კვ.მ
                                        <strong>
                                        {property?.buildingstructureofficearea != null
                                        ? property.buildingstructureofficearea
                                        : ""} მ<sup>2</sup>
                                        </strong>
                                      </h6>
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                ""
                              )}
                              
                            </Row>
                            
                            {property?.communications1 ? (
                              <div className="propertyDetailsInner">
                                <h4>კომუნიკაციები</h4>

                                <Row>

                                  {property?.communications1?.includes("road") ? (
                                    <Col xxl={4}>
                                      <div className="mainListParamBox">
                                        <div className="paramListIcon">
                                          <FontAwesomeIcon icon={faCheckSquare} />
                                        </div>
                                        <div className="paramListText">
                                          <h5>გზა</h5>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                  {property?.communications1?.includes("electricity") ? (
                                    <Col xxl={4}>
                                      <div className="mainListParamBox">
                                        <div className="paramListIcon">
                                          <FontAwesomeIcon icon={faCheckSquare} />
                                        </div>
                                        <div className="paramListText">
                                          <h5>დენი</h5>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}
                                  
                                  {property?.communications1?.includes("water") ? (
                                    <Col xxl={4}>
                                      <div className="mainListParamBox">
                                        <div className="paramListIcon">
                                          <FontAwesomeIcon icon={faCheckSquare} />
                                        </div>
                                        <div className="paramListText">
                                          <h5>წყალი</h5>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                  {property?.communications1?.includes("gas") ? (
                                    <Col xxl={4}>
                                      <div className="mainListParamBox">
                                        <div className="paramListIcon">
                                          <FontAwesomeIcon icon={faCheckSquare} />
                                        </div>
                                        <div className="paramListText">
                                          <h5>გაზი</h5>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                  {property?.communications1?.includes("sewercollector") ? (
                                    <Col xxl={4}>
                                      <div className="mainListParamBox">
                                        <div className="paramListIcon">
                                          <FontAwesomeIcon icon={faCheckSquare} />
                                        </div>
                                        <div className="paramListText">
                                          <h5>კოლექტორი-კანალიზაცია</h5>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                  {property?.communications1?.includes("well") ? (
                                    <Col xxl={4}>
                                      <div className="mainListParamBox">
                                        <div className="paramListIcon">
                                          <FontAwesomeIcon icon={faCheckSquare} />
                                        </div>
                                        <div className="paramListText">
                                          <h5>ჭა</h5>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                  {property?.communications1?.includes("easement") ? (
                                    <Col xxl={4}>
                                      <div className="mainListParamBox">
                                        <div className="paramListIcon">
                                          <FontAwesomeIcon icon={faCheckSquare} />
                                        </div>
                                        <div className="paramListText">
                                          <h5>სერვიტუტი</h5>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                  {property?.communications1?.includes("firstrow") ? (
                                    <Col xxl={4}>
                                      <div className="mainListParamBox">
                                        <div className="paramListIcon">
                                          <FontAwesomeIcon icon={faCheckSquare} />
                                        </div>
                                        <div className="paramListText">
                                          <h5>პირველი ზოლი</h5>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                  {property?.communications1?.includes("secondrow") ? (
                                    <Col xxl={4}>
                                      <div className="mainListParamBox">
                                        <div className="paramListIcon">
                                          <FontAwesomeIcon icon={faCheckSquare} />
                                        </div>
                                        <div className="paramListText">
                                          <h5>მეორე ზოლი</h5>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                  {property?.communications1?.includes("thirdrow") ? (
                                    <Col xxl={4}>
                                      <div className="mainListParamBox">
                                        <div className="paramListIcon">
                                          <FontAwesomeIcon icon={faCheckSquare} />
                                        </div>
                                        <div className="paramListText">
                                          <h5>მესამე ზოლი</h5>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                  {property?.communications1?.includes("fence") ? (
                                    <Col xxl={4}>
                                      <div className="mainListParamBox">
                                        <div className="paramListIcon">
                                          <FontAwesomeIcon icon={faCheckSquare} />
                                        </div>
                                        <div className="paramListText">
                                          <h5>ღობე</h5>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                  {property?.communications1?.includes("owntransformer") ? (
                                    <Col xxl={4}>
                                      <div className="mainListParamBox">
                                        <div className="paramListIcon">
                                          <FontAwesomeIcon icon={faCheckSquare} />
                                        </div>
                                        <div className="paramListText">
                                          <h5>საკუთარი ტრანსფორმატორი</h5>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                  {property?.communications1?.includes("owngenerator") ? (
                                    <Col xxl={4}>
                                      <div className="mainListParamBox">
                                        <div className="paramListIcon">
                                          <FontAwesomeIcon icon={faCheckSquare} />
                                        </div>
                                        <div className="paramListText">
                                          <h5>საკუთარი გენერატორი</h5>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                </Row>
                              </div>
                            ) : (
                              ""
                            )}
                            
                            {property?.additionaldetails ? (
                              <div className="propertyDetailsInner">
                                <h4>დამატებითი დეტალები</h4>

                                <Row>

                                  {property?.additionaldetails?.includes("pumpsystem") ? (
                                    <Col xxl={4}>
                                      <div className="mainListParamBox">
                                        <div className="paramListIcon">
                                          <FontAwesomeIcon icon={faCheckSquare} />
                                        </div>
                                        <div className="paramListText">
                                          <h5>სატუმბი სისტემა</h5>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                  {property?.additionaldetails?.includes("borehole") ? (
                                    <Col xxl={4}>
                                      <div className="mainListParamBox">
                                        <div className="paramListIcon">
                                          <FontAwesomeIcon icon={faCheckSquare} />
                                        </div>
                                        <div className="paramListText">
                                          <h5>ჭაბურღილი</h5>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                  {property?.additionaldetails?.includes("dripirrigationsystem") ? (
                                    <Col xxl={4}>
                                      <div className="mainListParamBox">
                                        <div className="paramListIcon">
                                          <FontAwesomeIcon icon={faCheckSquare} />
                                        </div>
                                        <div className="paramListText">
                                          <h5>წვეთოვანი სარწყავი სისტემა</h5>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                  {property?.additionaldetails?.includes("railwayspur") ? (
                                    <Col xxl={4}>
                                      <div className="mainListParamBox">
                                        <div className="paramListIcon">
                                          <FontAwesomeIcon icon={faCheckSquare} />
                                        </div>
                                        <div className="paramListText">
                                          <h5>რკინიგზის ჩიხი</h5>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                  {property?.additionaldetails?.includes("capitalfence") ? (
                                    <Col xxl={4}>
                                      <div className="mainListParamBox">
                                        <div className="paramListIcon">
                                          <FontAwesomeIcon icon={faCheckSquare} />
                                        </div>
                                        <div className="paramListText">
                                          <h5>კაპიტალური ღობე</h5>
                                        </div>
                                      </div>
                                    </Col>
                                  ) : (
                                    ""
                                  )}

                                </Row>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  :
                  ''
                  }

                  {/* იყიდება განვადებით */}
                  { property?.transaction_type == 'installment' ?
                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>განვადებით გაყიდვის დეტალები</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>პირველადი შესატანი თანხა</h5>
                                    <h6>
                                      <strong>
                                      {typeof property == "object" &&
                                      property?.Initialdepositamount != null
                                      ? property.Initialdepositamount
                                      : ""}
                                      </strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ყოველთვიური შესატანი თანხა</h5>
                                    <h6>
                                      <strong>
                                      {typeof property == "object" &&
                                      property?.monthlydepositamount != null
                                      ? property.monthlydepositamount
                                      : ""}
                                      </strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>საბოლოო თანხა ჯამში</h5>
                                    <h6>
                                      <strong>
                                      {typeof property == "object" &&
                                      property?.finalamounttotal != null
                                      ? property.finalamounttotal
                                      : ""}
                                      </strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>მოქმედების ვადა</h5>
                                    <h6>
                                      <strong>
                                      {typeof property == "object" &&
                                      property?.validityperiod != null
                                      ? property.validityperiod
                                      : ""} <sub>თვე</sub>
                                      </strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  :
                  ''
                  }

                  {/* ქირავდება დღიურად */}
                  { property?.transaction_type == 'dailyrent' ?
                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>დღიურად გაქირავების დეტალები</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>გაქირავების დაწყების დრო</h5>
                                    <h6>
                                      <strong>
                                      {typeof property == "object" &&
                                      property?.rentalstarttime != null
                                      ? property.rentalstarttime
                                      : ""}
                                      </strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>გაქირავების დასრულების დრო</h5>
                                    <h6>
                                      <strong>
                                      {typeof property == "object" &&
                                      property?.rentalendtime != null
                                      ? property.rentalendtime
                                      : ""}
                                      </strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  :
                  ''
                  }

                  {/* იყიდება აუქციონით */}
                  { property?.transaction_type == 'auction' ?
                  <div className="detailsCardBox">
                    <Row>
                      <Col xxl={12}>
                        <div className="propertyDetailsProp">
                          <h3>აუქციონით გაყიდვის დეტალები</h3>

                          <div className="propertyDetailsPropBox">
                            <Row>
                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>აუქციონის ბოლო ვადა</h5>
                                    <h6>
                                      <strong>
                                      {typeof property == "object" &&
                                      property?.auctiondeadline != null
                                      ? property.auctiondeadline
                                      : ""}
                                      </strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>ყოველთვიური შესატანი თანხა</h5>
                                    <h6>
                                      <strong>
                                      {typeof property == "object" &&
                                      property?.monthlydepositamount1 != null
                                      ? property.monthlydepositamount1
                                      : ""}
                                      </strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>

                              <Col xxl={12}>
                                <div className="mainParamBox mb-12">
                                  <div className="paramIcon">
                                    <FontAwesomeIcon icon={faBuilding} />
                                  </div>
                                  <div className="paramText">
                                    <h5>საბოლოო თანხა ჯამში</h5>
                                    <h6>
                                      <strong>
                                      {typeof property == "object" &&
                                      property?.finalamounttotal1 != null
                                      ? property.finalamounttotal1
                                      : ""}
                                      </strong>
                                    </h6>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  :
                  ''
                  }

                  {/* Hidden details end */}
                </div>
              </div>
            </Col>

            <Col xxl={4}>
              <div className="propertyDetailsRight">
                <div className="singleProductPrice">
                  {["GEL", "USD", "EUR"].includes(currentCurrency) && (
                    <>
                      <h4>
                        {property?.[`price_${currentCurrency.toLowerCase()}`] !=
                        null
                          ? property?.[`price_${currentCurrency.toLowerCase()}`]
                          : ""}
                        <span>{currentCurrency}</span>
                      </h4>
                    </>
                  )}
                  <div className="priceBoxDetails">
                    <div
                      className={`singlePropertyPrice ${
                        currentCurrency === "GEL" ? "active" : ""
                      }`}
                      onClick={() => toggleCurrency("GEL")}
                    >
                      <p>GEL</p>
                    </div>
                    <div
                      className={`singlePropertyPrice ${
                        currentCurrency === "USD" ? "active" : ""
                      }`}
                      onClick={() => toggleCurrency("USD")}
                    >
                      <p>USD</p>
                    </div>
                    <div
                      className={`singlePropertyPrice ${
                        currentCurrency === "EUR" ? "active" : ""
                      }`}
                      onClick={() => toggleCurrency("EUR")}
                    >
                      <p>EUR</p>
                    </div>
                  </div>
                </div>
                <div className="oneSquarePrice">
                  <p>
                    1 მ<sup>2</sup> <span>{(property?.[`price_${currentCurrency.toLowerCase()}`] / property?.area).toFixed(0)} {currentCurrency}</span>
                  </p>
                </div>

                <div className="oneSquareArea">
                  <p>
                    ფართი <span>{property?.area != null ? property.area : ""} მ<sup>2</sup></span>
                  </p>
                </div>

                <div className="adAuthor">
                  <Link to="/">
                    <div className="adAuthorImg">
                      <h4>R</h4>
                    </div>
                  </Link>
                  <div className="adAuthorText">
                    <Link to="/">
                      <h5>Relux</h5>
                    </Link>
                    <p>დაგვიკავშირდით</p>
                  </div>
                </div>

                <a href="tel:+995 555 123 456">
                  <div className="adAuthorPhone">
                    <FontAwesomeIcon icon={faMobileScreen} />
                    <div className="phoneNumber">+995 555 123 456</div>
                  </div>
                </a>

                <a href="mailto:info@relux.ge">
                  <div className="adAuthorPhone">
                    <FontAwesomeIcon icon={faEnvelope} />
                    <div className="phoneNumber">info@relux.ge</div>
                  </div>
                </a>
              </div>
            </Col>

            {/* <Col xxl={12}>
              <div className="relatedAds">
                <h6>მსგავსი განცხადებები</h6>
                <Swiper
                  modules={[Navigation, A11y]}
                  spaceBetween={5}
                  slidesPerView={4}
                  navigation
                  scrollbar={{ draggable: true }}
                >
                  <SwiperSlide>
                    <div className="singleProductGridBox">
                      {message && (
                        <div className="addedToFafourites">
                          <h4>{message}</h4>
                        </div>
                      )}
                      <div className="singleProductGridBoxImageBox">
                        <Link to={`/${locale}/property/details/`}>
                          <Swiper
                            modules={[Navigation, A11y]}
                            spaceBetween={20}
                            slidesPerView={1}
                            navigation
                            scrollbar={{ draggable: true }}
                          >
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                          </Swiper>
                        </Link>
                        <div className="singleProductGridTag">
                          <Link to="/">
                            <h4>ექსკლუზიური</h4>
                          </Link>
                        </div>
                        <div
                          className="singleProductGridFavourite"
                          onClick={toggleFavourite}
                        >
                          <FontAwesomeIcon
                            icon={isFavourite ? faHeart : faHeartRegular}
                          />
                        </div>
                        <div className="singleProductGridPrice">
                          <h4>
                            180000 <span>{currentCurrency}</span>
                          </h4>
                          <div className="priceBoxGrid">
                            <div
                              className={`singlePrice ${
                                currentCurrency === "GEL" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("GEL")}
                            >
                              <p>GEL</p>
                            </div>
                            <div
                              className={`singlePrice ${
                                currentCurrency === "USD" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("USD")}
                            >
                              <p>USD</p>
                            </div>
                            <div
                              className={`singlePrice ${
                                currentCurrency === "EUR" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("EUR")}
                            >
                              <p>EUR</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="singleProductGridBoxContant">
                        <div className="propertyGridTags">
                          <div className="singleTag">ბინა</div>
                          <div className="singleTag">თბილისი</div>
                        </div>
                        <Link to={`/${locale}/property/details/`}>
                          <h1>
                            იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს
                          </h1>
                        </Link>
                        <div className="singlePropertyGridLocation">
                          <FontAwesomeIcon icon={faLocationCrosshairs} />
                          <p>ა.წერეთლის გამზირი #137, 0119</p>
                        </div>
                        <div className="singlePropertyListMiniBox">
                          <div
                            className="singlePropertyGridMini"
                            title="საძინებელი"
                          >
                            <FontAwesomeIcon icon={faBed} />: <span>4</span>
                          </div>
                          <div className="singlePropertyGridMini" title="ოთახი">
                            <FontAwesomeIcon icon={faDoorOpen} />
                            <span>3</span>
                          </div>
                          <div
                            className="singlePropertyGridMini"
                            title="სართული"
                          >
                            <FontAwesomeIcon icon={faLayerGroup} />
                            <span>12/14</span>
                          </div>
                          <div className="singlePropertyGridMini" title="ფართი">
                            <FontAwesomeIcon icon={faClone} />
                            <span>
                              120 მ<sup>2</sup>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="singleProductGridBox">
                      {message && (
                        <div className="addedToFafourites">
                          <h4>{message}</h4>
                        </div>
                      )}
                      <div className="singleProductGridBoxImageBox">
                        <Link to={`/${locale}/property/details/`}>
                          <Swiper
                            modules={[Navigation, A11y]}
                            spaceBetween={5}
                            slidesPerView={1}
                            navigation
                            scrollbar={{ draggable: true }}
                          >
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                          </Swiper>
                        </Link>
                        <div className="singleProductGridTag">
                          <Link to="/">
                            <h4>ექსკლუზიური</h4>
                          </Link>
                        </div>
                        <div
                          className="singleProductGridFavourite"
                          onClick={toggleFavourite}
                        >
                          <FontAwesomeIcon
                            icon={isFavourite ? faHeart : faHeartRegular}
                          />
                        </div>
                        <div className="singleProductGridPrice">
                          <h4>
                            180000 <span>{currentCurrency}</span>
                          </h4>
                          <div className="priceBoxGrid">
                            <div
                              className={`singlePrice ${
                                currentCurrency === "GEL" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("GEL")}
                            >
                              <p>GEL</p>
                            </div>
                            <div
                              className={`singlePrice ${
                                currentCurrency === "USD" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("USD")}
                            >
                              <p>USD</p>
                            </div>
                            <div
                              className={`singlePrice ${
                                currentCurrency === "EUR" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("EUR")}
                            >
                              <p>EUR</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="singleProductGridBoxContant">
                        <div className="propertyGridTags">
                          <div className="singleTag">ბინა</div>
                          <div className="singleTag">თბილისი</div>
                        </div>
                        <Link to={`/${locale}/property/details/`}>
                          <h1>
                            იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს
                          </h1>
                        </Link>
                        <div className="singlePropertyGridLocation">
                          <FontAwesomeIcon icon={faLocationCrosshairs} />
                          <p>ა.წერეთლის გამზირი #137, 0119</p>
                        </div>
                        <div className="singlePropertyListMiniBox">
                          <div
                            className="singlePropertyGridMini"
                            title="საძინებელი"
                          >
                            <FontAwesomeIcon icon={faBed} />: <span>4</span>
                          </div>
                          <div className="singlePropertyGridMini" title="ოთახი">
                            <FontAwesomeIcon icon={faDoorOpen} />
                            <span>3</span>
                          </div>
                          <div
                            className="singlePropertyGridMini"
                            title="სართული"
                          >
                            <FontAwesomeIcon icon={faLayerGroup} />
                            <span>12/14</span>
                          </div>
                          <div className="singlePropertyGridMini" title="ფართი">
                            <FontAwesomeIcon icon={faClone} />
                            <span>
                              120 მ<sup>2</sup>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="singleProductGridBox">
                      {message && (
                        <div className="addedToFafourites">
                          <h4>{message}</h4>
                        </div>
                      )}
                      <div className="singleProductGridBoxImageBox">
                        <Link to={`/${locale}/property/details/`}>
                          <Swiper
                            modules={[Navigation, A11y]}
                            spaceBetween={5}
                            slidesPerView={1}
                            navigation
                            scrollbar={{ draggable: true }}
                          >
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                          </Swiper>
                        </Link>
                        <div className="singleProductGridTag">
                          <Link to="/">
                            <h4>ექსკლუზიური</h4>
                          </Link>
                        </div>
                        <div
                          className="singleProductGridFavourite"
                          onClick={toggleFavourite}
                        >
                          <FontAwesomeIcon
                            icon={isFavourite ? faHeart : faHeartRegular}
                          />
                        </div>
                        <div className="singleProductGridPrice">
                          <h4>
                            180000 <span>{currentCurrency}</span>
                          </h4>
                          <div className="priceBoxGrid">
                            <div
                              className={`singlePrice ${
                                currentCurrency === "GEL" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("GEL")}
                            >
                              <p>GEL</p>
                            </div>
                            <div
                              className={`singlePrice ${
                                currentCurrency === "USD" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("USD")}
                            >
                              <p>USD</p>
                            </div>
                            <div
                              className={`singlePrice ${
                                currentCurrency === "EUR" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("EUR")}
                            >
                              <p>EUR</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="singleProductGridBoxContant">
                        <div className="propertyGridTags">
                          <div className="singleTag">ბინა</div>
                          <div className="singleTag">თბილისი</div>
                        </div>
                        <Link to={`/${locale}/property/details/`}>
                          <h1>
                            იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს
                          </h1>
                        </Link>
                        <div className="singlePropertyGridLocation">
                          <FontAwesomeIcon icon={faLocationCrosshairs} />
                          <p>ა.წერეთლის გამზირი #137, 0119</p>
                        </div>
                        <div className="singlePropertyListMiniBox">
                          <div
                            className="singlePropertyGridMini"
                            title="საძინებელი"
                          >
                            <FontAwesomeIcon icon={faBed} />: <span>4</span>
                          </div>
                          <div className="singlePropertyGridMini" title="ოთახი">
                            <FontAwesomeIcon icon={faDoorOpen} />
                            <span>3</span>
                          </div>
                          <div
                            className="singlePropertyGridMini"
                            title="სართული"
                          >
                            <FontAwesomeIcon icon={faLayerGroup} />
                            <span>12/14</span>
                          </div>
                          <div className="singlePropertyGridMini" title="ფართი">
                            <FontAwesomeIcon icon={faClone} />
                            <span>
                              120 მ<sup>2</sup>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  <SwiperSlide>
                    <div className="singleProductGridBox">
                      {message && (
                        <div className="addedToFafourites">
                          <h4>{message}</h4>
                        </div>
                      )}
                      <div className="singleProductGridBoxImageBox">
                        <Link to={`/${locale}/property/details/`}>
                          <Swiper
                            modules={[Navigation, A11y]}
                            spaceBetween={5}
                            slidesPerView={1}
                            navigation
                            scrollbar={{ draggable: true }}
                          >
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                            <SwiperSlide>
                              <div className="singleProductGridBoxImage">
                                <Image
                                  src={Propertyimage}
                                  alt="Property image"
                                />
                              </div>
                            </SwiperSlide>
                          </Swiper>
                        </Link>
                        <div className="singleProductGridTag">
                          <Link to="/">
                            <h4>ექსკლუზიური</h4>
                          </Link>
                        </div>
                        <div
                          className="singleProductGridFavourite"
                          onClick={toggleFavourite}
                        >
                          <FontAwesomeIcon
                            icon={isFavourite ? faHeart : faHeartRegular}
                          />
                        </div>
                        <div className="singleProductGridPrice">
                          <h4>
                            180000 <span>{currentCurrency}</span>
                          </h4>
                          <div className="priceBoxGrid">
                            <div
                              className={`singlePrice ${
                                currentCurrency === "GEL" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("GEL")}
                            >
                              <p>GEL</p>
                            </div>
                            <div
                              className={`singlePrice ${
                                currentCurrency === "USD" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("USD")}
                            >
                              <p>USD</p>
                            </div>
                            <div
                              className={`singlePrice ${
                                currentCurrency === "EUR" ? "active" : ""
                              }`}
                              onClick={() => toggleCurrency("EUR")}
                            >
                              <p>EUR</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="singleProductGridBoxContant">
                        <div className="propertyGridTags">
                          <div className="singleTag">ბინა</div>
                          <div className="singleTag">თბილისი</div>
                        </div>
                        <Link to={`/${locale}/property/details/`}>
                          <h1>
                            იყიდება 5 ოთახიანი კერძო სახლი თბილისთან ახლოს
                          </h1>
                        </Link>
                        <div className="singlePropertyGridLocation">
                          <FontAwesomeIcon icon={faLocationCrosshairs} />
                          <p>ა.წერეთლის გამზირი #137, 0119</p>
                        </div>
                        <div className="singlePropertyListMiniBox">
                          <div
                            className="singlePropertyGridMini"
                            title="საძინებელი"
                          >
                            <FontAwesomeIcon icon={faBed} />: <span>4</span>
                          </div>
                          <div className="singlePropertyGridMini" title="ოთახი">
                            <FontAwesomeIcon icon={faDoorOpen} />
                            <span>3</span>
                          </div>
                          <div
                            className="singlePropertyGridMini"
                            title="სართული"
                          >
                            <FontAwesomeIcon icon={faLayerGroup} />
                            <span>12/14</span>
                          </div>
                          <div className="singlePropertyGridMini" title="ფართი">
                            <FontAwesomeIcon icon={faClone} />
                            <span>
                              120 მ<sup>2</sup>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </Col> */}
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default Detail;
