import React, { createContext, useContext, useEffect, useState } from 'react';

import enumtranslation from './../helper/enum.json'

//api
import { get_user, get_contact } from '../api/Api'


const AuthContext = createContext();

export const useAppContext = () => useContext(AuthContext);

export const ContextProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [updatedUser, setUpdatedUser] = useState(false);
  const [favoritesCount, setFavoritesCount] = useState(null);
  const [globalContact, setGlobalContact] = useState(null);

  useEffect(()=> {
    if(favoritesCount == null) {
      if(localStorage.hasOwnProperty('favorites') && localStorage.getItem('favorites') != '[]') {
        setFavoritesCount(JSON.parse(localStorage.getItem('favorites')).length);
      } else {
          setFavoritesCount(0);
      }
    }
  }, [favoritesCount])

  useEffect(() => {

    if (localStorage.hasOwnProperty('token')) {
      const fetchData = async () => {
        
        const header = {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }

        try {
          get_user(setIsLoggedIn, header);
  
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };


      if (isLoggedIn == null || isLoggedIn == true || updatedUser) {
        fetchData();
        setUpdatedUser(false);
      }
      
    } else {
      setIsLoggedIn(false);
    }

  }, [isLoggedIn, updatedUser]);

  const login = () => {
    setIsLoggedIn(true);
  };

  const updateUserContext = () => {
    setUpdatedUser(true);
  };

  const logout = () => {

    setIsLoggedIn(false);
    
    if(localStorage.hasOwnProperty('token')) {
      localStorage.removeItem('token');
    }
  };


  const getenumbykey = (key) => {
    return enumtranslation[key] || 'Key not found';
  };


  useEffect(()=> {
    const fetchData = async () => {
        
      try {
        get_contact(setGlobalContact);


      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };


    if (globalContact == null) {
      fetchData();
    }
  }, [globalContact])
  

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, updatedUser, updateUserContext, logout, getenumbykey, setFavoritesCount, favoritesCount, globalContact, setGlobalContact }}>
      {children}
    </AuthContext.Provider>
  );
};