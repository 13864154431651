import React, { useState, useRef, useEffect } from 'react';
import { useAppContext } from './../../components/Context';

//import tags
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ThreeDots } from 'react-loader-spinner';
import { toast } from 'react-toastify';

//import resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faList, faPhotoFilm, faPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faHeart as faHeartRegular, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import "react-datepicker/dist/react-datepicker.css";


//import components
import * as Options from '../../components/utils/PropertyOptions';
import { formatDate } from '../../components/utils/Helper'

//api
import { insert_property } from '../../api/Api'


function Add({locale}) {

    //check auth
    const { isLoggedIn } = useAppContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (isLoggedIn == false) {
            MySwal.fire({
                title: <strong>შეცდომა</strong>,
                html: <i>გთხოვთ გაიაროთ ავტორიზაცია</i>,
                icon: "error",
            }).then(() => {
                navigate('/');
            });
        }
    }, [isLoggedIn])

    //sweetalert
    const MySwal = withReactContent(Swal);
    
    //states
    const [images, setImages] = useState(Array(15).fill(null));
    const [inputs, setInputs] = useState([{ id: 1, value: '' }]);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const [activeFilterTab, setActiveFilterTab] = useState(0);
    const [formData, setFormData] = useState({});
    

    const uploadAdImageRefs = useRef([]);

    // Top filter states
    const generalFltr = useRef(null);
    const locationFltr = useRef(null);
    const parametersFltr = useRef(null);
    const amenitiesFltr = useRef(null);
    const advantagesFltr = useRef(null);
    const photosFltr = useRef(null);
    const descriptionFltr = useRef(null);


    // Top main filters
    const filterTabs = [
        { name: 'ძირითადი', ref: generalFltr },
        { name: 'მდებარეობა', ref: locationFltr },
        { name: 'პარამეტრები', ref: parametersFltr },
        { name: 'კეთილმოწყობა', ref: amenitiesFltr },
        { name: 'უპირატესობები', ref: advantagesFltr },
        { name: 'ფოტოები', ref: photosFltr },
        { name: 'აღწერა', ref: descriptionFltr }
    ];

    const handleTabClick = (index, ref) => {
        setActiveFilterTab(index);
        ref.current.scrollIntoView({ behavior: 'smooth' });
    };

    const handleUploadClick = (index) => {
        uploadAdImageRefs.current[index].click();
    };

    const handleImageChange = (e, index) => {
        const newImages = [...images];
        newImages[index] = e.target.files[0];
        setImages(newImages);
    };

    const handleImageDelete = (index) => {
        const newImages = [...images];
        newImages[index] = null;
        setImages(newImages);
    };


    const handleAddInput = () => {
        if(inputs.length >= 5) {
            return;
        }
        const newInput = { id: inputs.length + 1, value: '' };
        setInputs([...inputs, newInput]);
    };

    const handleDeleteInput = (id) => {
        if (inputs.length > 1) {
            const inputName = 'cadaster_'+id;
            const newInputs = inputs.filter(input => input.id !== id);
            setInputs(newInputs);
            const newFormData = { ...formData };
            delete newFormData[inputName];
            setFormData(newFormData);
        }
    };


    //form data

    function handleChangeSelect(selectedOption, actionMeta) {
    
        setFormData(prevData => {
            const { name } = actionMeta; 
            const value = selectedOption ? selectedOption.value : '';

            return {
                ...prevData,
                [name]: value
            };
        });
    
    }


    const handleDateChange = (date, e, name) => {
        const formattedDate = formatDate(date);

        setFormData(prev => ({
            ...prev,
            [name]: formattedDate
        }));
    };


    function handleChangeInput(e) {
        const { name, value, type, checked } = e.target;
    
        // Update formData
        setFormData(prevData => {
            const isArrayField = name.endsWith('[]');
            const key = isArrayField ? name.slice(0, -2) : name; // Normalize the key (remove [])
    
            let newData = {};
    
            if (isArrayField) {
                const currentArray = prevData[key] || []; // Get the current array or initialize it
    
                if (checked) {
                    newData = {
                        ...prevData,
                        [key]: [...currentArray, value]
                    };
                } else {
                    newData = {
                        ...prevData,
                        [key]: currentArray.filter(item => item !== value) // Remove unchecked value
                    };
                }
            } else {
                if (type === "checkbox") {
                    newData = checked
                        ? { ...prevData, [key]: value }
                        : { ...prevData, [key]: undefined };
                } else {
                    newData = {
                        ...prevData,
                        [key]: value
                    };
                }
            }
    
            const cleanedData = Object.fromEntries(
                Object.entries(newData).filter(([key, val]) => {
                    if (Array.isArray(val)) {
                        return val.length > 0;
                    }
                    return val !== '' && val !== undefined && val !== null;
                })
            );
    
            return cleanedData;
        });
    }
    
    


    // Call this function before form submission
    async function submitForm() {
        if (localStorage.getItem('token')) {
            setLoading(true);
    
            const header = {
                'Authorization': 'Bearer ' + localStorage.getItem('token')
            };
    
            let data = new FormData();
    
            // Append formData object properties to FormData instance
            Object.entries(formData).forEach(([key, value]) => {
                data.append(key, value);
            });
    
            // Append images to FormData instance
            images.forEach((image, index) => {
                if (image) {
                    data.append(`image${index + 1}`, image);
                }
            });
    
            try {
                await insert_property(setMessage, data, header);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
                toast.success('განცხადება წარმატებით დაემატა', {
                    position: "bottom-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        } else {
            console.error('Not authorized');
        }
    }
    



    return (
        <div className='main'>
            <section className="productSection">
                <Container>
                    <Row>

                        <Col xxl={12}>
                            <div className="tabsBox">
                                {filterTabs.map((filterTabs, index) => (
                                    <div
                                        key={index}
                                        className={`singleTab ${activeFilterTab === index ? 'active' : ''}`}
                                        onClick={() => handleTabClick(index, filterTabs.ref)}
                                    >
                                        {filterTabs.name}
                                    </div>
                                ))}
                            </div>
                        </Col>

                        <Col xxl={12} ref={generalFltr}>
                            <div className="cardBox">
                                <div className="cardBoxTitle">
                                    <h4>ძირითადი ინფორმაცია</h4>
                                </div>

                                <Row className="cardBoxFormGroup">

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupSelect mb-3">
                                            <label htmlFor='property-type'>უძრავი ქონების ტიპი</label>
                                            <Select
                                                id='property-type'
                                                value={Options.propertyTypeOptions.find(option => option.value === formData?.property_type)}
                                                onChange={handleChangeSelect}
                                                name="property_type"
                                                options={Options.propertyTypeOptions}
                                                placeholder={'აირჩიეთ უძრავი ქონების ტიპი'}
                                            />
                                        </div>
                                    </Col>

                                     <Col xxl={4}>
                                        <div className="form-group customFormGroupSelect mb-3">
                                            <label htmlFor='transaction_type'>გარიგების ტიპი</label>
                                            <Select
                                                id='transaction_type'
                                                value={Options.transactionTypeOptions.find(option => option.value === formData?.transaction_type)}
                                                name="transaction_type"
                                                onChange={handleChangeSelect}
                                                options={Options.transactionTypeOptions}
                                                placeholder={'აირჩიეთ გარიგების ტიპი'}
                                            />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupSelect mb-3">
                                            <label htmlFor='property-status'>უძრავი ქონების სტატუსი</label>
                                            <Select
                                                id='property-status'
                                                value={Options.propertyStatusOptions.find(option => option.value === formData?.property_status)}
                                                name="property_status"
                                                onChange={handleChangeSelect}
                                                options={Options.propertyStatusOptions}
                                                placeholder={'აირჩიეთ უძრავი ქონების სტატუსი'}
                                            />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupSelect mb-3">
                                            <label htmlFor='property-project'>პროექტი</label>
                                            <Select
                                                id='property-project'
                                                value={Options.propertyProjectOptions.find(option => option.value === formData?.project)}
                                                onChange={handleChangeSelect}
                                                name="project"
                                                options={Options.propertyProjectOptions}
                                                placeholder={'აირჩიეთ პროექტი'}
                                            />
                                        </div>
                                    </Col>

                                    {
                                        formData?.property_status == 'new' || formData?.property_status == 'underconstruction' ? 
                                        <>
                                        <Col xxl={4}>
                                            <div className="form-group customFormGroupSelect mb-3">
                                                <label htmlFor='property-project-class'>პროექტის კლასი</label>
                                                <Select
                                                    id='property-project-class'
                                                    value={Options.propertyProjectClassOptions.find(option => option.value === formData?.class)}
                                                    onChange={handleChangeSelect}
                                                    name="class"
                                                    options={Options.propertyProjectClassOptions}
                                                    placeholder={'აირჩიეთ პროექტის კლასი'}
                                                />
                                            </div>
                                        </Col>

                                        <Col xxl={4}>
                                            <div className="form-group customFormGroupInput mb-3">
                                                <label htmlFor='development-company'>სამშენებლო კომპანიის დასახელება</label>
                                                <input type='text' onChange={(e)=>handleChangeInput(e)} name='building_company' id='development-company' placeholder='ჩაწერეთ სამშენებლო კომპანიის დასახელება' />
                                            </div>
                                        </Col>
                                        </>
                                        :
                                        ''
                                    }

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='build-date'>აშენების თარიღი</label>
                                            <input type='text' onChange={(e)=>handleChangeInput(e)} name='build_date' id='build-date' placeholder='ჩაწერეთ აშენების თარიღი' />
                                        </div>
                                    </Col>

                                    <Col xxl={2}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='start_date'>დაწყების თარიღი</label>
                                            <div className="dateBox">
                                                <DatePicker
                                                    selected={formData?.start_date}
                                                    onChange={(date, e) => handleDateChange(date, e, "start_date")}
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xxl={2}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='build-datet'>დასრულების თარიღი</label>
                                            <div className="end_date">
                                                <DatePicker
                                                    selected={formData?.end_date}
                                                    onChange={(date, e) => handleDateChange(date, e, "end_date")}
                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupSelect mb-3">
                                            <label htmlFor='property-condition'>მდგომარეობა</label>
                                            <Select
                                                id='property-condition'
                                                value={Options.propertyConditionOptions.find(option => option.value === formData?.condition)}
                                                onChange={handleChangeSelect}
                                                name="condition"
                                                options={Options.propertyConditionOptions}
                                                placeholder={'აირჩიეთ მდგომარეობა'}
                                            />
                                        </div>
                                    </Col>

                                    <Col xxl={12} className="mt-1">
                                        <Row>
                                            <Col xxl={12} className="mb-3 mt-3">
                                                <div className="subTitle">ფასი</div>
                                            </Col>
                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='price_gel'>ფასი GEL</label>
                                                    <input type='text' onChange={(e)=>handleChangeInput(e)} name='price_gel' id='price_gel' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='address'>ფასი USD</label>
                                                    <input type='text' onChange={(e)=>handleChangeInput(e)} name='price_usd' id='price_usd' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='price_eur'>ფასი EUR</label>
                                                    <input type='text' onChange={(e)=>handleChangeInput(e)} name='price_eur' id='price_eur' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                        </Row>

                                    </Col>

                                </Row>
                            </div>
                        </Col>



                        <Col xxl={12} ref={locationFltr}>
                            <div className="cardBox">
                                <div className="cardBoxTitle">
                                    <h4>მდებარეობა</h4>
                                </div>

                                <Row className="cardBoxFormGroup">

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupSelect mb-3">
                                            <label htmlFor='city'>ქალაქი</label>
                                            <Select
                                                id='city'
                                                value={Options.cityOptions.find(option => option.value === formData?.city)}
                                                onChange={handleChangeSelect}
                                                name='city'
                                                options={Options.cityOptions}
                                                placeholder={'აირჩიეთ ქალაქი'}
                                            />
                                        </div>
                                    </Col>
                                    
                                    {
                                        formData?.city == 'tbilisi' ? 
                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupSelect mb-3">
                                                    <label htmlFor='destrict'>უბანი</label>
                                                    <Select
                                                        id='destrict'
                                                        value={Options.destrictOptions.find(option => option.value === formData?.destrict)}
                                                        onChange={handleChangeSelect}
                                                        name='destrict'
                                                        options={Options.destrictOptions}
                                                        placeholder={'აირჩიეთ უბანი'}
                                                    />
                                                </div>
                                            </Col>
                                            :
                                            ''
                                    }
                                    

                                    {
                                        formData?.city != 'tbilisi' && formData?.city != 'batumi' && formData?.city != 'kutaisi' && formData?.city != 'rustavi' ?
                                        <>
                                        <Col xxl={4}>
                                            <div className="form-group customFormGroupSelect mb-3">
                                                <label htmlFor='region'>რეგიონი</label>
                                                <Select
                                                    id='region'
                                                    value={Options.districtOptions.find(option => option.value === formData?.region)}
                                                    onChange={handleChangeSelect}
                                                    name='region'
                                                    options={Options.districtOptions}
                                                    placeholder={'აირჩიეთ რეგიონი'}
                                                />
                                            </div>
                                        </Col>

                                        <Col xxl={4}>
                                            <div className="form-group customFormGroupSelect mb-3">
                                                <label htmlFor='municipality'>მუნიციპალიტეტი</label>
                                                <Select
                                                    id='municipality'
                                                    value={Options.municipalOptions.find(option => option.value === formData?.municipality)}
                                                    onChange={handleChangeSelect}
                                                    name='municipality'
                                                    options={Options.municipalOptions}
                                                    placeholder={'აირჩიეთ მუნიციპალიტეტი'}
                                                />
                                            </div>
                                        </Col>

                                        <Col xxl={4}>
                                            <div className="form-group customFormGroupInput mb-3">
                                                <label htmlFor='village'>სოფელი-დაბა</label>
                                                <input type='text' onChange={(e)=>handleChangeInput(e)} name='village' id='village' placeholder='ჩაწერეთ სოფელი-დაბა' />
                                            </div>
                                        </Col>
                                        </>
                                        :
                                        ''
                                    }

                                    <Col xxl={8}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='address'>ქუჩის მისამართი</label>
                                            <input type='text' onChange={(e)=>handleChangeInput(e)} name='address' id='address' placeholder='ჩაწერეთ მისამართი' />
                                        </div>
                                    </Col>

                                    <Col xxl={12}>
                                        {inputs.map((input, index) => (
                                            <div className="form-group customFormGroupInput mb-3" key={input.id}>
                                                <label htmlFor={`cadaster_${input.id}`}>საკადასტრო კოდი {input.id}</label>
                                                <input
                                                    type='text'
                                                    name={`cadaster_${input.id}`}
                                                    id={`cadaster_${input.id}`}
                                                    placeholder='ჩაწერეთ საკადასტრო კოდი'
                                                    onChange={(e)=>handleChangeInput(e)}
                                                />
                                                {inputs.length > 1 && (
                                                    <button type="button" className="deleteCustomField" onClick={() => handleDeleteInput(input.id)}>Delete</button>
                                                )}
                                            </div>
                                        ))}
                                        {inputs.length < 5 && (
                                            <div className="cadasterAddBox">
                                                <button type="button" className="addCustomField" onClick={handleAddInput}>+ საკადასტრო კოდის დამატება</button>
                                            </div>
                                        )}
                                        
                                    </Col>



                                </Row>
                            </div>
                        </Col>


                        <Col xxl={12} ref={parametersFltr}>
                            <div className="cardBox">
                                <div className="cardBoxTitle">
                                    <h4>პარამეტრები</h4>
                                </div>

                                <Row className="cardBoxFormGroup">

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='area'>სრული ფართი (მ<sup>2</sup>)</label>
                                            <input type='number' onChange={(e)=>handleChangeInput(e)} name='area' id='area' placeholder='სრული ფართი' />
                                        </div>
                                    </Col>

                                    <Col xxl={8}>
                                        &nbsp;
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='rooms'>ოთახების რაოდენობა</label>
                                            <input type='number' onChange={(e)=>handleChangeInput(e)} name='rooms' id='rooms' placeholder='რაოდენობა' />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='bedrooms'>საძინებლების რაოდენობა</label>
                                            <input type='number' onChange={(e)=>handleChangeInput(e)} name='bedrooms' id='bedrooms' placeholder='რაოდენობა' />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='masterbedrooms'>მასტერ საძინებლების რაოდენობა</label>
                                            <input type='number' onChange={(e)=>handleChangeInput(e)} name='masterbedrooms' id='masterbedrooms' placeholder='რაოდენობა' />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='floor'>სართული</label>
                                            <input type='number' onChange={(e)=>handleChangeInput(e)} name='floor' id='floor' placeholder='სართული' />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='allfloor'>სართული სულ</label>
                                            <input type='number' onChange={(e)=>handleChangeInput(e)} name='allfloor' id='allfloor' placeholder='სართული სულ' />
                                        </div>
                                    </Col>

                                    <Col xxl={4}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <label htmlFor='bathrooms'>სველი წერტილების რაოდენობა</label>
                                            <input type='number' onChange={(e)=>handleChangeInput(e)} name='bathrooms' id='bathrooms' placeholder='რაოდენობა' />
                                        </div>
                                    </Col>

                                    <Col xxl={12} className="mb-3 mt-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="livingroom" /><span className="customCheckboxBox"></span><strong>მისაღები</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.livingroom ? 
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='livingspace' id='livingspace' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='livingamount' id='livingamount' placeholder='რაოდენობა' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :

                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="studio" /><span className="customCheckboxBox"></span><strong>სტუდიო</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.studio ?
                                                    <Col xxl={3}>
                                                        <div className="form-group customFormGroupInput">
                                                            <input type='number' onChange={(e)=>handleChangeInput(e)} name='studiospace' id='studiospace' placeholder='ფართი კვ.მ' />
                                                        </div>
                                                    </Col>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="kitchen" /><span className="customCheckboxBox"></span><strong>სამზარეულო</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.kitchen ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='kitchenspace' id='kitchenspace' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='kitchenamount' id='kitchenamount' placeholder='რაოდენობა' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="openbalcony" /><span className="customCheckboxBox"></span><strong>ღია აივანი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.openbalcony ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='openbalconyspace' id='openbalconyspace' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='openbalconyamount' id='openbalconyamount' placeholder='რაოდენობა' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="closedbalcony" /><span className="customCheckboxBox"></span><strong>დახურული აივანი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.closedbalcony ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='closedbalconyspace' id='closedbalconyspace' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='closedbalconyamount' id='closedbalconyamount' placeholder='რაოდენობა' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="frenchbalcony" /><span className="customCheckboxBox"></span><strong>ფრანგული აივანი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.frenchbalcony ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='frenchbalconyspace' id='frenchbalconyspace' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='frenchbalconyamount' id='frenchbalconyamount' placeholder='რაოდენობა' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="terrace" /><span className="customCheckboxBox"></span><strong>ვერანდა/ტერასა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.terrace ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='terracespace' id='terracespace' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='terraceamount' id='terraceamount' placeholder='რაოდენობა' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="dressingroom" /><span className="customCheckboxBox"></span><strong>საგარდერობო ოთახი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.dressingroom ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='dressingroomspace' id='dressingroomspace' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='dressingroomamount' id='dressingroomamount' placeholder='რაოდენობა' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="storage" /><span className="customCheckboxBox"></span><strong>სათავსო</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.storage ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='storagespace' id='storagespace' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='storageamount' id='storageamount' placeholder='რაოდენობა' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="loft" /><span className="customCheckboxBox"></span><strong>სხვენი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.loft ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='loftspace' id='loftspace' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="duplex" /><span className="customCheckboxBox"></span><strong>დუპლექსი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.duplex ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='duplexspace' id='duplexspace' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="triplex" /><span className="customCheckboxBox"></span><strong>ტრიპლექსი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.triplex ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='triplexspace' id='triplexspace' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }

                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="summerkitchen" /><span className="customCheckboxBox"></span><strong>საზაფხულო სამზარეულო</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.summerkitchen ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='summerkitchenspace' id='summerkitchenspace' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="basement" /><span className="customCheckboxBox"></span><strong>სარდაფი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.basement ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='basementspace' id='basementspace' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='basementamount' id='basementamount' placeholder='რაოდენობა' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="loggia" /><span className="customCheckboxBox"></span><strong>ლოჯი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.loggia ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='loggiaspace' id='loggiaspace' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="hall" /><span className="customCheckboxBox"></span><strong>ჰოლი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.hall ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='hallspace' id='hallspace' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="buildup" /><span className="customCheckboxBox"></span><strong>დაშენება</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.buildup ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='buildupspace' id='buildupspace' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="buildup1" /><span className="customCheckboxBox"></span><strong>მიშენება</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.buildup1 ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='buildupspace1' id='buildupspace1' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="commoncorridor" /><span className="customCheckboxBox"></span><strong>საერთო კორიდორი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.commoncorridor ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='commoncorridorspace' id='commoncorridorspace' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="antresor" /><span className="customCheckboxBox"></span><strong>ანტრესორი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.antresor ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='antresorspace' id='antresorspace' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="wintergarden" /><span className="customCheckboxBox"></span><strong>ზამთრის ბაღი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.wintergarden ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='wintergardenspace' id='wintergardenspace' placeholder='ფართი კვ.მ' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="garage" /><span className="customCheckboxBox"></span><strong>ავტოფარეხი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.garage ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='garageamount' id='garageamount' placeholder='რაოდენობა' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="yardparking" /><span className="customCheckboxBox"></span><strong>ეზოს პარკინგი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.yardparking ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='yardparkingamount' id='yardparkingamount' placeholder='რაოდენობა' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="undergroundparking" /><span className="customCheckboxBox"></span><strong>მიწისქვეშა პარკინგი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.undergroundparking ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='undergroundparkingamount' id='undergroundparkingamount' placeholder='რაოდენობა' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="parkingspace" /><span className="customCheckboxBox"></span><strong>პარკინგის ადგილი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.parkingspace ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='parkingspaceamount' id='parkingspaceamount' placeholder='რაოდენობა' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="paidparking" /><span className="customCheckboxBox"></span><strong>ფასიანი პარკინგი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.paidparking ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='paidparkingamount' id='paidparkingamount' placeholder='რაოდენობა' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                </Row>
                            </div>
                        </Col>


                        <Col xxl={12} ref={amenitiesFltr}>
                            <div className="cardBox">
                                <div className="cardBoxTitle">
                                    <h4>კეთილმოწყობა</h4>
                                </div>

                                <Row className="cardBoxFormGroup">

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="elevator" /><span className="customCheckboxBox"></span><strong>სამგზავრო ლიფტი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.paidparking ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='elevatoramount' id='elevatoramount' placeholder='რაოდენობა' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="freightelevator" /><span className="customCheckboxBox"></span><strong>სატვირთო ლიფტი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.freightelevator ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='freightelevatoramount' id='freightelevatoramount' placeholder='რაოდენობა' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-4 mt-3">
                                        <Row>
                                            <Col xxl={3}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="personalelevator" /><span className="customCheckboxBox"></span><strong>პერსონალური ლიფტი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="outdoorpool" /><span className="customCheckboxBox"></span><strong>ღია აუზი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={3}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="Indoorpool" /><span className="customCheckboxBox"></span><strong>დახურული აუზი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-3">
                                        <Row>
                                            <Col xxl={2}>
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="fireplace" /><span className="customCheckboxBox"></span><strong>ბუხარი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            {
                                                formData?.fireplace ?
                                                    <>
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='fireplaceamount' id='fireplaceamount' placeholder='რაოდენობა' />
                                                            </div>
                                                        </Col>
                                                    </>
                                                :
                                                ''
                                            }
                                            
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-4 mt-3">
                                        <Row>
                                            <Col xxl={12} className="mb-3 mt-3">
                                                <div className="subTitle">ცხელი წყალი</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="centralheating" onChange={(e)=>handleChangeInput(e)}  name="hotwater[]" /><span className="customCheckboxBox"></span><strong>ცენტრალური გათბობის სისტემა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={8} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="gasheater" onChange={(e)=>handleChangeInput(e)}  name="hotwater[]" /><span className="customCheckboxBox"></span><strong>გაზის გამაცხელებელი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="tank" onChange={(e)=>handleChangeInput(e)}  name="hotwater[]" /><span className="customCheckboxBox"></span><strong>ავზი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={8} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="electricheater" onChange={(e)=>handleChangeInput(e)}  name="hotwater[]" /><span className="customCheckboxBox"></span><strong>დენის გამაცხელებელი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="naturalhotwater" onChange={(e)=>handleChangeInput(e)}  name="hotwater[]" /><span className="customCheckboxBox"></span><strong>ბუნებრივი ცხელი წყალი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={8} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="solarheater" onChange={(e)=>handleChangeInput(e)}  name="hotwater[]" /><span className="customCheckboxBox"></span><strong>მზის გამათბობელი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>

                                    </Col>

                                    <Col xxl={12} className="mb-4 mt-3">
                                        <Row>
                                            <Col xxl={12} className="mb-3 mt-3">
                                                <div className="subTitle">გათბობა</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="centralheating" onChange={(e)=>handleChangeInput(e)}  name="heating[]" /><span className="customCheckboxBox"></span><strong>ცენტრალური გათბობა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={8} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="gasheater" onChange={(e)=>handleChangeInput(e)}  name="heating[]" /><span className="customCheckboxBox"></span><strong>გაზის გამათბობელი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="floorheating" onChange={(e)=>handleChangeInput(e)}  name="heating[]" /><span className="customCheckboxBox"></span><strong>იატაკის გათბობა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={8} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="powerheater" onChange={(e)=>handleChangeInput(e)}  name="heating[]" /><span className="customCheckboxBox"></span><strong>დენის გამათბობელი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="chiller" onChange={(e)=>handleChangeInput(e)}  name="heating[]" /><span className="customCheckboxBox"></span><strong>ჩილერი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={8} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="solarheater" onChange={(e)=>handleChangeInput(e)}  name="heating[]" /><span className="customCheckboxBox"></span><strong>მზის გამათბობელი</strong>
                                                    </label>
                                                </div>
                                            </Col>

                                        </Row>

                                    </Col>

                                    <Col xxl={12} className="mb-4 mt-3">
                                        <Row>
                                            <Col xxl={12} className="mb-3 mt-3">
                                                <div className="subTitle">ზოგადი კეთილმოწყობა</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="naturalgas" onChange={(e)=>handleChangeInput(e)}  name="generalamenities[]" /><span className="customCheckboxBox"></span><strong>ბუნებრივი აირი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="generator" onChange={(e)=>handleChangeInput(e)}  name="generalamenities[]" /><span className="customCheckboxBox"></span><strong>გენერატორი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="internet" onChange={(e)=>handleChangeInput(e)}  name="generalamenities[]" /><span className="customCheckboxBox"></span><strong>ინტერნეტი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="television" onChange={(e)=>handleChangeInput(e)}  name="generalamenities[]" /><span className="customCheckboxBox"></span><strong>ტელევიზია</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="alarmsystem" onChange={(e)=>handleChangeInput(e)}  name="generalamenities[]" /><span className="customCheckboxBox"></span><strong>სიგნალიზაცია</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="satelit" onChange={(e)=>handleChangeInput(e)}  name="generalamenities[]" /><span className="customCheckboxBox"></span><strong>სატელიტური თეფში</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="solarPanel" onChange={(e)=>handleChangeInput(e)}  name="generalamenities[]" /><span className="customCheckboxBox"></span><strong>მზის ენერგიის პანელი</strong>
                                                    </label>
                                                </div>
                                            </Col>


                                        </Row>

                                    </Col>

                                    <Col xxl={12} className="mb-4 mt-3">
                                        <Row>
                                            <Col xxl={12} className="mb-3 mt-3">
                                                <div className="subTitle">ფასადი</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="galesili" onChange={(e)=>handleChangeInput(e)}  name="facade1[]" /><span className="customCheckboxBox"></span><strong>გალესილი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="facadePanel" onChange={(e)=>handleChangeInput(e)}  name="facade1[]" /><span className="customCheckboxBox"></span><strong>ფასადის პანელი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="aguri" onChange={(e)=>handleChangeInput(e)}  name="facade1[]" /><span className="customCheckboxBox"></span><strong>აგური</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="traventili" onChange={(e)=>handleChangeInput(e)}  name="facade1[]" /><span className="customCheckboxBox"></span><strong>ტრავენტილი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="xisPaneli" onChange={(e)=>handleChangeInput(e)}  name="facade1[]" /><span className="customCheckboxBox"></span><strong>ხის პანელი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="hplPanel" onChange={(e)=>handleChangeInput(e)}  name="facade1[]" /><span className="customCheckboxBox"></span><strong>HPL პანელი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="combined" onChange={(e)=>handleChangeInput(e)}  name="facade1[]" /><span className="customCheckboxBox"></span><strong>კომბინირებული</strong>
                                                    </label>
                                                </div>
                                            </Col>


                                        </Row>

                                    </Col>

                                    <Col xxl={12} className="mb-4 mt-3">
                                        <Row>
                                            <Col xxl={12} className="mb-3 mt-3">
                                                <div className="subTitle">ავეჯი</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="livingroomfurniture" onChange={(e)=>handleChangeInput(e)}  name="furniture[]" /><span className="customCheckboxBox"></span><strong>მისაღები ოთახის ავეჯი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="softfurniture" onChange={(e)=>handleChangeInput(e)}  name="furniture[]" /><span className="customCheckboxBox"></span><strong>რბილი ავეჯი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="kitchenfurniture" onChange={(e)=>handleChangeInput(e)}  name="furniture[]" /><span className="customCheckboxBox"></span><strong>სამზარეულოს ავეჯი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="bedroomfurniture" onChange={(e)=>handleChangeInput(e)}  name="furniture[]" /><span className="customCheckboxBox"></span><strong>საძინებლის ავეჯი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="summerfurniture" onChange={(e)=>handleChangeInput(e)}  name="furniture[]" /><span className="customCheckboxBox"></span><strong>საზაფხულო ავეჯი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="officefurniture" onChange={(e)=>handleChangeInput(e)}  name="furniture[]" /><span className="customCheckboxBox"></span><strong>საოფისე ავეჯი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="childrensfurniture" onChange={(e)=>handleChangeInput(e)}  name="furniture[]" /><span className="customCheckboxBox"></span><strong>საბავშვო ავეჯი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="partiallyfurnished" onChange={(e)=>handleChangeInput(e)}  name="furniture[]" /><span className="customCheckboxBox"></span><strong>ნაწილობრივ ავეჯი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="unfurnished" onChange={(e)=>handleChangeInput(e)}  name="furniture[]" /><span className="customCheckboxBox"></span><strong>ავეჯის გარეშე</strong>
                                                    </label>
                                                </div>
                                            </Col>


                                        </Row>

                                    </Col>

                                    <Col xxl={12} className="mb-4 mt-3">
                                        <Row>
                                            <Col xxl={12} className="mb-3 mt-3">
                                                <div className="subTitle">ტექნიკა</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="television" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>ტელევიზორი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="refrigerator" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>მაცივარი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="microwaveoven" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>მიკროტალღური ღუმელი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="dishwasher" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>ჭურჭლის სარეცხი მანქანა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="washingmachine" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>სარეცხი მანქანა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="airconditioner" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>კონდინციონერი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="stereosystem" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>მუსიკალური ცენტრი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="rangehood" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>გამწოვი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="stove" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>გაზქურა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="vacuumcleaner" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>მტვერსასრუტი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="iron" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>უთო</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="homecinema" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>კინოთეატრი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="dryer" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>საშრობი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="coffeemaker" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>ყავის აპარატი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="electrickettle" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>ელექტრო ჩაიდანი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="toaster" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>ტოსტერი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="juicer" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>წვენსაწური</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="mixer" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>მიქსერი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="blender" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>ბლენდერი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="meatgrinder" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>ხორცის საკეპი მანქანა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="computer" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>კომპიუტერი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="printerscannerxerox" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>პრინტერი / სკანერი/ ქსეროქსი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="safe" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>სეიფი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="fitnessequipment" onChange={(e)=>handleChangeInput(e)}  name="technic[]" /><span className="customCheckboxBox"></span><strong>სპორტული ტრენაჟორები</strong>
                                                    </label>
                                                </div>
                                            </Col>


                                        </Row>

                                    </Col>


                                </Row>
                            </div>
                        </Col>

                        <Col xxl={12} ref={advantagesFltr}>
                            <div className="cardBox">
                                <div className="cardBoxTitle">
                                    <h4>უპირატესობები</h4>
                                </div>

                                <Row className="cardBoxFormGroup">

                                    <Col xxl={12} className="mb-4">
                                        <Row>
                                            <Col xxl={12} className="mb-3">
                                                <div className="subTitle">დაცვის სერვისი</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="alarmsystem" onChange={(e)=>handleChangeInput(e)}  name="security[]" /><span className="customCheckboxBox"></span><strong>სიგნალიზაცია</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="livesecurity" onChange={(e)=>handleChangeInput(e)}  name="security[]" /><span className="customCheckboxBox"></span><strong>ცოცხალი დაცვა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="cctvcameras" onChange={(e)=>handleChangeInput(e)}  name="security[]" /><span className="customCheckboxBox"></span><strong>ვიდეო კამერები</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="entranceaccesssystem" onChange={(e)=>handleChangeInput(e)}  name="security[]" /><span className="customCheckboxBox"></span><strong>სადარბაზოს დაშვების სისტემა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="gatedaccesssystem" onChange={(e)=>handleChangeInput(e)}  name="security[]" /><span className="customCheckboxBox"></span><strong>ჩაკეტილი ეზოს დაშვების სისტემა</strong>
                                                    </label>
                                                </div>
                                            </Col>


                                        </Row>

                                    </Col>

                                    <Col xxl={12} className="mb-4 mt-3">
                                        <Row>
                                            <Col xxl={12} className="mb-3 mt-3">
                                                <div className="subTitle">ჭკვიანი სახლი</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="controlpanels" onChange={(e)=>handleChangeInput(e)}  name="smarthouse[]" /><span className="customCheckboxBox"></span><strong>მართვის პანელები</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="securitysystems" onChange={(e)=>handleChangeInput(e)}  name="smarthouse[]" /><span className="customCheckboxBox"></span><strong>უსაფრთხოების სისტემები</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="smartcamera" onChange={(e)=>handleChangeInput(e)}  name="smarthouse[]" /><span className="customCheckboxBox"></span><strong>ჭკვიანი კამერა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="curtainblind" onChange={(e)=>handleChangeInput(e)}  name="smarthouse[]" /><span className="customCheckboxBox"></span><strong>ფარდა ჟალუზი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="lightingautomation" onChange={(e)=>handleChangeInput(e)}  name="smarthouse[]" /><span className="customCheckboxBox"></span><strong>განათების ავტომატიზირება</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="smartlock" onChange={(e)=>handleChangeInput(e)}  name="smarthouse[]" /><span className="customCheckboxBox"></span><strong>ჭკვიანი საკეტი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="householdappliancecontrol" onChange={(e)=>handleChangeInput(e)}  name="smarthouse[]" /><span className="customCheckboxBox"></span><strong>საყოფაცხოვრებო ტექნიკის მართვა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="audiosystem" onChange={(e)=>handleChangeInput(e)}  name="smarthouse[]" /><span className="customCheckboxBox"></span><strong>აუდიო სისტემა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="smartintercom" onChange={(e)=>handleChangeInput(e)}  name="smarthouse[]" /><span className="customCheckboxBox"></span><strong>ჭკვიანი დომოფონი</strong>
                                                    </label>
                                                </div>
                                            </Col>


                                        </Row>

                                    </Col>


                                    <Col xxl={12} className="mb-4">
                                        <Row>
                                            <Col xxl={12} className="mb-3">
                                                <div className="subTitle">დამატებითი უპირატესობები</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="organizedinfrastructure" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>მოწესრიგებული ინფრასტრუქტურა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="panoramicview" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>პანორამული ხედი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="cityview" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>ხედი ქალაქზე</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="seaview" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>ხედი ზღვაზე</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="mountainview" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>ხედი მთაზე</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="riverview" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>ხედი მდინარეზე</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="lakeview" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>ხედი ტბაზე</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="courtyardview" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>ხედი ეზოში</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="bright" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>ნათელი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="stainedglasswindowdoor" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>ვიტრაჟული კარ-ფანჯარა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="landscaping" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>გამწვანება</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="childrenspace" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>საბავშვო სივრცე</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="sportsfield" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>სპორტული მოედანი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="concierge" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>კონსიაჟი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="coworkingspace" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>საერთო სამუშაო სივრცე</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="businesslobby" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>ბიზნეს ლობი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="cafeteria" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>კაფეტერია</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="restaurant" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>რესტორანი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="conferencehall" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>საკონფერენციო დარბაზი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="eventhall" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>ივენთ ჰოლი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="commercialspace" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>კომერციული ფართი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="residentiallobby" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>საცხოივრებელი ლობი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="freewifi" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>უფასო Wifi</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="laundry" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>სამრეცხაო</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="spacenter" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>სპა ცენტრი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="sauna" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>საუნა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="fitnessroom" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>ფიტნეს დარბაზი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="tenniscourts" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>კორტები</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="golfcourse" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>გოლფის მოედანი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="multifunctional" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>მულტიფუნქციური</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="energyefficient" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>ენერგო ეფექტური</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="modernarchitecture" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>თანამედროვე არქიტექტურა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="glazedbalconies" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>შემინული აივნები</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="jacuzzi" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>ჯაკუზი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="showercabinplusbathtub" onChange={(e)=>handleChangeInput(e)}  name="additionaladvantages[]" /><span className="customCheckboxBox"></span><strong>დუშ კაბინა + ვანა</strong>
                                                    </label>
                                                </div>
                                            </Col>


                                        </Row>

                                    </Col>


                                    <Col xxl={12} className="mb-4">
                                        <Row>
                                            <Col xxl={12} className="mb-3">
                                                <div className="subTitle">დამატებითი უსაფრთხოება</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="evacuationstaircase" onChange={(e)=>handleChangeInput(e)}  name="additionalsecurity[]" /><span className="customCheckboxBox"></span><strong>საევაკუაციო კიბე</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="firesafetyssystem" onChange={(e)=>handleChangeInput(e)}  name="additionalsecurity[]" /><span className="customCheckboxBox"></span><strong>ხანძრის საწინააღმდეგო სისტემა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="codetector" onChange={(e)=>handleChangeInput(e)}  name="additionalsecurity[]" /><span className="customCheckboxBox"></span><strong>CO დეტექტორი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="smokedetector" onChange={(e)=>handleChangeInput(e)}  name="additionalsecurity[]" /><span className="customCheckboxBox"></span><strong>კვამლის დეტექტორი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>


                                    <Col xxl={12} className="mb-4">
                                        <Row>
                                            <Col xxl={12} className="mb-3">
                                                <div className="subTitle">ობიექტთან ახლოს</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="citycenter" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>ქალაქის ცენტრი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="busstop" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>ავტობუსის გაჩერება</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="shoppingcenter" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>სავაჭრო ცენტრი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="school" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>სკოლა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="kindergarten" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>საბავშვო ბაღი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="metrosstation" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>მეტროსადგური</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="cinema" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>კინოთეატრი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="square" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>სკვერი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="museum" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>მუზეუმი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="restaurant" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>რესტორანი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="supermarket" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>სუპერმარკეტი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="pharmacy" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>აფთიაქი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="hospital" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>საავადმყოფო</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="railwaystation" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>რკინიგზის სადგური</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="airport" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>აეროპორტი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="sea" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>ზღვა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="river" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>მდინარე</strong>
                                                    </label>
                                                </div>
                                            </Col>

                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="lake" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>ტბა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="naturereserve" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>ნაკრძალი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="forest" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>ტყე</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="university" onChange={(e)=>handleChangeInput(e)}  name="nearobject[]" /><span className="customCheckboxBox"></span><strong>უნივერსიტეტი</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col xxl={12} className="mb-4">
                                        <Row>
                                            <Col xxl={12} className="mb-3">
                                                <div className="subTitle">დაშვებულია</div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="eventhosting" onChange={(e)=>handleChangeInput(e)}  name="allowed[]" /><span className="customCheckboxBox"></span><strong>წვეულების გამართვა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="pets" onChange={(e)=>handleChangeInput(e)}  name="allowed[]" /><span className="customCheckboxBox"></span><strong>შინაური ცხოველები</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                            <Col xxl={4} className="mb-2">
                                                <div className="customLabelCheck">
                                                    <label className="customCheckbox">
                                                        <input type="checkbox" value="smoking" onChange={(e)=>handleChangeInput(e)}  name="allowed[]" /><span className="customCheckboxBox"></span><strong>მოწევა</strong>
                                                    </label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>

                                </Row>
                            </div>
                        </Col>


                            { formData?.property_type == 'house' || formData?.property_type == 'townhouse' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>კერძო სახლი-აგარაკის / თაუნ ჰაუსის დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">

                                            <Col xxl={12}>
                                                <Row>

                                                    <Col xxl={12} className="mb-3">
                                                        <Row>
                                                            <Col xxl={2}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="yeard" /><span className="customCheckboxBox"></span><strong>ეზო</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            {
                                                                formData?.yeard ?
                                                                    <>
                                                                        <Col xxl={3}>
                                                                            <div className="form-group customFormGroupInput">
                                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='yeardarea' id='yeardarea' placeholder='ეზოს ფართი კვ.მ' />
                                                                            </div>
                                                                        </Col>
                                                                    </>
                                                                :
                                                                ''
                                                            }
                                                            
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12} className="mb-2">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="fence" /><span className="customCheckboxBox"></span><strong>ღობე</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-2">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="capitalfence" /><span className="customCheckboxBox"></span><strong>კაპიტალური ღობე</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-2">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="vine" /><span className="customCheckboxBox"></span><strong>ვაზი</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-2">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="fruit" /><span className="customCheckboxBox"></span><strong>ხეხილი</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-2">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="citrus" /><span className="customCheckboxBox"></span><strong>ციტრუსი</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-2">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="leafy" /><span className="customCheckboxBox"></span><strong>ფოთლოვანი</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-2">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="coniferous" /><span className="customCheckboxBox"></span><strong>წიწვოვანი</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-4 mt-3">
                                                        <Row>
                                                            <Col xxl={12} className="mb-3">
                                                                <div className="subTitle">გზა</div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="asphalted" onChange={(e)=>handleChangeInput(e)}  name="road[]" /><span className="customCheckboxBox"></span><strong>ასფალტიანი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="graveled" onChange={(e)=>handleChangeInput(e)}  name="road[]" /><span className="customCheckboxBox"></span><strong>მოხრეშილი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="soil" onChange={(e)=>handleChangeInput(e)}  name="road[]" /><span className="customCheckboxBox"></span><strong>გრუნტი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12} className="mb-4">
                                                        <Row>
                                                            <Col xxl={12} className="mb-3">
                                                                <div className="subTitle">აშენების მასალა</div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="block" onChange={(e)=>handleChangeInput(e)}  name="buildingmaterial[]" /><span className="customCheckboxBox"></span><strong>ბლოკი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="brick" onChange={(e)=>handleChangeInput(e)}  name="buildingmaterial[]" /><span className="customCheckboxBox"></span><strong>აგური</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="stone" onChange={(e)=>handleChangeInput(e)}  name="buildingmaterial[]" /><span className="customCheckboxBox"></span><strong>ქვა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="wood" onChange={(e)=>handleChangeInput(e)}  name="buildingmaterial[]" /><span className="customCheckboxBox"></span><strong>ხე</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="pumiceblock" onChange={(e)=>handleChangeInput(e)}  name="buildingmaterial[]" /><span className="customCheckboxBox"></span><strong>პემზის ბლოკი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="reinforcedconcrete" onChange={(e)=>handleChangeInput(e)}  name="buildingmaterial[]" /><span className="customCheckboxBox"></span><strong>რკინა-ბეტონი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="sendvichPanel" onChange={(e)=>handleChangeInput(e)}  name="buildingmaterial[]" /><span className="customCheckboxBox"></span><strong>სენდვიჩ პანელი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="minaPaket" onChange={(e)=>handleChangeInput(e)}  name="buildingmaterial[]" /><span className="customCheckboxBox"></span><strong>მინა პაკეტი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="combined" onChange={(e)=>handleChangeInput(e)}  name="buildingmaterial[]" /><span className="customCheckboxBox"></span><strong>კომბინირებული</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12} className="mb-4">
                                                        <Row>
                                                            <Col xxl={12} className="mb-3">
                                                                <div className="subTitle">პროექტი</div>
                                                            </Col>
                                                            {/* <Col xxl={12} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="individual" onChange={(e)=>handleChangeInput(e)}  name="individual" /><span className="customCheckboxBox"></span><strong>ინდივიადუალური</strong>
                                                                    </label>
                                                                </div>
                                                            </Col> */}
                                                            <Col xxl={12} className="mb-3">
                                                                <Row>
                                                                    <Col xxl={2}>
                                                                        <div className="customLabelCheck">
                                                                            <label className="customCheckbox">
                                                                                <input type="checkbox" value="project" onChange={(e)=>handleChangeInput(e)}  name="project2" /><span className="customCheckboxBox"></span><strong>პროექტი</strong>
                                                                            </label>
                                                                        </div>
                                                                    </Col>
                                                                    {
                                                                        formData?.project2 ?
                                                                            <>
                                                                                <Col xxl={10}>
                                                                                    <div className="form-group customFormGroupInput">
                                                                                        <input type='text' onChange={(e)=>handleChangeInput(e)} name='constcompanyname' id='constcompanyname' placeholder='სამშენებლო კომპანიის დასახელება' />
                                                                                    </div>
                                                                                </Col>
                                                                            </>
                                                                        :
                                                                        ''
                                                                    }
                                                                </Row>
                                                            </Col>
                                                            {/* <Col xxl={12} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="nonstandard" onChange={(e)=>handleChangeInput(e)}  name="notStandard" /><span className="customCheckboxBox"></span><strong>არასტანდარტული</strong>
                                                                    </label>
                                                                </div>
                                                            </Col> */}
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12} className="mb-4">
                                                        <Row>
                                                            <Col xxl={12} className="mb-3">
                                                                <div className="subTitle">გადახურვა</div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="flat" onChange={(e)=>handleChangeInput(e)}  name="overlap[]" /><span className="customCheckboxBox"></span><strong>ბრტყელი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="conical" onChange={(e)=>handleChangeInput(e)}  name="overlap[]" /><span className="customCheckboxBox"></span><strong>კონუსებური</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="tinroofing" onChange={(e)=>handleChangeInput(e)}  name="overlap[]" /><span className="customCheckboxBox"></span><strong>თუნუქის გადახურვა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="corrugatedroofing" onChange={(e)=>handleChangeInput(e)}  name="overlap[]" /><span className="customCheckboxBox"></span><strong>ტოლით გადახურვა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="tileroof" onChange={(e)=>handleChangeInput(e)}  name="overlap[]" /><span className="customCheckboxBox"></span><strong>კრამიტის სახურავი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="sendvich" onChange={(e)=>handleChangeInput(e)}  name="overlap[]" /><span className="customCheckboxBox"></span><strong>სენდვიჩ პანელი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="beton" onChange={(e)=>handleChangeInput(e)}  name="overlap[]" /><span className="customCheckboxBox"></span><strong>ბეტონი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="tagovani" onChange={(e)=>handleChangeInput(e)}  name="overlap[]" /><span className="customCheckboxBox"></span><strong>თაღოვანი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="piramid" onChange={(e)=>handleChangeInput(e)}  name="overlap[]" /><span className="customCheckboxBox"></span><strong>პირამიდული</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="valmur" onChange={(e)=>handleChangeInput(e)}  name="overlap[]" /><span className="customCheckboxBox"></span><strong>ვალმური</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="gambler" onChange={(e)=>handleChangeInput(e)}  name="overlap[]" /><span className="customCheckboxBox"></span><strong>გამბლერის სახურავი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="mansard" onChange={(e)=>handleChangeInput(e)}  name="overlap[]" /><span className="customCheckboxBox"></span><strong>მანსარდული</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>

                                                        </Row>
                                                    </Col>


                                                    <Col xxl={12}>
                                                        <Row>
                                                            <Col xxl={12} className="mb-3">
                                                                <div className="subTitle">ფუნდამენტის ტიპი</div>
                                                            </Col>
                                                            
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="lent" onChange={(e)=>handleChangeInput(e)}  name="fundament[]" /><span className="customCheckboxBox"></span><strong>ლენტოვანი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="khiminji" onChange={(e)=>handleChangeInput(e)}  name="fundament[]" /><span className="customCheckboxBox"></span><strong>ხიმინჯი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="balishovani" onChange={(e)=>handleChangeInput(e)}  name="fundament[]" /><span className="customCheckboxBox"></span><strong>ბალიშოვანი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>

                                                        </Row>
                                                    </Col>



                                                </Row>

                                            </Col>
                                        </Row>

                                    </div>
                                </Col>
                                :
                                ''
                                }
                                

                                { formData?.property_type == 'comercial' || formData?.property_type == 'office' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>კომერციული / საოფისე ფართის დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">

                                            <Col xxl={12}>
                                                <Row>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='typeofspace'>ფართის ტიპი</label>
                                                            <Select
                                                                id='typeofspace'
                                                                value={Options.areaTypes.find(option => option.value === formData?.typeofspace)}
                                                                onChange={handleChangeSelect}
                                                                name='typeofspace'
                                                                options={Options.areaTypes}
                                                                placeholder={'აირჩიეთ ტიპი'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-3">
                                                        <Row>
                                                            <Col xxl={2}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="showcase" /><span className="customCheckboxBox"></span><strong>ვიტრინა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            {
                                                                formData?.showcase ?
                                                                    <>
                                                                        <Col xxl={3}>
                                                                            <div className="form-group customFormGroupInput">
                                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='showcaseamount' id='showcaseamount' placeholder='რაოდენობა' />
                                                                            </div>
                                                                        </Col>
                                                                        <Col xxl={3}>
                                                                            <div className="form-group customFormGroupInput">
                                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='showcaselength' id='showcaselength' placeholder='სიგრძე (სმ)' />
                                                                            </div>
                                                                        </Col>
                                                                    </>
                                                                :
                                                                ''
                                                            }
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12} className="mb-3">
                                                        <Row>
                                                            <Col xxl={2}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="facade" /><span className="customCheckboxBox"></span><strong>ფასადი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            {
                                                                formData?.facade ?
                                                                    <>
                                                                        <Col xxl={3}>
                                                                            <div className="form-group customFormGroupInput">
                                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='facadelength' id='facadelength' placeholder='სიგრძე (სმ)' />
                                                                            </div>
                                                                        </Col>
                                                                    </>
                                                                :
                                                                ''
                                                            }
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12} className="mb-3">
                                                        <Row>
                                                            <Col xxl={2}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="hall2" /><span className="customCheckboxBox"></span><strong>დარბაზი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            {
                                                                formData?.hall2 ?
                                                                    <>
                                                                        <Col xxl={3}>
                                                                            <div className="form-group customFormGroupInput">
                                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='hall2amount' id='hall2amount' placeholder='რაოდენობა' />
                                                                            </div>
                                                                        </Col>
                                                                        <Col xxl={3}>
                                                                            <div className="form-group customFormGroupInput">
                                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='hall2area' id='hall2area' placeholder='ფართი (კვ.მ)' />
                                                                            </div>
                                                                        </Col>
                                                                    </>
                                                                :
                                                                ''
                                                            }
                                                        </Row>
                                                    </Col>

                                                </Row>

                                            </Col>
                                        </Row>

                                    </div>
                                </Col>
                               :
                               ''
                               }

                                { formData?.property_type == 'land' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>მიწის დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">

                                            <Col xxl={12}>
                                                <Row>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='landtype'>მიწის ტიპი</label>
                                                            <Select
                                                                id='landtype'
                                                                value={Options.landTypes.find(option => option.value === formData?.landtype)}
                                                                onChange={handleChangeSelect}
                                                                name='landtype'
                                                                options={Options.landTypes}
                                                                placeholder={'აირჩიეთ ტიპი'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='functionalarea'>ფუნქციური ზონა</label>
                                                            <Select
                                                                id='functionalarea'
                                                                value={Options.functgionZones.find(option => option.value === formData?.functionalarea)}
                                                                onChange={handleChangeSelect}
                                                                name='functionalarea'
                                                                options={Options.functgionZones}
                                                                placeholder={'აირჩიეთ ზონა'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='agriculturalland'>სასოფლოს სამეურნეო მიწის ტიპი</label>
                                                            <Select
                                                                id='agriculturalland'
                                                                value={Options.agriculturalTypes.find(option => option.value === formData?.agriculturalland)}
                                                                onChange={handleChangeSelect}
                                                                name='agriculturalland'
                                                                options={Options.agriculturalTypes}
                                                                placeholder={'აირჩიეთ ტიპი'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-3 mt-3">
                                                        <Row>
                                                            <Col xxl={2}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="building" /><span className="customCheckboxBox"></span><strong>შენობა ნაგებობა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            {
                                                                formData?.building ?
                                                                    <>
                                                                        <Col xxl={3}>
                                                                            <div className="form-group customFormGroupInput">
                                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='buildingarea' id='buildingarea' placeholder='სრული ფართი (კვ.მ)' />
                                                                            </div>
                                                                        </Col>
                                                                    </>
                                                                :
                                                                ''
                                                            }
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={4} className="mb-3 mt-3">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="agreedproject" /><span className="customCheckboxBox"></span><strong>შეთანხმებული პროექტი</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4} className="mb-3 mt-3">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="buildingpermission" /><span className="customCheckboxBox"></span><strong>მშენებლობის ნებართვა</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mt-3">
                                                        <Row>
                                                            <Col xxl={12} className="mb-3">
                                                                <div className="subTitle">კომუნიკაციები</div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="road" onChange={(e)=>handleChangeInput(e)}  name="communications[]" /><span className="customCheckboxBox"></span><strong>გზა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="electricity" onChange={(e)=>handleChangeInput(e)}  name="communications[]" /><span className="customCheckboxBox"></span><strong>დენი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="water" onChange={(e)=>handleChangeInput(e)}  name="communications[]" /><span className="customCheckboxBox"></span><strong>წყალი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="gas" onChange={(e)=>handleChangeInput(e)}  name="communications[]" /><span className="customCheckboxBox"></span><strong>გაზი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="sewercollector" onChange={(e)=>handleChangeInput(e)}  name="communications[]" /><span className="customCheckboxBox"></span><strong>კოლექტორი-კანალიზაცია</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="well" onChange={(e)=>handleChangeInput(e)}  name="communications[]" /><span className="customCheckboxBox"></span><strong>ჭა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="easement" onChange={(e)=>handleChangeInput(e)}  name="communications[]" /><span className="customCheckboxBox"></span><strong>სერვიტუტი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="firstrow" onChange={(e)=>handleChangeInput(e)}  name="communications[]" /><span className="customCheckboxBox"></span><strong>პირველი ზოლი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="secondrow" onChange={(e)=>handleChangeInput(e)}  name="communications[]" /><span className="customCheckboxBox"></span><strong>მეორე ზოლი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="thirdrow" onChange={(e)=>handleChangeInput(e)}  name="communications[]" /><span className="customCheckboxBox"></span><strong>მესამე ზოლი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="fence" onChange={(e)=>handleChangeInput(e)}  name="communications[]" /><span className="customCheckboxBox"></span><strong>ღობე</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="capitalfence" onChange={(e)=>handleChangeInput(e)}  name="communications[]" /><span className="customCheckboxBox"></span><strong>კაპიტალური ღობე</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>


                                                </Row>

                                            </Col>
                                        </Row>

                                    </div>
                                </Col>
                                :
                                    ""
                                }

                                { formData?.property_type == 'hotel' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>სასტუმროს დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">

                                            <Col xxl={12}>
                                                <Row>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='numberofstars'>ვარსკვლავების რაოდენობა</label>
                                                            <Select
                                                                id='numberofstars'
                                                                value={Options.hotelStars.find(option => option.value === formData?.numberofstars)}
                                                                onChange={handleChangeSelect}
                                                                name='numberofstars'
                                                                options={Options.hotelStars}
                                                                placeholder={'აირჩიეთ ვარსკვლავი'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupInput">
                                                            <label htmlFor='roomamount'>ნომრების რაოდენობა</label>
                                                            <input type='number' onChange={(e)=>handleChangeInput(e)} name='roomamount' id='roomamount' placeholder='რაოდენობა' />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupInput">
                                                            <label htmlFor='luxamount'>ლუქსების რაოდენობა</label>
                                                            <input type='number' onChange={(e)=>handleChangeInput(e)} name='luxamount' id='luxamount' placeholder='რაოდენობა' />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4} className="mb-3 mt-3">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="familyhotel" /><span className="customCheckboxBox"></span><strong>საოჯახო სასტუმრო</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={8} className="mb-3 mt-3">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="hostel" /><span className="customCheckboxBox"></span><strong>ჰოსტელი</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={3}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='food'>კვება</label>
                                                            <Select
                                                                id='food'
                                                                value={Options.eatings.find(option => option.value === formData?.food)}
                                                                onChange={handleChangeSelect}
                                                                name='food'
                                                                options={Options.eatings}
                                                                placeholder={'აირჩიეთ კვება'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={9}>
                                                        <div className="form-group customFormGroupInput">
                                                            <label htmlFor='activity'>აქტივობა</label>
                                                            <input type='text' onChange={(e)=>handleChangeInput(e)} name='activity' id='activity' placeholder='ჩაწერეთ აქტივობის ფორმები' />
                                                        </div>
                                                    </Col>


                                                </Row>

                                            </Col>
                                        </Row>

                                    </div>
                                </Col>
                                :
                                ""
                                }

                                { formData?.property_type == 'businesscenter' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>ბიზნესცენტრის დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">

                                            <Col xxl={12}>
                                                <div className="form-group customFormGroupSelect mb-3">
                                                    <label htmlFor='businesscenterclass'>ბიზნესცენტრის კლასი</label>
                                                    <Select
                                                        id='businesscenterclass'
                                                        value={Options.businesscenterClasses.find(option => option.value === formData?.businesscenterclass)}
                                                        onChange={handleChangeSelect}
                                                        name='businesscenterclass'
                                                        options={Options.businesscenterClasses}
                                                        placeholder={'აირჩიეთ კლასი'}
                                                    />
                                                </div>
                                            </Col>

                                        </Row>

                                    </div>
                                </Col>
                                :
                                ""
}

{ formData?.property_type == 'business' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>ბიზნეს ინვესტიციის დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">

                                            <Col xxl={12}>
                                                <Row>

                                                    <Col xxl={3}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='businessinvestmentcategory'>ბიზნეს ინვესტიციის კატეგორია</label>
                                                            <Select
                                                                id='businessinvestmentcategory'
                                                                value={Options.businessIndustryes.find(option => option.value === formData?.businessinvestmentcategory)}
                                                                onChange={handleChangeSelect}
                                                                name='businessinvestmentcategory'
                                                                options={Options.businessIndustryes}
                                                                placeholder={'აირჩიეთ კატეგორია'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    {
                                                        formData?.businessinvestmentcategory == 'other' ? 
                                                        <Col xxl={3}>
                                                            <div className="form-group customFormGroupInput">
                                                                <label htmlFor='otherindustry'>სხვა ინდუსტრია</label>
                                                                <input type='text' onChange={(e)=>handleChangeInput(e)} name='otherindustry' id='otherindustry' placeholder='ინდუსტრია' />
                                                            </div>
                                                        </Col>
                                                        :
                                                        ''
                                                    }



                                                    <Col xxl={3}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='owner'>მესაკუთრე</label>
                                                            <Select
                                                                id='owner'
                                                                value={Options.owners.find(option => option.value === formData?.owner)}
                                                                onChange={handleChangeSelect}
                                                                name='owner'
                                                                options={Options.owners}
                                                                placeholder={'აირჩიეთ მესაკუთრის ტიპი'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={3}>
                                                        <div className="form-group customFormGroupInput">
                                                            <label htmlFor='Identificationcode'>საიდენტიფიკაციო კოდი</label>
                                                            <input type='text' onChange={(e)=>handleChangeInput(e)} name='Identificationcode' id='Identificationcode' placeholder='კოდი' />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-3 mt-3">
                                                        <Row>
                                                            <Col xxl={2}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="building1" /><span className="customCheckboxBox"></span><strong>შენობა ნაგებობა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            {
                                                                formData?.building1 ?
                                                                    <>
                                                                        <Col xxl={3}>
                                                                            <div className="form-group customFormGroupInput">
                                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='buildingamount1' id='buildingamount1' placeholder='რაოდენობა' />
                                                                            </div>
                                                                        </Col>
                                                                        <Col xxl={3}>
                                                                            <div className="form-group customFormGroupInput">
                                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='buildingarea1' id='buildingarea1' placeholder='სრული ფართი (კვ.მ)' />
                                                                            </div>
                                                                        </Col>
                                                                    </>
                                                                :
                                                                ''
                                                            }
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='propertytype'>საკუთრების ტიპი</label>
                                                            <Select
                                                                id='propertytype'
                                                                value={Options.ownerTypes.find(option => option.value === formData?.propertytype)}
                                                                onChange={handleChangeSelect}
                                                                name='propertytype'
                                                                options={Options.ownerTypes}
                                                                placeholder={'აირჩიეთ საკუთრების ტიპი'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupInput">
                                                            <label htmlFor='landarea'>მიწის ფართი</label>
                                                            <input type='text' onChange={(e)=>handleChangeInput(e)} name='landarea' id='landarea' placeholder='ფართი (კვ.მ)' />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupInput">
                                                            <label htmlFor='numberofemployees'>თანამშრომლების რაოდენობა</label>
                                                            <input type='text' onChange={(e)=>handleChangeInput(e)} name='numberofemployees' id='numberofemployees' placeholder='რაოდენობა' />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4} className="mt-3">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="heavyequipment" /><span className="customCheckboxBox"></span><strong>მძიმე ტექნიკა</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4} className="mt-3">
                                                        <div className="customLabelCheck">
                                                            <label className="customCheckbox">
                                                                <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="specechnique" /><span className="customCheckboxBox"></span><strong>სპეც ტექნიკა</strong>
                                                            </label>
                                                        </div>
                                                    </Col>

                                                    <Col xxl={4}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='assets'>აქტივები</label>
                                                            <Select
                                                                id='assets'
                                                                value={Options.actives.find(option => option.value === formData?.assets)}
                                                                onChange={handleChangeSelect}
                                                                name='assets'
                                                                options={Options.actives}
                                                                placeholder={'აირჩიეთ'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-3 mt-3">
                                                        <Row>
                                                            <Col xxl={3}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="annualaccrual" /><span className="customCheckboxBox"></span><strong>წლიური დარიცხვა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            {
                                                                formData?.annualaccrual ?
                                                                    <>
                                                                        <Col xxl={3}>
                                                                            <div className="form-group customFormGroupInput">
                                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='annualaccrualgel' id='annualaccrualgel' placeholder='დარიცხვა GEL' />
                                                                            </div>
                                                                        </Col>
                                                                        <Col xxl={3}>
                                                                            <div className="form-group customFormGroupInput">
                                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='annualaccrualusd' id='annualaccrualusd' placeholder='დარიცხვა USD' />
                                                                            </div>
                                                                        </Col>
                                                                        <Col xxl={3}>
                                                                            <div className="form-group customFormGroupInput">
                                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='annualaccrualeur' id='annualaccrualeur' placeholder='დარიცხვა EUR' />
                                                                            </div>
                                                                        </Col>
                                                                    </>
                                                                :
                                                                ''
                                                            }
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12} className="mb-3 mt-3">
                                                        <Row>
                                                            <Col xxl={3}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="annualnetprofit" /><span className="customCheckboxBox"></span><strong>წლიური წმინდა მოგება</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            {
                                                                formData?.annualnetprofit ?
                                                                    <>
                                                                        <Col xxl={3}>
                                                                            <div className="form-group customFormGroupInput">
                                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='annualnetprofitgel' id='annualnetprofitgel' placeholder='მოგება GEL' />
                                                                            </div>
                                                                        </Col>
                                                                        <Col xxl={3}>
                                                                            <div className="form-group customFormGroupInput">
                                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='annualnetprofitusd' id='annualnetprofitusd' placeholder='მოგება USD' />
                                                                            </div>
                                                                        </Col>
                                                                        <Col xxl={3}>
                                                                            <div className="form-group customFormGroupInput">
                                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='annualnetprofiteur' id='annualnetprofiteur' placeholder='მოგება EUR' />
                                                                            </div>
                                                                        </Col>
                                                                    </>
                                                                :
                                                                ''
                                                            }
                                                        </Row>
                                                    </Col>


                                                </Row>

                                            </Col>
                                        </Row>

                                    </div>
                                </Col>
                                :
                                ""
                                }
                                                                

                                { formData?.property_type == 'farm' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>ფერმის დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">

                                            <Col xxl={12}>
                                                <Row>

                                                    <Col xxl={3}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='farmtype'>ფერმის ტიპი</label>
                                                            <Select
                                                                id='farmtype'
                                                                value={Options.farmTypes.find(option => option.value === formData?.farmtype)}
                                                                onChange={handleChangeSelect}
                                                                name='farmtype'
                                                                options={Options.farmTypes}
                                                                placeholder={'აირჩიეთ ფერმის ტიპი'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={3}>
                                                        <div className="form-group customFormGroupInput">
                                                            <label htmlFor='otherfarmtype'>სხვა ფერმის ტიპი</label>
                                                            <input type='text' onChange={(e)=>handleChangeInput(e)} name='otherfarmtype' id='otherfarmtype' placeholder='ტიპი' />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={3}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='landtype1'>მიწის ტიპი</label>
                                                            <Select
                                                                id='landtype1'
                                                                value={Options.farmLandTypes.find(option => option.value === formData?.landtype1)}
                                                                onChange={handleChangeSelect}
                                                                name='landtype1'
                                                                options={Options.farmLandTypes}
                                                                placeholder={'აირჩიეთ ტიპი'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={3}>
                                                        <div className="form-group customFormGroupSelect mb-3">
                                                            <label htmlFor='agrilandtype'>სასოფლო სამეურნეო მიწის ტიპი</label>
                                                            <Select
                                                                id='agrilandtype'
                                                                value={Options.farmAgriculturalTypes.find(option => option.value === formData?.agrilandtype)}
                                                                onChange={handleChangeSelect}
                                                                name='agrilandtype'
                                                                options={Options.farmAgriculturalTypes}
                                                                placeholder={'აირჩიეთ ტიპი'}
                                                            />
                                                        </div>
                                                    </Col>

                                                    <Col xxl={12} className="mb-3 mt-3">
                                                        <Row>
                                                            <Col xxl={2}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="buildingstructureoffice" /><span className="customCheckboxBox"></span><strong>შენობა ნაგებობა (ოფისი)</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            {
                                                                formData?.buildingstructureoffice ?
                                                                    <>
                                                                        <Col xxl={3}>
                                                                            <div className="form-group customFormGroupInput">
                                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='buildingstructureofficeamount' id='buildingstructureofficeamount' placeholder='რაოდენობა' />
                                                                            </div>
                                                                        </Col>
                                                                        <Col xxl={3}>
                                                                            <div className="form-group customFormGroupInput">
                                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='buildingstructureofficearea' id='buildingstructureofficearea' placeholder='სრული ფართი (კვ.მ)' />
                                                                            </div>
                                                                        </Col>
                                                                    </>
                                                                :
                                                                ''
                                                            }
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12} className="mt-3">
                                                        <Row>
                                                            <Col xxl={12} className="mb-3">
                                                                <div className="subTitle">კომუნიკაციები</div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="road" onChange={(e)=>handleChangeInput(e)}  name="communications1[]" /><span className="customCheckboxBox"></span><strong>გზა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="electricity" onChange={(e)=>handleChangeInput(e)}  name="communications1[]" /><span className="customCheckboxBox"></span><strong>დენი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="water" onChange={(e)=>handleChangeInput(e)}  name="communications1[]" /><span className="customCheckboxBox"></span><strong>წყალი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="gas" onChange={(e)=>handleChangeInput(e)}  name="communications1[]" /><span className="customCheckboxBox"></span><strong>გაზი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="sewercollector" onChange={(e)=>handleChangeInput(e)}  name="communications1[]" /><span className="customCheckboxBox"></span><strong>კოლექტორი-კანალიზაცია</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="well" onChange={(e)=>handleChangeInput(e)}  name="communications1[]" /><span className="customCheckboxBox"></span><strong>ჭა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="easement" onChange={(e)=>handleChangeInput(e)}  name="communications1[]" /><span className="customCheckboxBox"></span><strong>სერვიტუტი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="firstrow" onChange={(e)=>handleChangeInput(e)}  name="communications1[]" /><span className="customCheckboxBox"></span><strong>პირველი ზოლი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="secondrow" onChange={(e)=>handleChangeInput(e)}  name="communications1[]" /><span className="customCheckboxBox"></span><strong>მეორე ზოლი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="thirdrow" onChange={(e)=>handleChangeInput(e)}  name="communications1[]" /><span className="customCheckboxBox"></span><strong>მესამე ზოლი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="fence" onChange={(e)=>handleChangeInput(e)}  name="communications1[]" /><span className="customCheckboxBox"></span><strong>ღობე</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="owntransformer" onChange={(e)=>handleChangeInput(e)}  name="communications1[]" /><span className="customCheckboxBox"></span><strong>საკუთარი ტრანსფორმატორი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="owngenerator" onChange={(e)=>handleChangeInput(e)}  name="communications1[]" /><span className="customCheckboxBox"></span><strong>საკუთარი გენერატორი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12} className="mt-3">
                                                        <Row>
                                                            <Col xxl={12} className="mb-3">
                                                                <div className="subTitle">დამატებითი დეტალები</div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="pumpsystem" onChange={(e)=>handleChangeInput(e)}  name="additionaldetails[]" /><span className="customCheckboxBox"></span><strong>სატუმბი სისტემა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="borehole" onChange={(e)=>handleChangeInput(e)}  name="additionaldetails[]" /><span className="customCheckboxBox"></span><strong>ჭაბურღილი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="dripirrigationsystem" onChange={(e)=>handleChangeInput(e)}  name="additionaldetails[]" /><span className="customCheckboxBox"></span><strong>წვეთოვანი სარწყავი სისტემა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="railwayspur" onChange={(e)=>handleChangeInput(e)}  name="additionaldetails[]" /><span className="customCheckboxBox"></span><strong>რკინიგზის ჩიხი</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            <Col xxl={4} className="mb-2">
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="capitalfence" onChange={(e)=>handleChangeInput(e)}  name="additionaldetails[]" /><span className="customCheckboxBox"></span><strong>კაპიტალური ღობე</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    <Col xxl={12} className="mb-3 mt-4">
                                                        <Row>
                                                            <Col xxl={2}>
                                                                <div className="customLabelCheck">
                                                                    <label className="customCheckbox">
                                                                        <input type="checkbox" value="yes" onChange={(e)=>handleChangeInput(e)}  name="lake" /><span className="customCheckboxBox"></span><strong>ტბა</strong>
                                                                    </label>
                                                                </div>
                                                            </Col>
                                                            {
                                                                formData?.lake ?
                                                                    <>
                                                                        <Col xxl={3}>
                                                                            <div className="form-group customFormGroupInput">
                                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='lakeamount' id='lakeamount' placeholder='რაოდენობა' />
                                                                            </div>
                                                                        </Col>
                                                                        <Col xxl={3}>
                                                                            <div className="form-group customFormGroupInput">
                                                                                <input type='number' onChange={(e)=>handleChangeInput(e)} name='lakearea' id='lakearea' placeholder='სრული ფართი (კვ.მ)' />
                                                                            </div>
                                                                        </Col>
                                                                    </>
                                                                :
                                                                ''
                                                            }
                                                        </Row>
                                                    </Col>


                                                </Row>

                                            </Col>
                                        </Row>

                                    </div>
                                </Col>
                                :
                                ""
                                }
                                

                                { formData?.transaction_type == 'installment' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>განვადებით გაყიდვის დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">
                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='Initialdepositamountgel'>პირველადი შესატანი თანხა GEL</label>
                                                    <input type='number' onChange={(e)=>handleChangeInput(e)} name='Initialdepositamountgel' id='Initialdepositamountgel' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='Initialdepositamountusd'>პირველადი შესატანი თანხა USD</label>
                                                    <input type='number' onChange={(e)=>handleChangeInput(e)} name='Initialdepositamountusd' id='Initialdepositamountusd' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='Initialdepositamounteur'>პირველადი შესატანი თანხა EUR</label>
                                                    <input type='number' onChange={(e)=>handleChangeInput(e)} name='Initialdepositamounteur' id='Initialdepositamounteur' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='monthlydepositamountgel'>ყოველთვიური შესატანი თანხა GEL</label>
                                                    <input type='number' onChange={(e)=>handleChangeInput(e)} name='monthlydepositamountgel' id='monthlydepositamountgel' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='monthlydepositamountusd'>ყოველთვიური შესატანი თანხა USD</label>
                                                    <input type='number' onChange={(e)=>handleChangeInput(e)} name='monthlydepositamountusd' id='monthlydepositamountusd' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='monthlydepositamounteur'>ყოველთვიური შესატანი თანხა EUR</label>
                                                    <input type='number' onChange={(e)=>handleChangeInput(e)} name='monthlydepositamounteur' id='monthlydepositamounteur' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='finalamounttotalgel'>თანხა ჯამში GEL</label>
                                                    <input type='number' onChange={(e)=>handleChangeInput(e)} name='finalamounttotalgel' id='finalamounttotalgel' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='finalamounttotalusd'>თანხა ჯამში USD</label>
                                                    <input type='number' onChange={(e)=>handleChangeInput(e)} name='finalamounttotalusd' id='finalamounttotalusd' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='finalamounttotaleur'>თანხა ჯამში EUR</label>
                                                    <input type='number' onChange={(e)=>handleChangeInput(e)} name='finalamounttotaleur' id='finalamounttotaleur' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                            <Col xxl={3}>
                                                <div className="form-group customFormGroupInput">
                                                    <label htmlFor='validityperiod'>მოქმედების ვადა</label>
                                                    <input type='text' onChange={(e)=>handleChangeInput(e)} name='validityperiod' id='validityperiod' placeholder='ჩაწერეთ ვადა' />
                                                </div>
                                            </Col>

                                        </Row>

                                    </div>
                                </Col>
                                :
                                ""
                                }


                                { formData?.transaction_type == 'auction' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>აუქციონით გაყიდვის დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='auctiondeadline'>აუქციონის ბოლო ვადა</label>
                                                    <div className="dateBox">
                                                        <DatePicker
                                                            selected={formData?.auctiondeadline}
                                                            onChange={(date, e) => handleDateChange(date, e, "auctiondeadline")}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xxl={12}></Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='monthlydepositamount1gel'>ყოველთვიური შესატანი თანხა GEL</label>
                                                    <input type='number' onChange={(e)=>handleChangeInput(e)} name='monthlydepositamount1gel' id='monthlydepositamount1gel' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='monthlydepositamount1usd'>ყოველთვიური შესატანი თანხა USD</label>
                                                    <input type='number' onChange={(e)=>handleChangeInput(e)} name='monthlydepositamount1usd' id='monthlydepositamount1usd' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='monthlydepositamount1eur'>ყოველთვიური შესატანი თანხა EUR</label>
                                                    <input type='number' onChange={(e)=>handleChangeInput(e)} name='monthlydepositamount1eur' id='monthlydepositamount1eur' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='finalamounttotal1gel'>საბოლოო თანხა GEL</label>
                                                    <input type='number' onChange={(e)=>handleChangeInput(e)} name='finalamounttotal1gel' id='finalamounttotal1gel' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='finalamounttotal1usd'>საბოლოო თანხა USD</label>
                                                    <input type='number' onChange={(e)=>handleChangeInput(e)} name='finalamounttotal1usd' id='finalamounttotal1usd' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='finalamounttotal1eur'>საბოლოო თანხა EUR</label>
                                                    <input type='number' onChange={(e)=>handleChangeInput(e)} name='finalamounttotal1eur' id='finalamounttotal1eur' placeholder='ჩაწერეთ ფასი' />
                                                </div>
                                            </Col>


                                        </Row>

                                    </div>
                                </Col>
                                :
                                ""
                                }

                                { formData?.transaction_type == 'dailyrent' ?
                                <Col xxl={12}>
                                    <div className="cardBox">
                                        <div className="cardBoxTitle">
                                            <h4>დღიურად გაქირავების დეტალები</h4>
                                        </div>

                                        <Row className="cardBoxFormGroup">

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='rentalstarttime'>გაქირავების დაწყების დრო</label>
                                                    <div className="dateBox">
                                                        <DatePicker
                                                            selected={formData?.rentalstarttime}
                                                            onChange={(date, e) => handleDateChange(date, e, "rentalstarttime")}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col xxl={4}>
                                                <div className="form-group customFormGroupInput mb-3">
                                                    <label htmlFor='rentalendtime'>გაქირავების დასრულების დრო</label>
                                                    <div className="dateBox">
                                                        <DatePicker
                                                            selected={formData?.rentalendtime}
                                                            onChange={(date, e) => handleDateChange(date, e, "rentalendtime")}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>

                                    </div>
                                </Col>
                                :
                                ""
                            }
                                


                        <Col xxl={12} ref={photosFltr}>
                            <div className="cardBox">
                                <div className="cardBoxTitle">
                                    <h4>დაამატეთ ფოტოები</h4>
                                </div>

                                <Row className="cardBoxFormGroup">

                                    {[...Array(15)].map((_, i) => (
                                        <Col key={i} xxl={3} className='mb-3'>
                                            {!images[i] ? (
                                                <div className='uploadMainImageBox' onClick={() => handleUploadClick(i)}>
                                                    <div className='uploadMainImage'>
                                                        <FontAwesomeIcon icon={faPlus} />
                                                        {

                                                            i == 0 ?
                                                                <h4>ქავერის ფოტოს ატვირთვა</h4>
                                                                :
                                                                <h4>ფოტოს ატვირთვა</h4>
                                                        }

                                                    </div>
                                                    <input
                                                        type='file'
                                                        ref={(el) => (uploadAdImageRefs.current[i] = el)} // Assign ref dynamically
                                                        style={{ display: 'none' }}
                                                        accept='.png, .jpg, .jpeg'
                                                        onChange={(e) => handleImageChange(e, i)}
                                                    />
                                                </div>
                                            ) : (
                                                <div className='uploadedImage'>
                                                    <Image src={URL.createObjectURL(images[i])} alt={`Preview ${i + 1}`} />
                                                    <div className='makeCover active'>
                                                        <FontAwesomeIcon icon={faPhotoFilm} title='მთავარ ფოტოდ დაყენება' />
                                                    </div>
                                                    <span title='სურათის წაშლა' onClick={() => handleImageDelete(i)}>
                                                        <FontAwesomeIcon icon={faTrashCan} />
                                                    </span>
                                                </div>
                                            )}
                                        </Col>
                                    ))}





                                    <Col xxl={12}>
                                        <p className='notifyUpload'>* ფოტოების მაქსიმალური რაოდენობაა 15, ფოტოს ზომა არ უნდა აღემატებოდეს 2 მეგაბაიტს!</p>
                                    </Col>

                                </Row>

                            </div>
                        </Col>


                        <Col xxl={12} ref={descriptionFltr}>
                            <div className="cardBox">
                                <div className="cardBoxTitle">
                                    <h4>განცხადების აღწერა</h4>
                                </div>

                                <Row className="cardBoxFormGroup">

                                    <Col xxl={12}>
                                        <div className="form-group customFormGroupInput mb-3">
                                            <Tabs
                                                defaultActiveKey="georgian"
                                                id="descriptionLanguage"
                                                className="mainDescTabs"
                                            >
                                                <Tab eventKey="georgian" title="ქართული">
                                                    <textarea onChange={(e)=>handleChangeInput(e)} className='w-100 textDescription' rows="6" name='georgianDescription' id='georgianDescription' placeholder='აღწერა ქართულად'></textarea>
                                                </Tab>
                                                <Tab eventKey="english" title="English">
                                                    <textarea onChange={(e)=>handleChangeInput(e)} className='w-100 textDescription' rows="6" name='englishDescription' id='englishDescription' placeholder='აღწერა ინგლისურად'></textarea>
                                                </Tab>
                                                <Tab eventKey="russian" title="Русский">
                                                    <textarea onChange={(e)=>handleChangeInput(e)} className='w-100 textDescription' rows="6" name='russianDescription' id='russianDescription' placeholder='აღწერა რუსულად'></textarea>
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </Col>

                                </Row>

                            </div>
                        </Col>


                        <Col xxl={12}>
                            <div className="cardBox">

                                <Row className="cardBoxFormGroup">

                                    <Col xxl={12} className='text-center'>
                                        {
                                            loading ? 
                                            <button type='button' className='addPropertyButton px-5'>
                                                <ThreeDots
                                                    visible={true}
                                                    height="28"
                                                    width="28"
                                                    color="#7880ee"
                                                    radius="9"
                                                    ariaLabel="three-dots-loading"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                />
                                            </button>
                                            :
                                            <button type='button' className='addPropertyButton' onClick={submitForm}>+ განცხადების დამატება</button>
                                        }
                                    </Col>

                                </Row>

                            </div>
                        </Col>


                    </Row>
                </Container>
            </section>
        </div>
    )

}


export default Add;
