import { useState } from 'react'

//import tags
import { Container, Image, Row, Col, Tab, Tabs, Accordion } from 'react-bootstrap'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';


//import translation
import { useTranslation } from "react-i18next";
import "../../i18n";

function Sidemenu({ setSideMenuModal, locale }) {

    //get locale
    const { t } = useTranslation();

    return (
        
        <div className="sidebarContainer">
            <div className="closeSidebarModal" onClick={() => setSideMenuModal(false)}>
                <FontAwesomeIcon icon={faTimes} />
            </div>

            <div className="menuInner">
                <div className="sideMenuHeader">
                    <h4>მენიუ</h4>
                </div>

                <div className="mainSideMenuBox">
                    <Accordion>
                        
                        <Accordion.Item eventKey="0">
                            <Accordion.Header>უძრავი ქონება</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/photovideo`}>ფოტო / ვიდეო გადაღება</Link></li>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/rate`}>შეფასება</Link></li>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/management`}>უძრავი ქონების მართვა/მენეჯმენტი</Link></li>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/exclusive`}>ექსკლუზიური მომსახურება</Link></li>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/check`}>ყიდვამდე გადამოწმება</Link></li>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/insurance`}>დაზღვევა</Link></li>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/legalservice`}>იურიდიული მომსახურება</Link></li>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/ads`}>რეკლამა სოც. მედიაში</Link></li>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/clients`}>კლიენტი</Link></li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>არქიტექტურა - დიზაინი</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/repairservice`}>სრული სარემონტო მომსახურება</Link></li>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/montage`}>ნებისმიერი სირთულის რკინა ბეტონის მშენებლობა/მონტაჟი</Link></li>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/landservice`}>მიწის სამუშაოები</Link></li>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/buildingmanagement`}>მშენებლობის მენეჯმენტი</Link></li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="2">
                            <Accordion.Header>იურიდიული მომსახურება</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/architect`}>არქიტექტურული საპროექტო დოკუმენტაციის მომზადება</Link></li>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/urban`}>ქალაქგეგმარებითი ურბანული პროექტის შედგენა</Link></li>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/costservice`}>მშენებლობის ხარჯთაღრიცხვა</Link></li>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/interiordesign`}>ინტერიერის დიზაინი</Link></li>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/communications`}>საინჟინრო კომუნიკაციების პროექტირება</Link></li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="3">
                            <Accordion.Header>კომპანია</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/about`}>ჩვენ შესახებ</Link></li>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/newses`}>სიახლეები</Link></li>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/cities`}>პოპულარული ქალაქები</Link></li>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/videos`}>ვიდეო სიახლეები</Link></li>
                                    <li><Link onClick={() => setSideMenuModal(false)} to={`/${locale}/contact`}>კონტაქტი</Link></li>
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>
                </div>

            </div>

        </div>
    )

}

export default Sidemenu;