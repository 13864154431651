//import tags
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";
import Skeleton from 'react-loading-skeleton'
import parse from 'html-react-parser';

//import resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

//api
import { get_about } from '../api/Api'



function About({locale}) {

    const baseUrl = 'https://reluxapi.myweb.com.ge';
    const [about, setAbout] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
          try {
            get_about(setAbout);
    
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
        
        if(!about) {
            fetchData();
        }
    
      }, [about]);


    return (
        <div className='main'>

            <section className="breadcumbSection" id="sectionBreadCumb">
                <Container>
                        <Row>
                            <Col xxl={12}>
                                <div className="breadcumb">
                                    <div className="breadcumbLeft">
                                        <h1>ჩვენ შესახებ</h1>
                                    </div>
                                    <div className="breadcumbRight">
                                        <ul>
                                            <li><Link to="/">მთავარი</Link></li>
                                            <li><FontAwesomeIcon icon={faChevronRight} /></li>
                                            <li>ჩვენ შესახებ</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                </Container>
            </section>

            <section className="aboutSection">
                <Container>
                    <Row>
                        <Col xxl={6}>
                            <div className="aboutBox">
                                <h1>კომპანიის შესახებ</h1>
                                {typeof about === 'object' ? parse(about.translations[0].text) : <Skeleton count={10} />}
                            </div>
                        </Col>
                        <Col xxl={6}>
                            <div className="aboutImage">
                                {typeof about === 'object' ? (
                                    <Image src={baseUrl+'/storage/about/'+about.image} alt="About us" />
                                ) : (
                                    <Skeleton style={{ width: '100%', height: '100%' }} />
                                )}
                            </div>
                        </Col>
                        
                    </Row>
                </Container>
            </section>
        </div>
    )
    
}

export default About;